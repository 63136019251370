import Web3 from "web3";
import { ethers } from "ethers";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { getError } from "../../../utils";

export const PresetNetwork = {
  137: {
    chainId: ethers.utils.hexValue(137),
    rpcUrls: [
      "https://polygon.rpc.rivet.cloud/dc38adb5b8594a3e842a4dcaa7351c09",
    ],
    chainName: "Polygon Mainnet",
    nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
    blockExplorerUrls: ["https://polygonscan.com/"],
    // iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"],
  },
  80001: {
    chainId: ethers.utils.hexValue(80001),
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    chainName: "Matic Mumbai Testnet",
    nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    // iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"],
  },
  324: {
    chainId: ethers.utils.hexValue(324),
    rpcUrls: ["https://mainnet.era.zksync.io"],
    chainName: "zkSync Era Mainnet",
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://explorer.zksync.io/"],
    // iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"],
  },
  280: {
    chainId: ethers.utils.hexValue(280),
    rpcUrls: ["https://testnet.era.zksync.dev"],
    chainName: "zkSync Era Testnet",
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://goerli.explorer.zksync.io/"],
    // iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"],
  },
};

const APP_NAME = "npayme";
const APP_LOGO_URL = "https://npayme.io/logo192.png";
const DEFAULT_ETH_JSONRPC_URL =
  "https://mainnet.infura.io/v3/<YOUR_INFURA_API_KEY>";
const DEFAULT_CHAIN_ID = 5;

// Initialize Coinbase Wallet SDK
export const coinbaseWallet = new CoinbaseWalletSDK({
  appName: APP_NAME,
  appLogoUrl: APP_LOGO_URL,
  darkMode: false,
});

// Initialize a Web3 Provider object
const provider = coinbaseWallet.makeWeb3Provider(
  DEFAULT_ETH_JSONRPC_URL,
  DEFAULT_CHAIN_ID
);

// Initialize a Web3 object
export const web3 = new Web3(provider as any);

export const hasCoinbaseWallet = provider.isCoinbaseWallet;

export const zkSyncEraChains = [280, 324, "0x118", "0x144"];
export const checkNetwork = async (chainId: number) => {
  if (hasCoinbaseWallet) {
    const currentChainId = await provider.request({
      method: "eth_chainId",
    });

    const targetChainID = ethers.utils.hexValue(
      typeof chainId === "string" ? parseInt(chainId) : chainId
    );
    return targetChainID === currentChainId;
  }
};

export const switchNetwork = async (chainId: number) => {
  const targetChainID = chainId;
  try {
    const err = await provider.send("wallet_switchEthereumChain", [
      { chainId: ethers.utils.hexValue(targetChainID) },
    ]);

    if (err === null) {
      return true;
    }

    return err.message;
  } catch (e: any) {
    switch (e.code) {
      case 4902:
      case -32603:
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [PresetNetwork[targetChainID]],
          });
          return true;
        } catch (e: any) {
          console.log(e);
          return e.message;
        }
      case -32601:
        return "Please switch blockchain network manually";
      default:
        return e.message;
    }
  }
};

export const ensurChainId = async (chainId: number, setProgress?: Function) => {
  const validBlockchain = await checkNetwork(chainId);
  if (!validBlockchain) {
    if (typeof setProgress === "function") {
      setProgress();
    }

    return await switchNetwork(chainId);
  }

  return true;
};

export const onCoinbaseConnect = async (args: any = undefined) => {
  try {
    if (hasCoinbaseWallet) {
      // const provider = ethereum;
      // Will open the MetaMask UI
      // You should disable this button while the request is pending!
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      if (!accounts) {
        return console.error("Error connect coinbase");
      }

      provider.on("chainChanged", (chainId) => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        console.log("Changed to new network. Chain id =", chainId);
        // window.location.reload();
      });

      return accounts[0];
    }
  } catch (error) {
    console.error(error);
  }
};

export async function request({
  from,
  to,
  data,
  amount,
  gasPrice,
  estimatedGas,
  EIP712Msg,
  chainId,
  type,
  nonce,
  customData,
  gas,
  gasLimit,
  maxFeePerGas,
  maxPriorityFeePerGas,
  setProgress,
  uuid,
}: {
  from: string;
  to: string;
  data: string;
  amount: string;
  gasPrice: string;
  estimatedGas: string;
  EIP712Msg: any;
  chainId: number;
  type: number;
  nonce: number;
  customData: any;
  gas: string | number;
  gasLimit: string | number;
  maxFeePerGas: string | number;
  maxPriorityFeePerGas: string | number;
  setProgress?: Function;
  uuid?: string;
}) {
  if (hasCoinbaseWallet) {
    const message = await ensurChainId(
      chainId || EIP712Msg?.domain?.chainId,
      () => (typeof setProgress === "function" ? setProgress() : undefined)
    );
    if (message !== true) {
      return {
        success: false,
        message,
      };
    } else {
      try {
        if (!EIP712Msg) {
          let tx;
          if (!customData) {
            console.log("legacy transaction");

            tx =
              type == 2
                ? {
                    from,
                    to,
                    // gas: estimatedGas || gasLimit, // When deposit usdc on L1 Metamask throw error: invalid key
                    gasLimit: estimatedGas || gasLimit,
                    maxFeePerGas,
                    maxPriorityFeePerGas,
                    value: amount,
                    data,
                    type: "2",
                    // chainId,
                  }
                : {
                    from,
                    to,
                    // gas: estimatedGas || gasLimit, // When deposit usdc on L1 Metamask throw error: invalid key
                    gasLimit: gasLimit || estimatedGas,
                    gasPrice: gasPrice || gas || maxFeePerGas,
                    value: amount,
                    data,
                    // chainId,
                  };
          } else {
            console.log("paymaster transaction");

            tx = {
              from,
              to,
              gas: estimatedGas || gasLimit,
              // gasPrice: gasPrice || gas, // When donate usdc on L2 Metamask throw error: both gasPrice and (maxFeePerGas or maxPriorityFeePerGas) specified
              // gasLimit,
              value: amount,
              data,
              // nonce: ethers.utils.hexValue(nonce), // MetaMask - RPC Error: Invalid transaction params: nonce is not a string
              customData,
              maxFeePerGas,
              maxPriorityFeePerGas,
            };
          }
          console.log("tx to send to coinbase........", tx);

          const transactionHash = await provider.request({
            method: "eth_sendTransaction",
            params: [tx],
          });

          console.log("transactionHash........", transactionHash);

          return {
            success: true,
            uuid,
            transaction_hash: transactionHash,
          };
        } else {
          console.log("EIP712Msg", EIP712Msg);

          // const txHash = await provider.request({
          //   method: "eth_signTransaction",
          //   params: [tx],
          // });

          const signature = await provider.request({
            method: "eth_signTypedData_v4",
            params: [from, JSON.stringify(EIP712Msg)],
          });

          console.log("signature:", signature, from);
          return { from, EIP712Msg, signature };
        }
      } catch (e: any) {
        console.log("............", e, e.code);
        if (e && e.code) {
          switch (e.code) {
            case 4001:
            case "ACTION_REJECTED": // MetaMask Typed Message Signature: User denied message signature.
              return {
                success: false,
                message: "User rejected",
              };
            // case -32000:
            //   return {
            //     success: false,
            //     message: e.message,
            //   };
            default:
          }
        }
        return {
          success: false,
          message: getError(e) || "Something wrong",
        };
      }
    }
  }
}
