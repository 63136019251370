import { WALLET } from "./blockchain";

export const getRemainingDays = (expiration) => {
  console.log("expiration:", expiration);
  console.log("new Date():", new Date());

  if (expiration - new Date() > 1000 * 60 * 60 * 24) {
    return {
      unit: "Days",
      time: Math.floor((expiration - new Date()) / (1000 * 60 * 60 * 24)),
    };
  } else {
    let remaining = expiration - new Date();
    console.log("remaining:", remaining);
    if (remaining > 0) {
      const hours = Math.floor(remaining / (1000 * 60 * 60));
      console.log("hours:", hours);
      if (hours > 0) {
        return {
          unit: "Hours",
          time: hours,
        };
      } else {
        const minutes = Math.floor(remaining / (1000 * 60));
        console.log("minutes:", minutes);
        if (minutes > 0) {
          return {
            unit: "Hours",
            time: minutes,
          };
        }
      }
    }
  }

  return {
    unit: "Days",
    time: 0,
  };
};

export const getExpiration = (expiration) => {
  const end = new Date(expiration);
  end.setHours(23, 59, 59, 999);
  return end.getTime();

  // const now = new Date();
  // return Date.now() + Math.floor((expiration - now) / 1000 + 24 * 60 * 60); // Campaign ending at midnight of the ending date
};

export const getError = (e) => {
  let errMsg;

  if (e.code === -32603) {
    if (e.data && typeof e.data.message === "string") {
      errMsg = e.data.message;
    } else if (typeof e.message === "string") {
      errMsg = e.message;
    }
    console.log("..................1", errMsg);
    if (errMsg.indexOf("Paymaster balance might not be enough") !== -1) {
      return errMsg;
    }
  }
  if (typeof e === "string") {
    errMsg = e;
    console.log("..................2", errMsg);
  }
  if (e.error && typeof e.error.message === "string") {
    errMsg = e.error.message;
    console.log("..................3", errMsg);
  }
  if (e.message && typeof e.message === "string") {
    errMsg = e.message;
    console.log("..................5", errMsg);
  }
  if (e.reason && typeof e.reason === "string") {
    errMsg = e.reason;
    console.log("..................4", errMsg);
    if (
      errMsg.indexOf(
        "execution reverted: ERC20: transfer amount exceeds balance"
      ) === 0
    ) {
      return errMsg;
    }

    if (errMsg.indexOf("execution reverted: not enough allowance") === 0) {
      return errMsg;
    }
  }
  if (e.error && e.error.toString()) {
    errMsg = e.error.toString();
    console.log("..................6", errMsg);
  }

  return errMsg;
};

export const NONE = "NONE";
export const STEP_SET_ALLOWANCE = "STEP_SET_ALLOWANCE";
export const STEP_APPROVE_PROMOTE = "STEP_APPROVE_PROMOTE";
export const STEP_APPROVE_DONATION = "STEP_APPROVE_DONATION";
export const STEP_SEND_DONATION = "STEP_SEND_DONATION";
export const STEP_APPROVE_L1_ALLOWANCE = "STEP_APPROVE_L1_ALLOWANCE";
export const STEP_DEPOSIT_TOKEN = "STEP_DEPOSIT_TOKEN";
export const STEP_SWITCH_TO_L1 = "STEP_SWITCH_TO_L1";
export const STEP_SWITCH_TO_L2 = "STEP_SWITCH_TO_L2";
export const STEP_SWITCH_TO_PG = "STEP_SWITCH_TO_PG";
export const STEP_PREPARE_PAYMENT = "STEP_PREPARE_PAYMENT";
export const STEP_DUMMY_SIGN = "STEP_DUMMY_SIGN";
export const STEP_PREPARE_DEPOSIT = "STEP_PREPARE_DEPOSIT";
export const STEP_PREPARE_DONATION = "STEP_PREPARE_DONATION";
export const STEP_PULLING_SETTLEMENT = "STEP_PULLING_SETTLEMENT";
export const STEP_PREPARE_SETTLEMENT = "STEP_PREPARE_SETTLEMENT";
export const STEP_CREATE_WIDGET = "STEP_CREATE_WIDGET";
export const STEP_CONNECT_WALLET = "STEP_CONNECT_WALLET";
export const STEP_ADDING_AFFILIATE = "STEP_ADDING_AFFILIATE";
export const STEP_SELECT_NETWORK = "STEP_SELECT_NETWORK";
export const getWaitingMessage = (
  amount,
  symbol,
  origin_symbol = "",
  wallet = ""
) => ({
  [NONE]: false,
  // STEP_SET_ALLOWANCE: `Please appove an allowance of ${amount} ${symbol} on zkSync Era...`,
  [STEP_SET_ALLOWANCE]: [
    `${
      wallet === WALLET.metamask ? "MetaMask" : "CoinBase"
    } will open and ask you to approve your payment of ${amount} ${symbol} on ${
      origin_symbol.endsWith("_ZK")
        ? "zkSync Era"
        : origin_symbol.endsWith("_PG")
        ? "Polygon"
        : "Ethereum"
    }...`,
    `Please check your ${
      wallet === WALLET.metamask ? "MetaMask" : "CoinBase"
    } wallet now and approve the transaction`,
  ],
  [STEP_APPROVE_PROMOTE]: [
    `MetaMask will open and ask you to pay 1 NPAY to promote this campaign`,
    `Please check your ${
      wallet === WALLET.metamask ? "MetaMask" : "CoinBase"
    } wallet now & approve the transaction`,
  ],
  [STEP_APPROVE_DONATION]: [
    `${
      wallet === WALLET.metamask ? "MetaMask" : "CoinBase"
    } will open and ask you to finalize your donation of ${amount} ${symbol} on ${
      origin_symbol.endsWith("_ZK")
        ? "zkSync Era"
        : origin_symbol.endsWith("_PG")
        ? "Polygon"
        : "Ethereum"
    }...`,
    `Please check your ${
      wallet === WALLET.metamask ? "MetaMask" : "CoinBase"
    } wallet now and approve the transaction`,
  ],
  [STEP_DUMMY_SIGN]: [
    `${
      wallet === WALLET.metamask ? "MetaMask" : "CoinBase"
    } will open and ask you to sign on a dummy transfer of 0 USDC to promote this campaign`,
    `Please check your ${
      wallet === WALLET.metamask ? "MetaMask" : "CoinBase"
    } wallet now & approve the transaction`,
  ],
  [STEP_SEND_DONATION]: `Donating ${amount} ${symbol} on ${
    origin_symbol.endsWith("_ZK")
      ? "zkSync Era"
      : origin_symbol.endsWith("_PG")
      ? "Polygon"
      : "Ethereum"
  } to the campaign...`,
  [STEP_APPROVE_L1_ALLOWANCE]: `Please appove an allowance of ${amount} ${symbol} on Ethereum...`,
  [STEP_DEPOSIT_TOKEN]: `Please appove to move ${amount} ${symbol} to ${
    origin_symbol.endsWith("_ZK")
      ? "zkSync Era"
      : origin_symbol.endsWith("_PG")
      ? "Polygon"
      : "Ethereum"
  }...`,
  [STEP_SWITCH_TO_L1]: `Switching to Ethereum blockchain...`,
  [STEP_SWITCH_TO_L2]: `Switching to zkSync Era blockchain...`,
  [STEP_SWITCH_TO_PG]: `Switching to Polygon (MATIC) blockchain...`,
  [STEP_PREPARE_PAYMENT]: "Preparing a payment...",
  [STEP_PREPARE_DEPOSIT]: "Preparing a deposit transaction ( ~ 1 minute) ...",
  [STEP_PREPARE_DONATION]: "Preparing a donation transaction...",
  [STEP_PREPARE_SETTLEMENT]: "Preparing a settlement...",
  [STEP_PULLING_SETTLEMENT]: "Pulling balance for settlement...",
  [STEP_CREATE_WIDGET]:
    "Creating smart contract. If you chose to accept USDC on ethereum, it may take several minutes...",
  [STEP_CONNECT_WALLET]: "Please connect your wallet...",
  [STEP_ADDING_AFFILIATE]: "Adding you as an affiliate to the campaign...",
  [STEP_SELECT_NETWORK]:
    "Please select a network to settle then connect your wallet",
});
