import { useState, useEffect } from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import Paymaster from "../admin/Paymaster";
import Swapper from "../admin/Swapper";

const Home = () => {
  const [openPaymaster, setOpenPaymaster] = useState(false);
  const [openSwapper, setOpenSwapper] = useState(false);

  const handleClose = () => {
    setOpenPaymaster(false);
    setOpenSwapper(false);
  };

  useEffect(() => {
    document.addEventListener(
      "keydown",
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(e);

        if (e.key === "F1") {
          setOpenPaymaster(true);
          setOpenSwapper(false);
        }

        if (e.key === "F2") {
          setOpenPaymaster(false);
          setOpenSwapper(true);
        }

        return false;
      },
      { once: true }
    );
  }, []);

  const handleClick = (event) => {};
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Paymaster open={openPaymaster} handleClose={handleClose} />
      <Swapper open={openSwapper} handleClose={handleClose} />
      <img
        src="/assets/ring.png"
        style={{
          width: "280px",
          height: "280px",
          position: "absolute",
          top: "-88px",
          left: "calc(100% - 280px)",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          position: "absolute",
          width: "918px",
          left: "calc(50% - 410px)",
          top: "128px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1">
            What can npayme do for me as a creator and fundraiser?
          </Typography>
          <Typography variant="body1">
            With{" "}
            <Link
              href="https://npayme.com"
              target="_blank"
              rel="noreferrer"
              sx={{ fontWeight: 700, color: "#7d40edd4" }}
            >
              npayme
            </Link>
            , our goal is that everyone—every creator, every cause, every
            consumer—prospers the more you grow. With our revolutionary Web3
            payment system, creators, causes and charities can easily use social
            media to promote their offer, while incentivizing affiliates to
            spread the word and instantly get paid. Grow an audience that truly
            cares about your cause and content and spread your message through
            affiliates whose earnings increase the more you prosper.
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1">How does it work?</Typography>
          <Typography>It just takes 3 easy steps:</Typography>
          <ol>
            <li>
              <Typography>
                <strong>Create</strong> - create your npayme and decide who
                receives payments (including how much you want to reward
                affiliates and yourself)
              </Typography>
            </li>
            <li>
              <Typography>
                <strong>Share</strong> - share your npayme on social media (an
                npayme is a unique URL you can paste anywhere)
              </Typography>
            </li>
            <li>
              <Typography>
                <strong>Earn</strong> - all funds are instantly transferred to
                recipients (including affiliate payouts)
              </Typography>
            </li>
          </ol>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1">
            What do I need to create my own npayme?
          </Typography>
          <Typography>
            You need 2 things before you can create your npayme:
          </Typography>
          <ol>
            <li>
              <Typography>
                <strong>A cryptocurrency wallet</strong> - The npayme network
                is a decentralized Web3 system, so you need a cryptocurrency
                wallet. We recommended either{" "}
                <Link
                  href="https://metamask.io/download/"
                  target="_blank"
                  rel="noreferrer"
                >
                  MetaMask
                </Link>{" "}
                or{" "}
                <Link
                  href="https://www.coinbase.com/wallet/downloads"
                  target="_blank"
                  rel="noreferrer"
                >
                  Coinbase
                </Link>
              </Typography>
            </li>
            {/* <li>
              <Typography>
                <strong>NPAY tokens</strong> - You need to pay 1 NPAY token to
                create your npayme.{" "}
                <Link
                  href="https://ndau.io/knowledge-base/how-do-i-buy-npay-tokens/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get NPAY tokens here.
                </Link>
              </Typography>
            </li> */}
            <li>
              <Typography>
                <strong>The USDC address for your beneficiary</strong> - Patrons
                donate to an npayme using{" "}
                <Link
                  href="https://www.circle.com/en/usdc"
                  target="_blank"
                  rel="noreferrer"
                >
                  USDC
                </Link>{" "}
                and Beneficiaries receive USDC. Check with your Beneficiary to
                make sure you have their correct USDC address to receive the
                donations through your npayme.
              </Typography>
            </li>
          </ol>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1">
            How do people donate to my npayme?
          </Typography>
          <Typography>
            Donations to your npayme can be made using{" "}
            <Link
              href="https://www.circle.com/en/usdc"
              target="_blank"
              rel="noreferrer"
            >
              USDC
            </Link>{" "}
            tokens. Additional tokens including support for other payment types
            (credit cards) will be supported in the future.
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1">
            How do people receive their earnings?
          </Typography>
          <Typography>
            Beneficiaries receive their funds as{" "}
            <Link
              href="https://www.circle.com/en/usdc"
              target="_blank"
              rel="noreferrer"
            >
              USDC
            </Link>{" "}
            tokens. Creators and Affiliates both receive their earnings as{" "}
            <Link href="https://www.circle.com/en/usdc" target="_blank" rel="noreferrer">
              USDC
            </Link>{" "}
            tokens.
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1">
            I’m ready. What happens now?
          </Typography>
          <Typography>
            On the following screens you will connect your cryptocurrency
            wallet to the npayme network. At the end of the npayme creation
            process you will be given your own unique URL to your new npayme
            which you can immediately share on social media.
          </Typography>
        </Box>

        <Button
          component={RouterLink}
          to="/creator"
          variant="outlined"
          type="submit"
          onClick={handleClick}
          sx={{
            display: "flex",
            padding: "12px 16px",
            width: "547px",
            height: "56px",
            borderRadius: "12px",
            gap: "12px",
          }}
        >
          Get Started
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
