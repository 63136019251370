import { createBrowserRouter, Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Creator from "./features/creator/Creator";
import Patron from "./features/patron/Patron";
import Home from "./features/home/Home";

const Root = () => {
  return (
    <Box
      sx={{
        m: 0,
        p: 0,
        width: "100vw",
        height: "100vh",
      }}
    >
      {/* <Box sx={{ position: "fixed", top: 60, left: 80 }}>
        <img src="/assets/Logo.svg" width={224} height={67} />
      </Box> */}
      <Outlet />
    </Box>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    // errorElement: <ErrorPage />,
    //loader: RootLoader,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/creator",
        element: <Creator />,
      },
      {
        path: "/patron/:contract_address/:affiliate_address",
        element: <Patron />,
      },
    ],
  },
]);

export default router;
