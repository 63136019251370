import { Box, Button, Typography, Link } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "components/Slider";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";

import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const Beneficiary = (props) => {
  const {
    creator_earn,
    affiliate_earn,
    acceptUSDConEth,
    beneficiaries,
    setBeneficiary,
    handleCreatorEarnChange,
    handleAffiliateEarnChange,
    handleBeneficiaryChange,
    acceptUSDConEthereum,
  } = props;

  const handleBeneficiaryName = (idx, name) => {
    const update = beneficiaries.map((b, index) =>
      index === idx ? { ...b, name } : b
    );

    setBeneficiary(update);
  };

  const handleBeneficiaryAddress = (idx, address) => {
    const update = beneficiaries.map((b, index) =>
      index === idx ? { ...b, address: address.trim() } : b
    );

    setBeneficiary(update);
  };

  const handleAddBeneficiary = () => {
    setBeneficiary((prev) => {
      const totalPercentage = prev.reduce((accumulator, currentValue) => {
        accumulator += currentValue.percentage;
        return accumulator;
      }, 0);

      const newPercentage = totalPercentage / (prev.length + 1);
      const update = prev.map((b) => ({
        ...b,
        percentage: newPercentage,
      }));

      return [
        ...update,
        {
          name: "",
          beneficiary_adress: "",
          percentage: newPercentage,
        },
      ];
    });
  };

  // const ensureHundredPercent = (update) => {
  //   const totalPercentage = getBeneficiariesTotal(update);
  //   let exceed = totalPercentage + affiliate_earn + creator_earn - 100;
  //   if (exceed > 0) {
  //     if (exceed <= affiliate_earn) {
  //       setAffiliateEarn(affiliate_earn - exceed);
  //     } else {
  //       setCreatorEarn(creator_earn - (exceed - affiliate_earn));
  //       setAffiliateEarn(0);
  //     }
  //   } else if (exceed < 0) {
  //     setAffiliateEarn(affiliate_earn - exceed);
  //   }
  // };

  // const handleBeneficiaryChange = (idx, newValue) => {
  //   let update;
  //   if (beneficiaries.length === 1) {
  //     update = beneficiaries.map((b) => ({
  //       ...b,
  //       percentage: newValue,
  //     }));
  //   } else if (beneficiaries.length === 2) {
  //     const change = newValue - beneficiaries[idx].percentage;
  //     update = beneficiaries.map((b, index) =>
  //       index === idx
  //         ? { ...b, percentage: newValue }
  //         : {
  //             ...b,
  //             percentage: b.percentage - change < 0 ? 0 : b.percentage - change,
  //           }
  //     );
  //   } else {
  //     let count = 0;
  //     let delta = newValue - beneficiaries[idx].percentage;
  //     let change = beneficiaries.length - idx - 1 === 0 ? 0 : delta / (beneficiaries.length - idx - 1);
  //     update = beneficiaries.map((b, index) => {
  //       if (index < idx) {
  //         return b;
  //       } else if (index === idx) {
  //         return {
  //           ...b,
  //           percentage: newValue,
  //         };
  //       }
  //       if (b.percentage - change < 0) {
  //         count++;
  //         delta = delta - b.percentage;
  //         change = delta / (beneficiaries.length - idx - 1 - count);
  //         return {
  //           ...b,
  //           percentage: 0,
  //         };
  //       } else {
  //         delta = delta - change;
  //         return {
  //           ...b,
  //           percentage: b.percentage - change,
  //         };
  //       }
  //     });

  //     // Adjust the beneficiaries percentages for idx less than the one being change
  //     const totalPercentage = getBeneficiariesTotal(update);
  //     let carryOver = 0;
  //     for (let i = idx - 1; i >= 0; i--) {
  //       const exceed = totalPercentage + carryOver + affiliate_earn + creator_earn - 100;
  //       if (exceed > 0) {
  //         if (update[i].percentage - exceed >= 0) {
  //           carryOver = carryOver - exceed;
  //           update[i].percentage = update[i].percentage - exceed;
  //         } else {
  //           carryOver = carryOver - update[i].percentage;
  //           update[i].percentage = 0;
  //         }
  //       }
  //     }
  //   }

  //   ensureHundredPercent(update);
  //   setBeneficiary(update);
  // };

  function DeployOnEthereumRadio(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return (
      <FormControlLabel
        sx={{ display: "inline-block" }}
        checked={checked}
        {...props}
      />
    );
  }

  return (
    <Grid>
      <Box>
        <Typography variant="subtitle1">
          How are donations sent to Beneficiaries?
        </Typography>
        <Typography variant="body1">
          npayme currently supports donations from Patrons in{" "}
          <strong>USDC tokens on Ethereum, Polygon and zkSync Era.</strong>
        </Typography>
        <Typography>
          <strong>Beneficiaries</strong> will receive their npayme donations in
          the same token type as they were sent by the Patron.
        </Typography>
        <Typography>
          Check with your intended Beneficiary to verify you have their correct{" "}
          <strong>Beneficiary Payment Address</strong> so they can directly
          receive USDC donations through your npayme.
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          How do I get a Beneficiary Payment Address?
        </Typography>
        <Typography>
          If you are your own Beneficiary, you will need to get your own
          Beneficiary Payment Address.
        </Typography>
        <ul>
          <li>
            <Link
              href="https://ndau.io/knowledge-base/how-do-i-receive-usdc-on-ethereum/"
              target="_blank"
              rel="noreferrer"
            >
              Here's how to get your address to receive USDC on Ethereum.
            </Link>
          </li>
          <li>
            <Link
              href="https://ndau.io/knowledge-base/how-do-i-receive-usdc-on-zksync-era/"
              target="_blank"
              rel="noreferrer"
            >
              Here's how to get your address to receive USDC on zkSync Era.
            </Link>
          </li>
          <li>
            <Link
              href="https://ndau.io/knowledge-base/how-do-i-receive-usdc-on-polygon/"
              target="_blank"
              rel="noreferrer"
            >
              Here's how to get your address to receive USDC on Polygon (MATIC).
            </Link>
          </li>
        </ul>
        <Typography>
          The same Beneficiary Payment Address will be used to receive USDC on
          Ethereum, Polygon and zkSync Era.
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          What types of USDC Donations do you want your npayme to accept?
        </Typography>
        <Typography variant="body1">
          By default, you can create your npayme for free and it will accept
          USDC donation on both Polygon and zkSync Era.
        </Typography>
        <Typography>
          If you wish to also allow Patrons to donate to your npayme using USDC
          on Ethereum, you will pay a one-time setup fee of $100.
        </Typography>
        <Typography>
          <strong>Accept USDC Donations on Ethereum?</strong>
          <RadioGroup
            name="use-radio-group"
            defaultValue={acceptUSDConEth}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            onChange={acceptUSDConEthereum}
          >
            <DeployOnEthereumRadio value="N" label="No" control={<Radio />} />
            <DeployOnEthereumRadio value="Y" label="Yes" control={<Radio />} />
          </RadioGroup>
        </Typography>
      </Box>

      {beneficiaries.map((beneficiary, idx) => {
        return (
          <Box key={idx}>
            <Grid xs={12}>
              <Typography>Enter Beneficiary Name</Typography>
              <TextField
                variant="outlined"
                margin="dense"
                size="small"
                required
                fullWidth
                value={beneficiary.name}
                onChange={(event) =>
                  handleBeneficiaryName(idx, event.target.value)
                }
              />
            </Grid>
            <Grid xs={12}>
              <Typography>Add Beneficiary Payment Address</Typography>
              <TextField
                variant="outlined"
                margin="dense"
                size="small"
                required
                fullWidth
                value={beneficiary.address}
                onChange={(event) =>
                  handleBeneficiaryAddress(idx, event.target.value)
                }
              />
            </Grid>
          </Box>
        );
      })}
      {/* <Grid
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        rowSpacing={1}
        spacing={4}
        sx={{ padding: 0 }}
      >
        <Grid>
          <IconButton aria-label="add" onClick={handleAddBeneficiary}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid> */}
      {beneficiaries.length > 1 && (
        <>
          {beneficiaries.map((beneficiary, idx) => {
            return (
              <Grid xs={12} key={beneficiary.address}>
                <Slider
                  label={beneficiary.name}
                  fullWidth
                  value={beneficiary.percentage}
                  onChange={(event, newValue) =>
                    handleBeneficiaryChange(idx, newValue)
                  }
                />
              </Grid>
            );
          })}
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: 3 }}
          >
            <Grid direction="row" spacing={2} xs={6}>
              <Slider
                label="Affiliates Earn"
                value={affiliate_earn}
                onChange={handleAffiliateEarnChange}
              />
            </Grid>
            <Grid direction="row" spacing={2} xs={6}>
              <Slider
                label="Creator Earn"
                value={creator_earn}
                onChange={handleCreatorEarnChange}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Beneficiary;
