import { useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import { isMobile, isIOS } from "react-device-detect";
import {
  Typography,
  Box,
  LinearProgress,
  Button,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import { getETCPrice } from "../api/api";

// import {
//   request as conbaseConnectPay,
//   useCoinbaseConnect,
// } from "../features/CoinbaseWallet/hook/useCoinbaseConnect";
import {
  request as walletConnectPay,
  onWalletConnect,
  useWalletConnect,
} from "../features/WalletConnect/hook/useWalletConnect";
import {
  request as metamaskPay,
  onMetaMaskConnect,
} from "../features/MetaMask/hook/useMetaMask";
import {
  ensurChainId as cbEnsureChainId,
  onCoinbaseConnect,
  request as coinbasePay,
} from "../features/CoinbaseWallet/hook/useCoinbaseConnect";
import { getDidAddress } from "../features/WalletConnect/utils/address";
import { createContract, promote } from "../api/api";
import store from "../features/WalletConnect/store";
import {
  NONE,
  STEP_PREPARE_PAYMENT,
  STEP_PREPARE_DEPOSIT,
  STEP_DEPOSIT_TOKEN,
  STEP_CREATE_WIDGET,
  STEP_APPROVE_PROMOTE,
  STEP_ADDING_AFFILIATE,
  STEP_APPROVE_DONATION,
  STEP_SEND_DONATION,
  STEP_APPROVE_L1_ALLOWANCE,
  STEP_PREPARE_DONATION,
  STEP_DUMMY_SIGN,
} from "../utils";
import {
  WALLET,
  OPERATOR_ADDRESS,
  sendTransaction,
  commitPayment,
  sendDonation,
  sleep,
  mobile,
} from "../utils/blockchain";
import { EIP712Promote } from "../utils/eip712";
import { getExpiration, getWaitingMessage } from "utils";

const TokenBox = (props) => {
  const { token, amount, setAmount, amountUSD } = props;

  const getAmount = async (tokenName) => {
    const result = await getETCPrice(tokenName, "usd");
    const fee = amountUSD / result[tokenName].usd;
    setAmount(fee);
  };

  useEffect(() => {
    const coin = token === "ETH" ? "ethereum" : "usdc";
    getAmount(coin);
    const t = setInterval(() => {
      getAmount(coin);
    }, 30000);

    return () => {
      clearInterval(t);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        paddingLeft: 3,
      }}
    >
      {token === "ETH" && (
        <img src="/assets/eth.svg" alt="npay" style={{ paddingRight: "6px" }} />
      )}
      {token === "USDC" && (
        <img
          src="/assets/usdc.svg"
          alt="npay"
          style={{ paddingRight: "6px" }}
        />
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <span
            style={{
              display: "inline-block",
              fontSize: "22px",
              width: 262,
              color: "#2E3538",
              fontWeight: 600,
            }}
          >
            {token}
          </span>
          <span
            style={{
              fontSize: "22px",
              color: "#2E3538",
              fontWeight: 600,
            }}
          >
            {amount.toFixed(5)}
          </span>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <span
            style={{
              display: "inline-block",
              fontSize: "14px",
              color: "#2E3538",
              fontWeight: 400,
              width: 262,
            }}
          >
            {token === "ETH" && "on Ethereum"}
            {token === "USDC" && "on Eth"}
            {token === "USDC_ZK" && "on zkSync Era"}
            {token === "USDC_PG" && "on Polygon"}
          </span>
          <span>${parseFloat(amountUSD).toFixed(2)}</span>
        </Box>
      </Box>
    </Box>
  );
};

const ScanConnect = (props) => {
  const state = useSnapshot(store);
  const connectUrl = state.uri;

  const {
    args,
    setStep,
    setPatronAddress,
    setCreatorAddress,
    setContractAddress,
    notify,
    next,
    instruction,
    browser,
    wallet,
  } = props;

  const { political, action, promoterName, promoteMessage, acceptUSDConEth } =
    args;
  console.log("acceptUSDConEth........:", acceptUSDConEth);

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (isMobile && browser !== mobile) {
      if (action === "promote") {
        let urlBuilder;
        switch (wallet) {
          case "coinbase":
            urlBuilder = [
              `https://go.cb-w.com/dapp?cb_url=${window.location.host}${
                window.location.pathname
              }?browser=mobile&action=promote&promoter=${encodeURIComponent(
                promoterName
              )}&message=${encodeURIComponent(promoteMessage)}`,
            ];
            break;
          default:
            urlBuilder = [
              `https://metamask.app.link/dapp/${window.location.host}${
                window.location.pathname
              }?browser=mobile&action=promote&promoter=${encodeURIComponent(
                promoterName
              )}&message=${encodeURIComponent(promoteMessage)}`,
            ];
        }
        console.log("............................mobile switch");

        const redirectedUrl = urlBuilder.join("&");

        console.log("redirectedUrl............................", redirectedUrl);
        function openMetaMaskUrl(url) {
          const a = document.createElement("a");
          a.href = url;
          a.target = "_self";
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
        openMetaMaskUrl(redirectedUrl);
      }
    } else {
      console.log("I am good");
    }
  }, [browser]);

  const [waitingMessage, setWaitingMessage] = useState({
    NONE: false,
    STEP_CONNECT_WALLET: "Please connect your wallet...",
  });
  const [waitingStep, setWaitingStep] = useState(NONE);

  const connect = async (session, args) => {
    const {
      action,
      // Create
      campaign,
      creator_name,
      description,
      imageUrl,
      creator_earn,
      affiliate_earn,
      beneficiaries,
      goal,
      expiration,
      political,
      // Promote & Donate
      contract_address,
      affiliate_address,
      token,
      donation,
      promoterName,
      promoteMessage,
      wallet,
    } = args || {};

    const symbol = token.endsWith("_ZK")
      ? token.substring(0, token.length - 3)
      : token;

    setWaitingMessage(getWaitingMessage(donation, symbol, token, wallet));

    const { s: signature, p: payload, topic } = session;
    const from = getDidAddress(payload.iss);
    console.log(" action %s, token %s, from %s", action, token, from);
    if (from) {
      let Pay;
      if (topic === null) {
        switch (wallet) {
          case WALLET.coinbase:
            Pay = coinbasePay;
            break;
          default:
            Pay = metamaskPay;
        }
      } else {
        Pay = walletConnectPay;
      }

      let result;
      switch (action) {
        case "create":
          try {
            if (acceptUSDConEth == "Y") {
              if (amount == 0) {
                return notify("Please wait for an updated ETH price", "error");
              } else {
                setWaitingStep(STEP_PREPARE_PAYMENT);
              }
            }

            // Request to pay 1 NPAY to promote the campaign
            let result =
              acceptUSDConEth == "Y"
                ? await commitPayment(
                    Pay,
                    from, // creator_address,
                    OPERATOR_ADDRESS,
                    amount,
                    "ETH",
                    "transfer"
                  )
                : await commitPayment(
                    Pay,
                    from, // creator_address,
                    OPERATOR_ADDRESS,
                    "0",
                    "NPAY_ZK",
                    "transfer"
                  );

            console.log("transfer request return................", result);
            if (result && result.success !== false) {
              // Confirm payment to the backend
              if (
                result.tx_hash ||
                result.signature ||
                result.flag === "skip"
              ) {
                const npayment = result.signature
                  ? { ...result, acceptUSDConEth }
                  : {
                      method: "transfer",
                      token: acceptUSDConEth == "Y" ? "ETH" : "NPAY_ZK",
                      amount: acceptUSDConEth == "Y" ? amount : 0,
                      uuid: result.uuid,
                      tx_hash: result.flag === "skip" ? "skip" : result.tx_hash,
                      accept_usdc_on_ethereum: acceptUSDConEth,
                    };
                try {
                  notify(
                    "Creating a smart contract for the campaign. Please wait...",
                    "info"
                  );
                  console.log(`Deploying the campaign: ${campaign}`);
                  // TEMPORARY: Mimic delay on the real blockchain
                  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
                  // await delay(6000);

                  setWaitingStep(STEP_CREATE_WIDGET);

                  await sleep(15000);

                  result = await createContract(
                    campaign,
                    from, // creator_address,
                    creator_name,
                    description,
                    imageUrl,
                    creator_earn,
                    affiliate_earn,
                    goal,
                    getExpiration(expiration),
                    beneficiaries,
                    political,
                    npayment
                  );
                } catch (e) {
                  console.log("Exception", e);
                  return notify(
                    e.response?.data?.message || e.message,
                    "error"
                  );
                } finally {
                  setWaitingStep(NONE);
                }
              }
            }

            console.log("create contract result......", result);
            if (result && result.success) {
              store.approved = true;
              setCreatorAddress(from);
              setContractAddress(result.contract_address);
              next();
              notify("widget created successfully", "success");
            } else {
              setWaitingStep(NONE);
              notify(
                typeof result === "string" ? result : result?.message,
                "error"
              );
            }
          } catch (e) {
            setWaitingStep(NONE);
            notify(e.response?.data?.message || e.message, "error");
          }
          break;
        case "promote":
          if (political || true) {
            result = await sendTransaction(Pay, {
              from,
              EIP712Msg: EIP712Promote(promoterName, affiliate_address, 0),
            });
            console.log(result);
          } else {
            setWaitingStep(STEP_PREPARE_PAYMENT);

            // Request to pay 1 NPAY to promote the campaign
            result = await commitPayment(
              Pay,
              from,
              contract_address,
              political ? "0" : "1",
              political ? "USDC" : "NPAY_ZK",
              "approve",
              () =>
                political
                  ? setWaitingStep(STEP_DUMMY_SIGN)
                  : setWaitingStep(STEP_APPROVE_PROMOTE)
            );
          }

          // Confirm payment to the backend
          try {
            if (result && result.success !== false) {
              if (result.tx_hash || result.EIP712Msg) {
                console.log(`${promoterName} is promoting the campaign...`);
                setWaitingStep(STEP_ADDING_AFFILIATE);

                result = await promote(
                  contract_address,
                  from,
                  promoterName,
                  promoteMessage,
                  result.tx_hash,
                  result.signature,
                  result.EIP712Msg
                );
              }
            }
          } catch (e) {
            console.log("...........e", e);
            return notify(e.response?.data?.message || e?.message, "error");
          } finally {
            setWaitingStep(NONE);
          }

          console.log("................result 1", result);
          if (result && result.success === true) {
            setStep(10);
            setPatronAddress(from);
            return notify("successfully promoted the campaign", "success");
          }
          return notify(
            typeof result === "string"
              ? result
              : result?.message || "Something wrong",
            "error"
          );
        case "donate":
          if (political) {
            console.log("Show political ");
            throw "Not supported yet";
          }
          if (token === "USDC_ZK") {
            setWaitingStep(STEP_PREPARE_PAYMENT);
            result = await commitPayment(
              Pay,
              from,
              contract_address,
              donation,
              token,
              "approve",
              () => setWaitingStep(STEP_APPROVE_DONATION)
            );
            try {
              if (result && result.success === false) {
                return notify(result.message, "error");
              }

              console.log(`${from} is donating to the campaign...`);
              if (result && result.success === true) {
                setWaitingStep(STEP_SEND_DONATION);

                await sleep(15000);

                result = await sendDonation(
                  from,
                  contract_address,
                  affiliate_address,
                  token,
                  donation,
                  ""
                );
              }

              console.log("donate result.......1", result);
              if (!result || result.success === false) {
                setWaitingStep(NONE);
                return notify(result.message, "error");
              }

              if (result === true || result.success === true) {
                setStep(20);
                return notify("Success", "success");
              }
            } catch (e) {
              return notify(e.response?.data?.message || e.message, "error");
            } finally {
              setWaitingStep(NONE);
            }
          } else if (token === "USDC") {
            setWaitingStep(STEP_PREPARE_PAYMENT);

            result = await commitPayment(
              Pay,
              from,
              from,
              donation,
              token,
              "deposit",
              (stepNo) =>
                setWaitingStep(stepNo || STEP_APPROVE_L1_ALLOWANCE) ||
                (stepNo === STEP_PREPARE_DEPOSIT
                  ? STEP_DEPOSIT_TOKEN
                  : STEP_PREPARE_DEPOSIT)
            );

            if (result && result.success === false) {
              return notify(result.message, "error");
            }

            setWaitingStep(STEP_PREPARE_DONATION);
            // Wait for 10 seconds to give the allowance time to make it into the mempool/blockchain
            await sleep(15000);

            args.token = "USDC_ZK";
            connect(session, args);
          }
          break;
        default:
      }
    }
  };

  useWalletConnect((session, args) => {
    connect(session, args);
  });

  useEffect(() => {
    const wallet = async () => {
      if (args.action === "donate") {
        setWaitingStep("STEP_CONNECT_WALLET");
      }
      const res = await onWalletConnect(args);
      if (res.success === false) {
        notify(res.message, "error");
      }
      console.log("wallet connect succesfully: ", res);
    };
    wallet();
  }, [args]);

  const injectWallet = async (wallet) => {
    let from;
    switch (wallet) {
      case WALLET.coinbase:
        from = await onCoinbaseConnect();
        args.wallet = WALLET.coinbase;
        break;
      default:
        from = await onMetaMaskConnect();
        args.wallet = WALLET.metamask;
    }
    if (from) {
      console.log("Connected wallet: .", from);
      const iss = `${store.l2ChainId}:did:pkh:${from}`;

      connect(
        {
          p: {
            iss,
          },
          s: {},
          topic: null,
        },
        args
      );
    }
  };

  const onClick = async () => {
    notify("Copied to clipboard");
  };

  console.log("rereder............", waitingStep);

  return (
    <Grid
      container
      xs={12}
      direction="column"
      alignContent="center"
      alignItems="center"
    >
      <Grid container xs={12} spacing={3}>
        {/* <Grid sx={{ w: "100%" }}>
        <Typography sx={{ marginLeft: "auto", marginRight: "auto" }}>
          Need help connecting your Trust Wallet?&nbsp;
          <a
            href="https://ndau.io/knowledge-base/how-do-i-connect-to-npayme-using-trust-wallet-and-wallet-connect/"
            target="_blank"
          >
            Learn how here
          </a>
        </Typography>
      </Grid>
      <Grid
        sx={{
          width: 190,
          height: 190,
          padding: "12px",
          border: "1px solid",
          borderRadius: 3,
        }}
      >
        {connectUrl && (
          <CopyToClipboard text={connectUrl}>
            <QRCode size={166} value={connectUrl} onClick={onClick} />
          </CopyToClipboard>
        )}
      </Grid>
      <Grid sx={{ display: "flex" }}>
        <Typography style={{ color: "#1A1A1A" }}>
          Click the QR Code to copy
        </Typography>
        <span
          style={{
            backgroundColor: "#EFE7FE",
            borderRadius: 50,
            width: 146,
            height: 30,
            textAlign: "center",
            marginLeft: 4,
            marginRight: 4,
          }}
        >
          <img
            style={{ width: 21, height: 12 }}
            src="/assets/wallet_connect.svg"
            alt="WalletConnect"
          />
          Wallet Connect
        </span>
        <Typography sx={{ color: "#1A1A1A" }}>URL</Typography>
      </Grid> */}
        {/* {(args.action === "promote" || args.action === "create") && ( */}
        <Grid container xs={12}>
          <Grid xs={4}>
            <img
              src="/assets/metamask.svg"
              alt="metamask"
              width="144px"
              height="68px"
              display="inline-block"
            />
          </Grid>
          <Grid
            container
            xs={8}
            sx={{ alignContent: "center", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{ fontSize: 12, display: "flex" }}
                onClick={() => injectWallet(WALLET.metamask)}
              >
                Connect Metamask
              </Button>
              <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
                Need MetaMask?&nbsp;
                <a
                  href="https://metamask.io/download/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download it here
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={4}>
            <img
              src="/assets/coinbase.svg"
              alt="metamask"
              width="144px"
              height="104px"
              display="inline-block"
            />
          </Grid>
          <Grid
            container
            xs={8}
            sx={{ alignContent: "center", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{ fontSize: 12, display: "flex" }}
                onClick={() => injectWallet(WALLET.coinbase)}
              >
                Connect Coinbase
              </Button>
              <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
                Need Coinbase?&nbsp;
                <a
                  href="https://www.coinbase.com/wallet/downloads"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download it here
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {waitingMessage[waitingStep] && (
        <Grid
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 3,
            border: "1px solid #7129F5",
          }}
        >
          {typeof waitingMessage[waitingStep] === "string" ? (
            <Typography>{waitingMessage[waitingStep]}</Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ textAlign: "center" }}>
                {waitingMessage[waitingStep][0]}
              </Typography>
              <Typography
                sx={{ fontWeight: 700, color: "red", textAlign: "center" }}
              >
                {waitingMessage[waitingStep][1]}
              </Typography>
            </Box>
          )}

          <LinearProgress sx={{ width: "100%", backgroundColor: "#7129F5" }} />
        </Grid>
      )}
      {(args.action === "promote" || args.action === "create") && (
        <Grid
          xs={12}
          sx={{
            display: "grid",
            gridTemplateRows: "repeat(2, 1fr)",
            gap: "3px",
            height: 188,
            padding: "20px",
            backgroundColor: "#F9F5FF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" component="h4">
              {instruction}.
            </Typography>
          </Box>
          {args.acceptUSDConEth === "Y" && (
            <Box sx={{ position: "relative" }}>
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    alignContent: "center",
                    border: "1px solid",
                    borderRadius: 2,
                    borderColor: "#EFE7FE",
                    backgroundColor: "#EFE7FE",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  <TokenBox
                    token="ETH"
                    amount={amount}
                    setAmount={setAmount}
                    amountUSD={100}
                  />
                </Box>
                <Typography sx={{ marginTop: "26px", float: "right" }}>
                  Need to buy ETH tokens?&nbsp;
                  <a
                    href="https://ndau.io/knowledge-base/how-do-i-buy-npay-tokens/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn how here
                  </a>
                </Typography>
                {/*<Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 3,
                  marginLeft: 3,
                }}
              >
                <img src="/assets/npay.svg" alt="npay"></img>
                <Box sx={{ flexGrow: 1, paddingLeft: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: 262,
                        color: "#2E3538",
                        fontWeight: 600,
                      }}
                    >
                      NPAY
                    </span>
                    <span style={{ color: "#2E3538", fontWeight: 600 }}>
                      1.00
                    </span>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <span style={{ display: "inline-block", width: 262 }}>
                      NPAY Network
                    </span>
                    <span>≈1.54 USD</span>
                  </Box>
                </Box>
              </Box>*/}
              </Box>
              {/*<Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                // margin: "0 auto",
                top: "calc(50% - 24.5px)",
                left: "calc(50% - 24.5px)",
                width: 49,
                height: 49,
                borderRadius: 24.5,
                backgroundColor: "#FFFFFF",
              }}
            >
              <Box
                sx={{
                  width: 14,
                  height: 6,
                  borderTop: "2px solid",
                  borderBottom: "2px solid",
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                height: 76,
                border: "1px solid",
                borderRadius: 2,
                borderColor: "#EFE7FE",
                backgroundColor: "#EFE7FE",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 3,
                  marginLeft: 3,
                }}
              >
                <img src="/assets/usdc.svg" alt="usdc"></img>
                <Box sx={{ flexGrow: 1, paddingLeft: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: 262,
                        color: "#2E3538",
                        fontWeight: 600,
                      }}
                    >
                      USDC
                    </span>
                    <span style={{ color: "#2E3538", fontWeight: 600 }}>
                      1.53999
                    </span>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <span style={{ display: "inline-block", width: 262 }}>
                      USDC Coin
                    </span>
                    <span>≈ 1.00939 USD</span>
                  </Box>
                </Box>
              </Box>
            </Box>*/}
            </Box>
          )}
        </Grid>
      )}
      {args.action === "donate" && (
        <Box
          //fullWidth
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            backgroundColor: " #F9F5FF",
            borderRadius: "12px",
            paddingBottom: "26px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 6,
            }}
          >
            <Typography sx={{ paddingTop: "31px" }}>
              <img src="/assets/info.svg" />
              {` You are donating ${args.amount} USDC to ${args.beneficiary.name}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "460px",
              height: "76.61px",
              borderRadius: "8px",
              backgroundColor: "#EFE7FE",
              justifyContent: "center",
              alignItems: "center",
              padding: "14px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginLeft: 3,
              }}
            >
              <img src="/assets/usdc.svg" alt="npay"></img>
              <Box sx={{ flexGrow: 1, paddingLeft: 3 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: "22px",
                      width: 262,
                      color: "#2E3538",
                      fontWeight: 600,
                    }}
                  >
                    USDC
                  </span>
                  <span
                    style={{
                      fontSize: "22px",
                      color: "#2E3538",
                      fontWeight: 600,
                    }}
                  >
                    {args.amount.toFixed(5)}
                  </span>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      color: "#2E3538",
                      fontWeight: 400,
                      width: 262,
                    }}
                  >
                    {args.token === "USDC" && "on Eth"}
                    {args.token === "USDC_ZK" && "on zkSync Era"}
                    {args.token === "USDC_PG" && "on Polygon"}
                  </span>
                  <span>${parseFloat(args.amountUSD).toFixed(2)}</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default ScanConnect;
