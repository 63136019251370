import { useCallback } from "react";
import { Typography, FormLabel, Checkbox, TextField, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Unstable_Grid2";
import ImageIcon from "@mui/icons-material/PhotoSizeSelectActual";
import InputAdornment from "@mui/material/InputAdornment";
import Slider from "components/Slider";
import TargetIcon from "@mui/icons-material/TrackChanges";
import { useDropzone } from "react-dropzone";
import { upload } from "../../api/api";

const CampaignInfo = (props) => {
  const {
    campaign,
    handleCampaignName,
    imageUrl,
    setImageUrl,
    expiration,
    setExpiredDate,
    goal,
    setGoal,
    creator_earn,
    handleCreatorEarnChange,
    affiliate_earn,
    handleAffiliateEarnChange,
    percentage,
    handleBeneficiaryChange,
    setPoliticcalCampaign,
    notify,
  } = props;

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 2) {
      return notify("Please select one file only", "error");
    }

    acceptedFiles.forEach(async (file) => {
      const { name, path, size, type } = file;
      if (
        !["image/jpg", "image/jpeg", "image/png", "image/gif"].includes(type)
      ) {
        return notify(`This file type is not supported: ${type}`, "error");
      }

      if (size > 2 * 1024 * 1024) {
        return notify("File size should be less than 2Mb", "error");
      }

      const cacheName = {};

      // Refer to: https://cloudinary.com/documentation/image_upload_api_reference#upload
      if (!cacheName[name]) {
        cacheName[name] = true;
        const data = new FormData();
        data.append("file", file);
        // setUploading(true);

        await upload(data)
          .then((res) => {
            console.log(res);
            if (res.success) {
              setImageUrl(res.secure_url);
              notify("File uploaded successfully", "success");
            } else {
              notify(res.message, "error");
            }
          })
          .catch((e) => {
            notify(`Failed to upload file ${file.name}`, "error");
          });
        //  setUploading(false);
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography>Campaign Name</Typography>
        <TextField
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          value={campaign}
          placeholder="Your campaign name"
          onChange={handleCampaignName}
        />
      </Grid>
      {/* <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          fontSize: "12px",
          fontStyle: "italic",
          marginTop: "-8px",
          marginBottom: "-12px",
        }}
      >
        <FormLabel>Political Campaign</FormLabel>
        <Checkbox onChange={(e) => setPoliticcalCampaign(e.target.value)} />
      </Grid> */}
      <Grid xs={12}>
        <Typography>Add Image/Video</Typography>
        <Box {...getRootProps()}>
          <TextField
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            value={imageUrl}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ImageIcon />
                </InputAdornment>
              ),
            }}
          />
          <input {...getInputProps()} />
        </Box>
      </Grid>
      <Grid xs={12}>
        <Typography>Set Campaign End Date</Typography>
        <DatePicker
          onChange={(e) => setExpiredDate(e)}
          value={expiration}
          minDate={new Date()}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              InputLabelProps={{ lineheight: 0 }}
            />
          )}
        />
      </Grid>
      <Grid xs={12}>
        <Typography>Set Campaign Goal</Typography>
        <TextField
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          readOnly
          value={goal}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <TargetIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setGoal(e.target.value)}
        />
      </Grid>
      <Grid>
        <Typography>
          Click and drag on the sliders below to adjust Earn rates.
        </Typography>
      </Grid>
      <Grid
        spacing={2}
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid direction="row" spacing={2} xs={6}>
          <Slider
            label="Affiliates Earn"
            value={affiliate_earn}
            onChange={handleAffiliateEarnChange}
          />
        </Grid>
        <Grid direction="row" spacing={2} xs={6}>
          <Slider
            label="You Earn"
            value={creator_earn}
            onChange={handleCreatorEarnChange}
          />
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Slider
          label="Beneficiaries Earn"
          fullWidth
          value={percentage}
          onChange={(event, newValue) => handleBeneficiaryChange(0, newValue)}
        />
      </Grid>
    </Grid>
  );
};

export default CampaignInfo;
