import { Grid, Box, Typography, Link } from "@mui/material";

const ShareEarn = (props) => {
  const { campaignName, campaignUrl, affiliateEarn, political } = props;
  const receivedToken = political ? "USDC" : "NPAY";

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",

        left: "calc(50% - 410px)",
        // top: "128px",
        width: "100%",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1">
          What can npayme do for me as an Affiliate?
        </Typography>
        <Typography variant="body1">
          With <strong>npayme</strong>, our goal is that everyone—every creator,
          every cause, every consumer—prospers the more you grow. With our
          revolutionary Web3 payment system, creators, causes and charities can
          easily use social media to promote their offer, while incentivizing
          affiliates to spread the word and instantly get paid. Grow an audience
          that truly cares about your cause and content and spread your message
          through affiliates whose earnings increase the more you prosper.
        </Typography>
        <Typography variant="body1">
          By becoming an Affiliate, you can <strong>Share</strong> a unique
          Affiliate link to your favorite npayme campaign with your followers on
          social media and automatically <strong>Earn</strong> whenever someone
          Donates to it.
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1">
          How much will I Earn by becoming an Affiliate of this campaign?
        </Typography>
        <Typography>
          As an Affiliate of this <strong>{campaignName}</strong> npayme
          campaign, you will earn {affiliateEarn}% of all donations made through
          your unique Affiliate npayme link.
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1">
          How do I register as an Affiliate?
        </Typography>
        <Typography>
          Becoming an Affiliate is free! The npayme network is a decentralized
          Web3 system, so you need a cryptocurrency wallet in order to receive
          your earnings. We recommended either{" "}
          <Link
            href="https://metamask.io/download/"
            target="_blank"
            rel="noreferrer"
          >
            MetaMask
          </Link>{" "}
          or{" "}
          <Link
            href="https://www.coinbase.com/wallet/downloads"
            target="_blank"
            rel="noreferrer"
          >
            CoinBase
          </Link>
        </Typography>
        {/* <ol>
          <li>
            <Typography>
              <strong>MetaMask wallet</strong> - The npayme network is a
              decentralized Web3 system, so you need a MetaMask cryptocurrency
              wallet.{" "}
              <Link
                href="https://metamask.io/download/"
                target="_blank"
                rel="noreferrer"
              >
                Download MetaMask here.
              </Link>
            </Typography>
          </li>
          {political ? (
            <li>
              <Typography>
                A signature from your wallet (affiliate wallet)
              </Typography>
            </li>
          ) : (
            <li>
              <Typography>
                <strong>NPAY tokens</strong> - You need to pay 1 NPAY token to
                become an Affiliate.{" "}
                <Link
                  href="https://ndau.io/knowledge-base/how-do-i-buy-npay-tokens/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get NPAY tokens here.
                </Link>
              </Typography>
            </li>
          )}
        </ol> */}
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1">
          How will I receive my earnings?
        </Typography>
        <Typography>
          You will receive your earnings as{" "}
          {true ? (
            <Link
              href="https://www.circle.com/en/usdc"
              target="_blank"
              rel="noreferrer"
            >
              USDC
            </Link>
          ) : (
            <Link href="https://ndau.io/npay" target="_blank" rel="noreferrer">
              NPAY
            </Link>
          )}{" "}
          tokens, which will be automatically sent to your cryptocurrency
          wallet.
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1">
          I’m ready. What happens now?
        </Typography>
        <Typography>
          On the following screens you will connect your cryptocurrency wallet
          to the npayme network. At the end of the npayme Affiliate registration
          process, you will be given your own unique URL to your new Affiliate
          npayme which you can immediately share on social media.
        </Typography>
      </Box>
    </Grid>
  );
};

export default ShareEarn;
