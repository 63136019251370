import { useState, useCallback } from "react";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Unstable_Grid2";
import MuiAlert from "@mui/material/Alert";
import Container from "../../components/Container";
import Why from "features/campaign/Why";
import PreviewCampaign from "features/campaign/Preview";
import CampaignInfo from "features/campaign/CampaignInfo";
import Beneficiary from "features/campaign/Beneficiary";
import ScanConnect from "../../components/ScanConnect";
import Share from "../campaign/Share";
import { getRemainingDays, getExpiration } from "utils";

const dao_earn = 2.5;

const Creator = () => {
  const [expiration, setExpiredDate] = useState(null);
  const [open, setSnackbar] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState("success");
  const [creator_name, setCreatorName] = useState("");
  const [campaign, setCampaign] = useState("");
  const [political, setPoliticcalCampaign] = useState(false);
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [beneficiaries, setBeneficiary] = useState([
    { name: "", address: "", percentage: 92 },
  ]);
  const [creator_earn, setCreatorEarn] = useState(1);
  const [affiliate_earn, setAffiliateEarn] = useState(7);
  const [creatorAddress, setCreatorAddress] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [acceptUSDConEth, setAcceptUSDConEth] = useState("N");
  const [goal, setGoal] = useState(1000);
  const [step, setStep] = useState(1);

  const notify = useCallback((msg, severity) => {
    setMessage(msg);
    setSeverity(severity);
    setSnackbar(true);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar(false);
  };

  const next = () => {
    window.scrollTo(0, 0);
    setStep((prev) => (prev < 6 ? prev + 1 : prev));
  };

  const back = () => {
    window.scrollTo(0, 0);
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };

  const handleCampaignName = (event) => {
    setCampaign(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleAcceptUSDConEthereum = (v) => {
    setAcceptUSDConEth(v.target.value);
  };

  const handleCreatorEarnChange = (event, newValue) => {
    const delta = newValue - creator_earn;
    const beneficiaryChange = delta / beneficiaries.length;
    const update = beneficiaries.map((b) => ({
      ...b,
      percentage:
        b.percentage - beneficiaryChange < 0
          ? 0
          : b.percentage - beneficiaryChange,
    }));

    setCreatorEarn(newValue);

    const totalPercentage = getBeneficiariesTotal(update);
    let exceed = totalPercentage + affiliate_earn + newValue - 100;
    if (exceed > 0) {
      setAffiliateEarn(affiliate_earn - exceed);
    }
    setBeneficiary(update);

    // setBeneficiaryEarn(getBeneficiariesTotal(update));
  };

  const handleAffiliateEarnChange = (event, newValue) => {
    const delta = newValue - affiliate_earn;
    const beneficiaryChange = delta / beneficiaries.length;
    const update = beneficiaries.map((b) => ({
      ...b,
      percentage:
        b.percentage - beneficiaryChange < 0
          ? 0
          : b.percentage - beneficiaryChange,
    }));

    setAffiliateEarn(newValue);

    const totalPercentage = getBeneficiariesTotal(update);
    let exceed = totalPercentage + creator_earn + newValue - 100;
    if (exceed > 0) {
      setCreatorEarn(creator_earn - exceed);
    }
    setBeneficiary(update);

    // setBeneficiaryEarn(getBeneficiariesTotal(update));
  };

  const getBeneficiariesTotal = (update) =>
    update.reduce((accumulator, currentValue) => {
      accumulator += currentValue.percentage;
      return accumulator;
    }, 0);

  const ensureHundredPercent = (update) => {
    const totalPercentage = getBeneficiariesTotal(update);
    let exceed = totalPercentage + affiliate_earn + creator_earn - 100;
    if (exceed > 0) {
      if (exceed <= affiliate_earn) {
        setAffiliateEarn(affiliate_earn - exceed);
      } else {
        setCreatorEarn(creator_earn - (exceed - affiliate_earn));
        setAffiliateEarn(0);
      }
    } else if (exceed < 0) {
      setAffiliateEarn(affiliate_earn - exceed);
    }
  };

  const handleBeneficiaryChange = (idx, newValue) => {
    let update;
    if (beneficiaries.length === 1) {
      update = beneficiaries.map((b) => ({
        ...b,
        percentage: newValue,
      }));
    } else if (beneficiaries.length === 2) {
      const change = newValue - beneficiaries[idx].percentage;
      update = beneficiaries.map((b, index) =>
        index === idx
          ? { ...b, percentage: newValue }
          : {
              ...b,
              percentage: b.percentage - change < 0 ? 0 : b.percentage - change,
            }
      );
    } else {
      let count = 0;
      let delta = newValue - beneficiaries[idx].percentage;
      let change =
        beneficiaries.length - idx - 1 === 0
          ? 0
          : delta / (beneficiaries.length - idx - 1);
      update = beneficiaries.map((b, index) => {
        if (index < idx) {
          return b;
        } else if (index === idx) {
          return {
            ...b,
            percentage: newValue,
          };
        }
        if (b.percentage - change < 0) {
          count++;
          delta = delta - b.percentage;
          change = delta / (beneficiaries.length - idx - 1 - count);
          return {
            ...b,
            percentage: 0,
          };
        } else {
          delta = delta - change;
          return {
            ...b,
            percentage: b.percentage - change,
          };
        }
      });

      // Adjust the beneficiaries percentages for idx less than the one being change
      const totalPercentage = getBeneficiariesTotal(update);
      let carryOver = 0;
      for (let i = idx - 1; i >= 0; i--) {
        const exceed =
          totalPercentage + carryOver + affiliate_earn + creator_earn - 100;
        if (exceed > 0) {
          if (update[i].percentage - exceed >= 0) {
            carryOver = carryOver - exceed;
            update[i].percentage = update[i].percentage - exceed;
          } else {
            carryOver = carryOver - update[i].percentage;
            update[i].percentage = 0;
          }
        }
      }
    }

    ensureHundredPercent(update);
    setBeneficiary(update);
  };

  const toPreviewCampaign = () => {
    const { name, address } = beneficiaries[0] || {};
    if (!name) {
      return notify("Please enter beneficiary name", "info");
    }

    if (!address || address.length != 42) {
      return notify("The beneficiary address doesn't look corect", "info");
    }

    next();
  };

  const dateLeft = getRemainingDays(expiration);
  const setExpiration = (v) => setExpiredDate(getExpiration(v));

  return (
    <>
      {step === 1 && (
        <Container
          paper={{ marginTop: 180 }}
          primaryTitle="Hello"
          secondaryTitle="Creator"
          subTitle="Let's create your npayme campaign."
        >
          <Why
            name={creator_name}
            message={description}
            setName={setCreatorName}
            setPromoteMessage={setDescription}
          />
          <Grid xs={12} justifyContent="center" alignItems="start">
            <Button
              sx={{
                marginTop: 6,
                backgroundColor: "#7129f5",
                height: 37.13,
              }}
              variant="outlined"
              onClick={next}
              fullWidth
              disabled={!creator_name || !description}
            >
              Next
            </Button>
          </Grid>
        </Container>
      )}
      {step === 2 && (
        <Container
          back={back}
          paper={{ marginTop: 68 }}
          primaryTitle="Campaign"
          secondaryTitle="Info"
          subTitle="Enter campaign details and set how you would you like to distribute npayme assets"
        >
          <CampaignInfo
            campaign={campaign}
            description={description}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            expiration={expiration}
            setExpiredDate={setExpiration}
            goal={goal}
            setGoal={setGoal}
            affiliate_earn={affiliate_earn}
            creator_earn={creator_earn}
            percentage={getBeneficiariesTotal(beneficiaries)}
            notify={notify}
            handleCampaignName={handleCampaignName}
            handleDescription={handleDescription}
            handleAffiliateEarnChange={handleAffiliateEarnChange}
            handleCreatorEarnChange={handleCreatorEarnChange}
            handleBeneficiaryChange={handleBeneficiaryChange}
            setPoliticcalCampaign={setPoliticcalCampaign}
          />
          <Grid xs={12}>
            <Button
              fullWidth
              variant="outlined"
              onClick={next}
              disabled={!campaign}
            >
              Next
            </Button>
          </Grid>
        </Container>
      )}
      {step === 3 && (
        <Container
          back={back}
          paper={{ width: 660, marginTop: 68 }}
          primaryTitle="Beneficiary"
          secondaryTitle="Info"
          // subTitle="Enter the beneficiary details you want to support through this npayme campaign."
        >
          <Beneficiary
            beneficiaries={beneficiaries}
            creator_earn={creator_earn}
            affiliate_earn={affiliate_earn}
            setBeneficiary={setBeneficiary}
            acceptUSDConEth={acceptUSDConEth}
            handleCreatorEarnChange={handleCreatorEarnChange}
            handleAffiliateEarnChange={handleAffiliateEarnChange}
            handleBeneficiaryChange={handleBeneficiaryChange}
            acceptUSDConEthereum={handleAcceptUSDConEthereum}
          />
          <Grid xs={12}>
            <Button
              fullWidth
              variant="outlined"
              onClick={toPreviewCampaign}
              disabled={!beneficiaries[0].name || !beneficiaries[0].address}
            >
              Preview Campaign
            </Button>
          </Grid>
        </Container>
      )}
      {step === 4 && (
        <Container
          back={back}
          paper={{ width: 870, marginTop: 68 }}
          primaryTitle="Preview"
          secondaryTitle="Campaign"
        >
          <PreviewCampaign
            campaignName={campaign}
            imageUrl={imageUrl}
            description={description}
            dateLeft={dateLeft}
            raised={0}
            totalRaisedFund={0}
            goal={goal}
            creator={{
              earn: creator_earn * 100,
              name: creator_name,
            }}
            affiliate={{
              earn: affiliate_earn * 100,
              name: creator_name,
            }}
            beneficiary={beneficiaries[0]}
          />
          <Button
            sx={{ marginTop: "16px" }}
            variant="outlined"
            onClick={next}
            fullWidth
          >
            Create Campaign
          </Button>
        </Container>
      )}
      {step === 5 && (
        <Container
          back={back}
          paper={{ marginTop: 42 }}
          secondaryTitle="Connect your Wallet"
          // secondaryTitle="Scan & Connect"
          // subTitle="Use your WalletConnect mobile app to scan the QR code below and authorize connection to this campaign."
        >
          <ScanConnect
            setCreatorAddress={setCreatorAddress}
            setContractAddress={setContractAddress}
            notify={notify}
            next={next}
            instruction={
              acceptUSDConEth === 'Y'
                ? "The creation of this campaign requires a setup fee of 100 USDC on Ethereum"
                : "The creation of this campaign requires a signature from your wallet"
            }
            // instruction="The creation of this campaign requires a setup fee of 1 NPAY token"
            args={{
              action: "create",
              token: "NPAY_ZK",
              campaign,
              creator_name,
              contract_address: contractAddress,
              affiliate_address: creatorAddress,
              promoterName: creator_name,
              description,
              imageUrl,
              creator_earn,
              affiliate_earn,
              beneficiaries,
              goal,
              expiration,
              political,
              acceptUSDConEth,
            }}
          />
        </Container>
      )}
      {step === 6 && (
        <Container
          paper={{ marginTop: 106 }}
          primaryTitle="Campaign"
          secondaryTitle="Created"
          subTitle="Spread the word and share on socials."
        >
          <Share
            affiliateAddress={creatorAddress}
            contractAddress={contractAddress}
            notify={notify}
          />
        </Container>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          severity={severity}
          variant="filled"
          elevation={6}
          sx={{ width: "100%" }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default Creator;
