import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography, TextField, MenuItem, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";

import Cash from "./Cash";
import { getETCPrice } from "../../api/api";

const MAX_DONATION = 23200;
const LimitSelection = (props) => {
  const { onChange } = props;

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props: RadioProps) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid",
        padding: "6px",
        marginTop: "6px",
        marginBottom: "6px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontWeight: 600, margin: "auto" }}>
        Maximum Donation
      </Typography>
      <RadioGroup
        row
        defaultValue="0"
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        sx={{ display: "flex", justifyContent: "space-around" }}
        onChange={onChange}
      >
        {/* <FormControlLabel
          value="23200"
          control={<BpRadio />}
          label="$23,200/Couple"
          sx={{ alignItems: "end" }}
        /> */}
        <FormControlLabel
          value="11600"
          control={<BpRadio />}
          label="$11,600/Individual"
          sx={{ alignItems: "end" }}
        />
        <FormControlLabel
          value="0"
          control={<BpRadio />}
          label="Other"
          sx={{ alignItems: "end" }}
        />
      </RadioGroup>
    </Box>
  );
};

const Donate = (props) => {
  const {
    setContainer,
    token,
    setToken,
    amount = 0,
    setAmount,
    setAmountUSD,
    notify,
    political,
    acceptUSDConEth,
  } = props;

  const mdSize = useMediaQuery<any>((theme) => theme.breakpoints.down("md"));
  const smSize = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));

  const [tab, setTab] = useState("crypto");
  const [dollar, setDollar] = useState(amount);
  const [price, setPrice] = useState(1);

  useEffect(() => {
    console.log("changed", price, amount);
    const donation = price !== 0 ? dollar / price : 0;
    setAmount(donation);
  }, [price]);

  const getPrice = async (tokenName) => {
    const result = await getETCPrice(tokenName, "USD");
    const price = result[tokenName].usd;
    setPrice(price);
  };

  const switchTab = (tab) => {
    if (tab === "crypto") {
      setContainer({ marginTop: 135, disablePay: false });
    } else {
      setContainer({ marginTop: 135, disablePay: true });
    }
    setTab(tab);
  };

  const handleChangeCoin = (e) => {
    const token = e.target.value;
    setToken(token);
    switch (token) {
      case "NPAY":
        const price = 1;
        setPrice(price);
        break;
      case "ETH":
        getPrice("ethereum");
        break;
      case "USDC":
        setPrice(1);
        break;
      case "USDC_PG":
        setPrice(1);
        break;
      case "USDC_ZK":
        setPrice(1);
        break;
      default:
    }
  };

  const ensureMaximumLimit = (usd) => {
    if (political && usd > MAX_DONATION) {
      notify(`Maximum allowed donation amount is $23,200`, "error");
      return false;
    }

    return true;
  };

  const handleDollar = (e) => {
    const usd = e.target.value;
    if (ensureMaximumLimit(usd)) {
      setDollar(usd);
      setAmountUSD(usd);
      if (price != 0) {
        const donation = usd / price;
        setAmount(donation);
      }
    }
  };

  const handleCoin = (e) => {
    const donation = e.target.value;
    if (ensureMaximumLimit(donation * price)) {
      setAmount(donation);
      setDollar(donation * price);
      setAmountUSD(donation * price);
    }
  };

  const currencies = [
    {
      value: "USDC_PG",
      label: <img src="/assets/usdc_coin.svg" />,
      text: "on Polygon",
      prompt: "Cheap but slow. We recommend zkSync Era.",
    },
    {
      value: "USDC_ZK",
      label: <img src="/assets/usdc_coin.svg" />,
      text: "on zkSync Era",
      prompt: "Use zkSync Era for faster and cheaper transactions!",
    },
    // {
    //   value: "NPAY",
    //   label: <img src="/assets/npay.svg" />,
    //   text: "on ETH",
    // },
    // {
    //   value: "ETH",
    //   label: <img src="/assets/eth.svg" />,
    //   text: "ETH",
    // },
  ];

  if (acceptUSDConEth === 'Y') {
    currencies.unshift({
      value: "USDC",
      label: <img src="/assets/usdc_coin.svg" />,
      text: "on Ethereum",
      prompt: "Slow and expensive. We recommend zkSync Era.",
    });
  }

  return (
    <Grid container xs={12} direction="column" alignItems="center">
      {false && (
        <Grid
          container
          xs={12}
          sx={{ display: "flex", flexDirection: "row", gap: "22px" }}
        >
          <Grid
            xs={6}
            onClick={() => switchTab("crypto")}
            sx={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid",
              borderRadius: "12px",
              borderColor: "#F9F5FF",
              backgroundColor: tab === "crypto" ? "#7129F5" : "#F9F5FF",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px 4px",
              gap: "4px",
              height: 56,
              "&:hover": {
                borderColor: "#7129F5",
                borderRadius: "12px",
              },
              "&.focused": {
                borderColor: "#7129F5",
                borderRadius: "12px",
              },
            }}
          >
            <Typography sx={{ color: tab === "crypto" ? "white" : "black" }}>
              Crypto
            </Typography>
          </Grid>
          <Grid
            xs={6}
            onClick={() => switchTab("cash")}
            sx={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid",
              borderRadius: "12px",
              borderColor: "#F9F5FF",
              backgroundColor: tab === "cash" ? "#7129F5" : "#F9F5FF",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px 4px",
              gap: "4px",
              height: 56,
              "&:hover": {
                borderColor: "#7129F5",
                borderRadius: "12px",
              },
              "&.focused": {
                borderColor: "#7129F5",
                borderRadius: "12px",
              },
            }}
          >
            <Typography sx={{ color: tab === "cash" ? "white" : "black" }}>
              Cash <i>(Coming Soon)</i>
            </Typography>
          </Grid>
        </Grid>
      )}
      {tab === "crypto" && (
        <>
          <TextField
            select
            size="small"
            margin="dense"
            fullWidth
            defaultValue={token}
            onChange={handleChangeCoin}
            sx={{
              backgroundColor: "#F9F5FF",

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#F9F5FF",
                  borderRadius: "12px",
                },
                "&:hover fieldset": {
                  borderColor: "#F9F5FF",
                  borderRadius: "12px",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#7129F5",
                  borderRadius: "12px",
                },
              },
            }}
          >
            {currencies.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ width: "100%" }}
              >
                <Box sx={{ width: "100%" }}>
                  <Grid container xs={12}>
                    <Grid
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography component="span">{option.label}</Typography>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-end"
                    >
                      <Grid>{option.text}</Grid>
                    </Grid>
                    <Grid xs={12}>
                      <Typography
                        component="span"
                        sx={{
                          ml: smSize ? 0 : "38px",
                          overflowWrap: "break-word",
                          fontStyle: "italic",
                          fontSize: smSize ? "10px" : mdSize ? "12px" : "13px",
                        }}
                      >
                        {option.prompt}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </MenuItem>
            ))}
          </TextField>
          <Typography
            variant="body1"
            component="span"
            sx={{ marginTop: 3, marginLeft: "auto", marginRight: "auto" }}
          >
            {token === "USDC" && `Need to buy USDC on Ethereum?`}
            {token === "USDC_ZK" && `Need to buy USDC on zkSync Era?`}
            {token === "USDC_PG" && `Need to buy USDC on Polygon (MATIC)?`}
            &nbsp;
            {token === "USDC" && (
              <a
                href="https://ndau.io/knowledge-base/how-do-i-buy-usdc-on-ethereum/"
                target="_blank"
              >
                Learn how here
              </a>
            )}
            {token === "USDC_ZK" && (
              <a
                href="https://ndau.io/knowledge-base/how-do-i-buy-usdc-on-zksync-era/"
                target="_blank"
              >
                Learn how here
              </a>
            )}
            {token === "USDC_PG" && (
              <a
                href="https://ndau.io/knowledge-base/how-do-i-buy-usdc-on-polygon/"
                target="_blank"
              >
                Learn how here
              </a>
            )}
          </Typography>
          {political && <LimitSelection onChange={handleDollar} />}
          <Typography variant="body1" component="span" align="center">
            Input amount in Dollars
          </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            margin="dense"
            size="small"
            required
            fullWidth
            type="text"
            value={dollar}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ paddingTop: "6px" }} position="start">
                  $
                </InputAdornment>
              ),
            }}
            onChange={handleDollar}
          />
          <Typography variant="body1" component="span" align="center">
            Input amount in {token}
          </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            margin="dense"
            size="small"
            required
            fullWidth
            type="text"
            value={amount}
            onChange={handleCoin}
          />
        </>
      )}
      {!political && tab === "cash" && <Cash />}
    </Grid>
  );
};

export default Donate;
