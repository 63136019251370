import { useState } from "react";
import { Box, Typography, Button, LinearProgress, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import { StyledButton, BackButton } from "../../components/Elements";

import { updateTransaction } from "../../api/api";

const Thanks = (props) => {
  const { contract_address, receipt, beneficiary, share, shareAndEarn, token } =
    props;
  const { transactionHash } = receipt;
  console.log("receipt:", receipt);

  const [patronMessage, setPatronMessage] = useState("");
  const [patronEmail, setPatronEmail] = useState("");
  const [waitingStep, setWaitingStep] = useState("NONE");
  const [sent, setSent] = useState(false);

  const WAITING_MSG = {
    NONE: false,
    STEP_SENDING_MESSAGE: "Sending message...",
  };

  const sendPatronMessage = async () => {
    setWaitingStep("STEP_SENDING_MESSAGE");
    await updateTransaction(
      contract_address,
      transactionHash,
      patronMessage,
      patronEmail
    );

    setWaitingStep("NONE");
    setSent(true);
  };
  let href;
  switch (token) {
    case "NPAY":
    case "USDC":
      href = `https://etherscan.io/tx/${transactionHash}`;
      break;
    case "NPAY_PG":
    case "USDC_PG":
      href = `https://polygonscan.com/tx/${transactionHash}`;
      break;
    default:
      href = `https://explorer.zksync.io/tx/${transactionHash}`;
  }

  return (
    <Grid container xs={12}>
      <Grid xs={12}>
        <Typography
          sx={{
            fontStyle: "italic",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          Here's a record of your donation on the blockchain{" "}
          <Link href={href} target="_blank" rel="noreferrer">
            {transactionHash}
          </Link>
        </Typography>
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "34px",
          gap: "28px",
          isolation: "isolate",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <img
          src="/assets/check_mark.gif"
          style={{ width: "180px", height: "180px" }}
        ></img>
        {WAITING_MSG[waitingStep] && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 3,
              border: "1px solid #7129F5",
            }}
          >
            <Typography>{WAITING_MSG[waitingStep]}</Typography>
            <LinearProgress
              sx={{ width: "100%", backgroundColor: "#7129F5" }}
            />
          </Box>
        )}
        {sent && (
          <Typography
            variant="body1"
            component="span"
            align="center"
            fullWidth
            sx={{ fontWeight: 400, lineHeight: "22px" }}
          >
            Your message was sent to the Beneficiary ({beneficiary.name})
          </Typography>
        )}
        {!sent && (
          <Grid xs={12}>
            <Typography
              variant="body1"
              component="span"
              sx={{ fontWeight: 400, lineHeight: "22px" }}
            >
              Send a message to the Beneficiary ({beneficiary.name})
            </Typography>
            <TextField
              variant="outlined"
              component="span"
              margin="dense"
              size="small"
              sx={{
                width: "100%",
                borderRadius: "8px",
                borderColor: "#8F9CA3",
              }}
              required
              fullWidth
              onChange={(e) => setPatronMessage(e.target.value)}
            />
          </Grid>
        )}
        {!sent && (
          <Grid xs={12}>
            <Typography
              variant="body1"
              component="span"
              sx={{ fontWeight: 400, lineHeight: "22px" }}
            >
              Stay Up-to Date with Beneficiary ({beneficiary.name})
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              size="small"
              required
              fullWidth
              onChange={(e) => setPatronEmail(e.target.value)}
              placeholder="Enter your Email"
              sx={{
                borderRadius: "8px",
                borderColor: "#8F9CA3",
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }}>
        <StyledButton
          component="span"
          sx={{
            borderRadius: "4px",
            height: "52px",
            // backgroundColor: "#F9F5FF",
            // color: "#7129F5",
            // "&:hover": {
            //   color: "#FFFFFF",
            // },
          }}
          fullWidth
          onClick={sendPatronMessage}
        >
          Send
        </StyledButton>
        <Button
          fullWidth
          onClick={shareAndEarn}
          // sx={{ borderRadius: "12px", height: "56px", marginTop: "10px" }}
          sx={{
            borderRadius: "4px",
            height: "52px",
            marginTop: "12px",
            "&:hover": {
              backgroundColor: "#FFC700",
            },
          }}
        >
          Share & Earn
        </Button>
        <StyledButton
          fullWidth
          onClick={share}
          sx={{
            borderRadius: "4px",
            height: "52px",
            marginTop: "12px",
            // backgroundColor: "#FFFFFF",
            // color: "#7129F5",
            //"&:hover": {
            //  color: "#FFFFFF",
            //},
          }}
        >
          Share
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default Thanks;
