import Grid from "@mui/material/Unstable_Grid2";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import { Typography, Button, Box } from "@mui/material";
import { StyledButton, BackButton } from "./Elements";
import Footer from "./Footer";
import Disclaimer from "./Disclaimer";
import useMediaQuery from "@mui/material/useMediaQuery";

const Container = (props) => {
  const {
    back,
    share,
    subTitle,
    primaryTitle,
    secondaryTitle,
    paper,
    children,
    political,
  } = props;

  const smSize = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const maxWidth = paper?.width ? paper.width : 574;
  const marginTop = smSize ? 0 : paper?.marginTop ? paper.marginTop : 30;
  const paddingTop = paper?.paddingTop ? paper.paddingTop : 48;
  const paddingBottom = paper?.paddingBottom ? paper.paddingBottom : 101;

  console.log("maxWidth:", maxWidth);
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="stretch"
        sx={{ width: "100%" }}
      >
        <Grid xs={0} sm={1} md={2} lg={3} />
        <Grid xs={12} sm={10} md={8} lg={6}>
          <Paper
            elevation={7}
            align="left"
            sx={{
              // maxWidth,
              // position: "relative",
              paddingLeft: "34px !important",
              paddingRight: "34px !important",
              paddingTop: `${paddingTop}px !important`,
              paddingBottom: `${paddingBottom}px !important`,
              marginTop: `${marginTop}px !important`,
              borderRadius: "12px",
            }}
          >
            <Grid
              container
              direction="row"
              spacing={2}
              // justifyContent="center"
              // alignItems="stretch"
              sx={{ width: "100%", height: "100%" }}
            >
              {typeof back === "function" && (
                <BackButton
                  aria-label="back"
                  onClick={back}
                  sx={{
                    position: "relative",
                    marginTop: "-36px",
                    marginLeft: "-28px",
                    fontSize: 18,
                  }}
                >
                  <ArrowLeftIcon />
                  <span style={{ fontSize: 12 }}>Back</span>
                </BackButton>
              )}
              <Grid xs={12}>
                {primaryTitle && (
                  <Typography
                    variant="h1"
                    color="primary"
                    component="h1"
                    sx={{ display: "inline-block" }}
                  >
                    {primaryTitle}
                  </Typography>
                )}
                {secondaryTitle && (
                  <Typography
                    variant="h1"
                    color="secondary"
                    component="h1"
                    sx={{
                      display: "inline-block",
                      paddingLeft: primaryTitle ? 2 : 0,
                      fontWeight: 700,
                    }}
                  >
                    {secondaryTitle}
                  </Typography>
                )}

                {typeof share === "function" && (
                  <StyledButton
                    variant="text"
                    sx={{
                      // backgroundColor: "#FFFFFF",
                      // color: "#7129F5",
                      // "&:hover": {
                      //   color: "#FFFFFF",
                      // },
                      float: "right",
                      top: 18,
                      fontSize: 18,
                    }}
                    startIcon={<ShareIcon />}
                    onClick={share}
                  >
                    Share
                  </StyledButton>
                )}
              </Grid>
              <Grid xs={12}>
                {typeof subTitle === "function" ? (
                  subTitle()
                ) : (
                  <Typography variant="body1" component="span">
                    {subTitle}
                  </Typography>
                )}
              </Grid>
              <Grid container xs={12}>
                {children}
              </Grid>
              <Grid container xs={12} sx={{ mt: "16px" }}>
                {political ? <Disclaimer /> : <Footer />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={0} sm={1} md={2} lg={3} />
      </Grid>
    </>
  );
};

export default Container;
