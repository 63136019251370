import { useState, useEffect, useMemo, useContext, createContext } from "react";
import { RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSnapshot } from "valtio";

import theme from "./theme";
import router from "./router";
import store from "./store/appStore";
import wcStore from "./features/WalletConnect/store";
import mmStore from "./features/MetaMask/store";
import { getEnvConfig } from "api/api";
import "./App.css";
import { beneficiaryThemes, customizedTheme } from "./theme";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  const [retry, setRetry] = useState(false);
  const snap = useSnapshot(store);
  const beneficiary = snap.beneficiary;

  const customized =
    customizedTheme[beneficiaryThemes[beneficiary.toLowerCase()]];
  // console.log("..........overrides", customized);
  const overrides = useMemo(
    () => createTheme(theme, customized),
    [beneficiary]
  );

  useEffect(() => {
    const setupEnvironment = async () => {
      const env = await getEnvConfig();
      if (!env) {
        // setTimeout(() => setRetry(!retry), 10000);
      } else {
        const {
          l1ChainId,
          l2ChainId,
          pgChainId,
          l1Rpc,
          l2Rpc,
          pgRpc,
          baseUrl,
          socialUrl,
          FACILITATOR_ADDRESS,
          pkMoonPay,
        } = env;
        wcStore.l1ChainId = l1ChainId;
        wcStore.l2ChainId = l2ChainId;
        wcStore.pgChainId = pgChainId;
        wcStore.l1Rpc = l1Rpc;
        wcStore.l2Rpc = l2Rpc;
        wcStore.pgRpc = pgRpc;

        mmStore.l1ChainId = l1ChainId;
        mmStore.l2ChainId = l2ChainId;
        mmStore.pgChainId = pgChainId;
        mmStore.l1Rpc = l1Rpc;
        mmStore.l2Rpc = l2Rpc;
        mmStore.pgRpc = pgRpc;

        store.baseUrl = baseUrl;
        store.socialUrl = socialUrl;
        store.FACILITATOR_ADDRESS = FACILITATOR_ADDRESS;
        store.pkMoonPay = pkMoonPay;
      }
    };

    setupEnvironment();
  }, [retry]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={overrides}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RouterProvider router={router}></RouterProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
