import axios from "axios";
export const HTTP_REQUEST_HEADER = {
  method: "GET",
  mode: "no-cors",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

type methodType = "get" | "post" | "patch" | "delete";
console.log(
  "process.env.REACT_APP_SERVICE_ENDPOINT ",
  process.env.REACT_APP_SERVICE_ENDPOINT
);
const baseURL = process.env.REACT_APP_SERVICE_ENDPOINT;
const apiURL = `${baseURL || window.location.origin}/api`;

export const fetch_axios = async (
  method: methodType,
  url: string | { base: string; path: string; contentType: string },
  body?: any,
  params?: any,
  config?: any
) => {
  const { base, path, contentType } =
    typeof url === "object"
      ? url
      : { base: "", path: "", contentType: "application/json; charset=utf-8" };

  const response =
    typeof url === "object"
      ? await axios({
          method,
          url: !base
            ? baseURL
              ? `${baseURL}/${path}`
              : `${path}`
            : `${base}/${path}`,
          data: body,
          // timeout: 3 * 60 * 1000,
          params,
          headers: {
            "Content-Type": contentType,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          },
        })
      : await axios({
          method,
          url: `${apiURL}/${url}`,
          data: body,
          // timeout: 3 * 60 * 1000,
          params,
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          },
        });

  if (response && response.status === 200) {
    return response.data;
  }

  return response;
};

export const getEnvConfig = async () => {
  try {
    const res = await fetch_axios("get", "env");
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const createContract = async (
  campaign_name: string,
  creator_address: string,
  creator_name: string,
  description: string,
  campaign_url: string,
  creator_fee: number,
  affiliate_fee: number,
  goal: number,
  expiration: number,
  beneficiaries: Array<{ address: string; name: string; percentage: number }>,
  political,
  npayment?: string
) => {
  return await fetch_axios(
    "post",
    "contract",
    {
      campaign_name,
      creator_address,
      creator_name,
      description,
      campaign_url,
      creator_fee,
      affiliate_fee,
      goal,
      expiration,
      beneficiaries,
      political,
      npayment,
    },
    undefined,
    {
      timeout: 0, // default is `0` (no timeout)
    }
  );
};

export const paynow = async (
  payer_address: string,
  payee_address: string,
  amount: number,
  token: string,
  method: string
) => {
  return await fetch_axios("post", "paynow", {
    payer_address,
    payee_address,
    amount,
    token,
    method,
  });
};

export const promote = async (
  contract_address: string,
  affiliate_address: string,
  affiliate_name: string,
  promote_message: string,
  tx_hash: string,
  signature: string,
  EIP712Msg: any
) => {
  return await fetch_axios("post", "promote", {
    contract_address,
    affiliate_address,
    affiliate_name,
    promote_message,
    tx_hash,
    signature,
    EIP712Msg,
  });
};

export const donate = async (
  patron_address: string,
  contract_address: string,
  affiliate_address: string,
  token: string,
  amount: number,
  payment: any
) => {
  return await fetch_axios("post", "donate", {
    patron_address,
    contract_address,
    affiliate_address,
    token,
    amount,
    payment,
  });
};

export const deposit = async (
  patron_address: string,
  contract_address: string,
  affiliate_address: string,
  token: string,
  amount: number,
  tx_hash: string
) => {
  return await fetch_axios("post", "deposit", {
    patron_address,
    contract_address,
    affiliate_address,
    token,
    amount,
    tx_hash,
  });
};

export const addTransaction = async (
  transaction_hash: string,
  owner_address: string,
  contract_address: string,
  affiliate_address: string,
  amount: number,
  message: string
) => {
  return await fetch_axios("post", "transact", {
    transaction_hash,
    owner_address,
    contract_address,
    affiliate_address,
    amount,
    message,
  });
};

export const updateTransaction = async (
  contract_address: string,
  transaction_hash: string,
  short_message: string,
  patron_email: string
) => {
  return await fetch_axios("post", "message", {
    contract_address,
    transaction_hash,
    short_message,
    patron_email,
  });
};

export const settle = async (
  sender_address: string,
  contract_address: string
) => {
  return await fetch_axios("post", "settle", {
    sender_address,
    contract_address,
  });
};

export const pullCampaign = async (contract_address: string) => {
  return await fetch_axios(
    "get",
    "campaign",
    {},
    {
      contract_address,
    }
  );
};

export const pullAffiliate = async (
  contract_address: string,
  affiliate_address: string
) => {
  return await fetch_axios(
    "get",
    "affiliate",
    {},
    {
      contract_address,
      affiliate_address,
    }
  );
};

export const pullBeneficiary = async (contract_address: string, id: number) => {
  return await fetch_axios(
    "get",
    "beneficiary",
    {},
    {
      contract_address,
      id,
    }
  );
};

export const getAllContracts = async () => {
  return await fetch_axios("get", "contracts");
};

export const getRaisedFunds = async (contract_address: string) => {
  return await fetch_axios(
    "get",
    "funds",
    {},
    {
      contract_address,
    }
  );
};

export const getURLSignature = async (url: string) => {
  console.log("......url", url);
  return await fetch_axios("post", "payment/signature", {
    url,
    processor: "MoonPay",
  });
};

export const payWithCreditCard = async (
  contract_address: string,
  affiliate_address: string,
  token: string,
  amount: number,
  currency_code: string,
  base_currency_code: string,
  browser: string,
  processor: string = "MoonPay"
) => {
  return await fetch_axios("post", "payment/prepare", {
    contract_address,
    affiliate_address,
    token,
    amount,
    currency_code,
    base_currency_code,
    browser,
    processor,
  });
};

export const upload = async (data) => {
  return await fetch_axios(
    "post",
    { base: "", path: "upload", contentType: "multipart/form-data" },
    data
  );
};

export const getETCPrice = async (coin: string, currency: string) => {
  // ethereum-class, matic-aave-usdc
  return await fetch_axios("get", {
    base: "https://api.coingecko.com/",
    path: `api/v3/simple/price?ids=${coin}&vs_currencies=${currency}`,
    contentType: "application/json; charset=utf-8",
  });
};

export { baseURL };
