import { Typography, Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const Footer = (props) => {
  return (
    <Grid
      container
      xs={12}
      direction="row"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Grid
        xs={12}
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", display: "flex" }}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{ display: "inline-block" }}
        >
          Powered by
        </Typography>
        <Link href="https://npayme.com/" target="_blank" rel="noreferrer">
          <img
            src="/assets/Logo.svg"
            height="23"
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              marginTop: 8,
            }}
          />
        </Link>
        {/* <img
          src="/assets/ndau.svg"
          width="52"
          height="23"
          style={{
            paddingLeft: 5,
            paddingRight: 5,
          }}
        /> */}
        <Typography
          variant="h4"
          component="h4"
          sx={{ display: "inline-block" }}
        >
          All rights reserved.
        </Typography>
      </Grid>
      <Grid
        xs={12}
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", display: "flex" }}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{ display: "inline-block" }}
        >
          [
          <a href="https://npayme.com" target="_blank">
            Learn more about npayme
          </a>
          ]
        </Typography>
        &nbsp;
        <Typography
          variant="h4"
          component="h4"
          sx={{ display: "inline-block" }}
        >
          [
          <a href="https://bpc-dao.org/npayme-support/" target="_blank">
            Contact npayme support
          </a>
          ]
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
