import { proxy } from "valtio";

export const isMetaMaskInstalled = () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

const state = proxy<{
  metamask: boolean;
  l1ChainId: string;
  l2ChainId: string;
  pgChainId: string;
  l1Rpc: string;
  l2Rpc: string;
  pgRpc: string;
  open: boolean;
  session: any;
  args: any;
  authorization: Array<any>;
  selected: string;
  version: number;
  uri: string;
}>({
  metamask: isMetaMaskInstalled(),
  l1ChainId: "eip155:1",
  l2ChainId: "eip155:324",
  pgChainId: 'eip155:80001',
  l1Rpc: '',
  l2Rpc: '',
  pgRpc: '',
  open: false,
  session: null,
  args: null,
  authorization: [],
  selected: "",
  version: 0,
  uri: "",
});

export default state;
