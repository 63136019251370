import { paynow, donate, deposit, addTransaction } from "../api/api";
import store from "../features/MetaMask/store";
import appStore from "../store/appStore";
import { getError } from "./index";

export const NETWORK = {
  zkSyncEra: "zkSync Era",
  ethereum: "ethereum",
  polygon: "polygon",
};

export const WALLET = {
  coinbase: "coinbase",
  metamask: "metamask",
};

export const TOKEN = {
  NPAY: "NPAY",
  USDC: "USDC",
  NPAY_ZK: "NPAY_ZK",
  USDC_ZK: "USDC_ZK",
  NPAY_PG: "NPAY_PG",
  USDC_PG: "USDC_PG",
};

export const METHOD = {
  settle: "settle",
};

export const zkSyncEra = "zkSync Era";
export const ethereum = "ethereum";
export const polygon = "polygon";
export const mobile = "mobile";

export const OPERATOR_ADDRESS = "0xdf6689e760aecb943c92271bc7dde5ef0648db17";

export const getFacilitator = () => appStore.FACILITATOR_ADDRESS;

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const getL1ChainId = () => {
  const arr = store.l1ChainId.split(":");
  return parseInt(arr[1]);
};

export const getL2ChainId = () => {
  const arr = store.l2ChainId.split(":");
  return parseInt(arr[1]);
};

export const getPGChainId = () => {
  const arr = store.pgChainId.split(":");
  return parseInt(arr[1]);
};

export const sendTransaction = async (Pay, transaction, browser) => {
  const { to, EIP712Msg, flag } = transaction;

  if (to || EIP712Msg) {
    if (flag === "skip") {
      return {
        success: true,
        skip: true,
      };
    }

    return await Pay(transaction, browser).then((res) => {
      console.log("send transaction result: ", res);
      // alert(`Debug 1: ${res.message}`)
      if (res.success === false) {
        return res;
      }

      if (res.signature) {
        const { signature, EIP712Msg } = res;
        const { customData, ...rest } = EIP712Msg;
        // return {
        //   from,
        //   to,
        //   value,
        //   EIP712Msg: {
        //     ...rest,
        //     customData: { ...customData, customSignature: signature },
        //   },
        // };
        return {
          success: true,
          uuid: res.uuid,
          EIP712Msg,
          signature,
          // EIP712Msg: {
          //   ...rest,
          //   customData: { ...customData, customSignature: signature },
          // },
        };
      }

      return {
        success: true,
        uuid: res.uuid,
        tx_hash: res.transaction_hash || res,
      };
    });
  }
};

export const sendDeposit = async (
  Pay,
  from,
  contract_address,
  affiliate_address,
  token,
  amount,
  tx_hash
) => {
  console.log("sendDeposit......");
  const result = await deposit(
    from,
    contract_address,
    affiliate_address,
    token,
    amount,
    tx_hash
  );
  console.log("result", result);

  return result;
};

export const donateDeposit = async () => {
  console.log("donateDeposit");
};

export const sendDonation = async (
  from,
  contract_address,
  affiliate_address,
  token,
  amount,
  payment
) => {
  const result = await donate(
    from,
    contract_address,
    affiliate_address,
    token,
    amount,
    payment
  );

  return result;
};

export const commitPayment = async (
  Pay,
  from,
  to,
  amount,
  token,
  method,
  setProgress,
  browser
) => {
  console.log(
    `Got paynow ${method} request from ${from} to ${to} for ${amount} ${token}`
  );
  let stepNo;
  let result;
  try {
    result = await paynow(from, to, amount, token, method);
  } catch (e) {
    if (typeof setProgress === "function") {
      setProgress("NONE");
    }
    return {
      success: false,
      message:
        getError(e) || `Failed to initialize a ${method} ${token} request`,
    };
  }

  if (result && result.success) {
    if (result.transaction?.flag == "skip") {
      return {
        success: true,
        ...result.transaction
      };
    }

    console.log(`Get raw transaction, calling sendTransaction... `, result);

    if (typeof setProgress === "function") {
      stepNo = setProgress();
    }

    result = sendTransaction(Pay, { from, ...result.transaction }, browser);
    if (result && result.success === false) {
      if (typeof setProgress === "function") {
        stepNo = setProgress();
      }
    }
  }

  return result;
};
