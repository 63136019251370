import Button, { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { alpha, styled } from "@mui/material/styles";

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.contrastText,
  color: theme.palette.primary.main,
  "&:hover": {
    color: "#FFFFFF",
  },
}));

export const BackButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.contrastText,
  color: theme.palette.secondary.main,
  // "&:hover": {
  //   color: "#FFFFFF",
  // },
}));