import { useSnapshot } from "valtio";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import Grid from "@mui/material/Unstable_Grid2";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  TwitterShareButton,
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";
// import { ReactComponent as CopyIcon } from '../../../public/assets/copy.svg';

import store from "../../store/appStore";

const Share = (props) => {
  const { affiliateAddress, contractAddress, notify } = props;
  const state = useSnapshot(store);
  const socialUrl = `${state.socialUrl}/widget?contract_address=${contractAddress}&affiliate_address=${affiliateAddress}`;
  const shareUrl = `${state.baseUrl}/patron/${contractAddress}/${affiliateAddress}`;

  const onClick = async () => {
    notify("Copied to clipboard");
  };

  return (
    <Grid container xs={12} direction="column" rowSpacing={-6}>
      <Grid xs={12} sx={{ display: "flex" }}>
        <img
          src="/assets/share-one.svg"
          style={{ width: "20px", height: "20px", marginRight: "8px" }}
        />
        <Typography
          variant="h6"
          component="span"
          sx={{ color: "#5C6970", lineHeight: "20px" }}
        >
          Share via
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        columns={27}
        sx={{ height: 100 }}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid
          xs={13}
          justifyContent="center"
          sx={{
            display: "flex",
            border: "1px solid #C7CED1",
            borderRadius: "8px",
            padding: "0px 4px",
            "&:hover": {
              backgroundColor: "#F9F5FF",
            },
          }}
        >
          <TwitterShareButton
            url={`${socialUrl}&platform=twitter`}
            quote={"Promote"}
            hashtag="#npayme"
            style={{ padding: "6px 18px" }}
          >
            <img
              style={{
                width: "44px",
                height: "44px",
                marginTop: "11px",
              }}
              src="/assets/twitter.svg"
              alt="Twitter"
            />
          </TwitterShareButton>
        </Grid>
        <Grid
          xs={13}
          justifyContent="center"
          sx={{
            display: "flex",
            border: "1px solid #C7CED1",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#F9F5FF",
            },
          }}
        >
          <FacebookShareButton
            url={`${socialUrl}&platform=facebook`}
            quote={"Promote"}
            hashtag="#npayme"
            style={{ padding: "6px 18px" }}
          >
            <img
              style={{ width: "44px", height: "44px", marginTop: "11px" }}
              src="/assets/facebook.svg"
              alt="Facebook"
            />
          </FacebookShareButton>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        columns={27}
        sx={{ height: 100 }}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid
          xs={13}
          justifyContent="center"
          sx={{
            display: "flex",
            border: "1px solid #C7CED1",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#F9F5FF",
            },
          }}
        >
          <TelegramShareButton
            url={`${socialUrl}&platform=telegram`}
            quote={"Promote"}
            hashtag="#npayme"
            style={{ padding: "6px 18px" }}
          >
            <img
              style={{ width: "44px", height: "44px", marginTop: "11px" }}
              src="/assets/telegram.svg"
              alt="Telegram"
            />
          </TelegramShareButton>
        </Grid>
        <Grid
          xs={13}
          justifyContent="center"
          sx={{
            display: "flex",
            border: "1px solid #C7CED1",
            borderRadius: "8px",
          }}
        >
          <Button variant="text" sx={{ backgroundColor: "#FFFFFF" }} disabled>
            <img
              style={{ width: "44px", height: "44px" }}
              src="/assets/snapchat.svg"
              alt="Snapchat"
            />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        columns={27}
        sx={{ height: 100 }}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid
          xs={13}
          justifyContent="center"
          sx={{
            display: "flex",
            border: "1px solid #C7CED1",
            borderRadius: "8px",
          }}
        >
          <Button variant="text" sx={{ backgroundColor: "#FFFFFF" }} disabled>
            <img
              style={{ width: "44px", height: "44px" }}
              src="/assets/tiktok.svg"
              alt="TikTok"
            />
          </Button>
        </Grid>
        <Grid
          xs={13}
          justifyContent="center"
          sx={{
            display: "flex",
            border: "1px solid #C7CED1",
            borderRadius: "8px",
          }}
        >
          <Button variant="text" sx={{ backgroundColor: "#FFFFFF" }} disabled>
            <img
              style={{ width: "44px", height: "44px" }}
              src="/assets/instagram.svg"
              alt="Instagram"
            />
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" columns={27} justifyContent="center">
        <CopyToClipboard text={shareUrl}>
          <TextField
            id="input-with-icon-textfield"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            InputProps={{
              endAdornment: (
                // <InputAdornment position="start">
                //   <SvgIcon component={CopyIcon} inheritViewBox />
                // </InputAdornment>
                <img src="/assets/copy.svg" alt="copy" onClick={onClick}></img>
              ),
            }}
            readOnly
            value={shareUrl}
          />
        </CopyToClipboard>
      </Grid>
    </Grid>
  );
};

export default Share;
