import { useState } from "react";
import { Typography, TextField, Button, Box, Modal } from "@mui/material";
import web3 from "web3";

import {
  ensurChainId,
  onMetaMaskConnect,
  request as metamaskPay,
} from "../MetaMask/hook/useMetaMask";
import { getL2ChainId } from "../../utils/blockchain";
import { paynow } from "../../api/api";

const currentPayMaster = "0x5E5dD07a8e31006196AfeD1BA7b4379f91574918";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Paymaster(props) {
  const { open, handleClose } = props;
  const [paymaster, setPaymaster] = useState(currentPayMaster);
  const [ethBalance, setEthBalance] = useState<string | number>(
    web3.utils.hexToNumber("0x00")
  );
  const [npayBalance, setNPAYBalance] = useState<string | number>(
    web3.utils.hexToNumber("0x00")
  );
  const [usdcBalance, setUSDCBalance] = useState<string | number>(
    web3.utils.hexToNumber("0x00")
  );
  const [isOwner, setIsOwner] = useState(false);

  const view = async (from, to) => {
    let result;
    const method = "withdraw_paymaster";

    if (true === (await ensurChainId(getL2ChainId()))) {
      console.log(`withdraw from paymaster on zkSync......`);

      result = await paynow(from, to, 0, "NPAY", method);
      if (result && result.success) {
        console.log(result);
      }
    } else {
      result = { success: false, message: "Failed to view fund" };
      console.log(".......asdfasdf......");
      alert(result.message);
    }

    return result;
  };

  const withdraw = async (from, to) => {
    let result;
    if (true === (await ensurChainId(getL2ChainId()))) {
      console.log(`withdraw from paymaster on zkSync......`);

      result = await view(from, to);

      console.log("result.......", result);
      if (result === true || result?.success === true) {
        result = await metamaskPay(result.transaction.transaction.eth);
        if (result && result.success) {
          console.log(result);
        }
      }
    } else {
      result = { success: false, message: "User cancelled" };
    }

    return result;
  };

  const metamask = async (action: string) => {
    console.log("connect metamask");
    const from = await onMetaMaskConnect();
    if (from) {
      console.log("wallet.......", from);
      let result;
      if (action === "view") {
        result = await view(from, paymaster);

        console.log("result.......", result);
        if (result === true || result?.success === true) {
          setEthBalance(
            web3.utils.fromWei(result.transaction.ethBalance.hex, "ether")
          );
          setNPAYBalance(
            web3.utils.fromWei(result.transaction.npayBalance.hex, "ether")
          );
          setUSDCBalance(
            web3.utils.fromWei(result.transaction.usdcBalance.hex, "mwei")
          );
          setIsOwner(result.transaction.transaction?.eth);
        } else {
          alert(result?.message || result);
        }
      } else if (action === "withdraw") {
        return await withdraw(from, paymaster);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Paymaster
        </Typography>
        <Typography>
          ETH (zkSync Era) Balance: <strong>{ethBalance}</strong>{" "}
        </Typography>
        <Typography>
          NPAY (zkSync Era) Balance: <strong>{npayBalance}</strong>{" "}
        </Typography>
        <Typography>
          USDC (zkSync Era) Balance: <strong>{usdcBalance}</strong>{" "}
        </Typography>
        <Typography>Enter Paymaster Wallet Address</Typography>
        <TextField
          variant="outlined"
          margin="dense"
          size="small"
          required
          fullWidth
          value={paymaster}
          onChange={(event) => setPaymaster(event.target.value)}
        />
        <Box sx={{ display: "flex", paddingTop: "16px" }}>
          <Button
            sx={{ flex: 1, marginRight: "16px" }}
            variant="outlined"
            onClick={() => metamask("view")}
          >
            View Funds
          </Button>
          <Button
            variant="outlined"
            onClick={() => metamask("withdraw")}
            disabled={!isOwner}
          >
            Withdraw All Funds
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
