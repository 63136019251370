import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  Typography,
  TextField,
  Checkbox,
  Backdrop,
  Fade,
} from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import Disclaimer from "../../components/Disclaimer";

interface Info {
  first_name: string;
  last_name: string;
  telephone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  email: string;
  employer: string;
  occupation: string;
  spouse_employer: string;
  spouse_occupation: string;
  home: string;
  office: string;
  cell: string;
}

export default function Compliance(props) {
  const { wallet, amount, open, setOpen, submit, compliance } = props;
  const initialInfo: Info = compliance
    ? JSON.parse(decodeURIComponent(escape(atob(compliance))))
    : {
        first_name: "",
        last_name: "",
        telephone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        email: "",
        employer: "",
        occupation: "",
        spouse_employer: "",
        spouse_occupation: "",
        home: "",
        office: "",
        cell: "",
      };
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState<Info>(initialInfo);

  const handleClose = () => {
    setOpen(false);
  };

  const next = () => {
    setStep(step + 1);
  };

  const back = () => step > 1 && setStep(step - 1);

  const updateInfo = (property: string, value: string) => {
    setInfo((prev) => ({
      ...prev,
      [property]: value,
    }));
  };

  const handleSubmit = () => {
    submit(wallet, info);
  };

  return (
    <Grid
      container
      xs={12}
      // open={open}
      // onClose={handleClose}
      // fullWidth
      // maxWidth={step == 3 ? "md" : "md"}
      // slots={{ backdrop: Backdrop }}
      // slotProps={{
      //   backdrop: {
      //     timeout: 500,
      //   },
      // }}
    >
      <Grid
        xs={12}
        sx={{
          backgroundColor: "#8a2246",
          // height: 123,
          color: "#FFF",
          textAlign: "center",
          backgroundImage: "url(/assets/DeSantis.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          // width: step == 3 ? 868 : 1024,
        }}
      />
      <Grid
        container
        xs={12}
        // sx={{
        //   height: 1200,
        //   overflowY: "visible",
        // }}
      >
        {/* <Fade in={open}> */}
        {false && step === 1 && (
          <Box sx={{ mt: 6, mb: 6, fontSize: 18, textAlign: "center" }}>
            <Typography>
              Federal law requires us to use our best efforts to obtain and
              report the name, mailing address, occupation, and name of employer
              for each individual whose contributions aggregate in excess of
              $200 per calendar year. If you are not employed, please enter N/A.
              If you are retired, please indicate you are retired. This
              contribution is made from my personal funds and is not drawn on an
              account maintained by an incorporated entity.
            </Typography>
            <Typography>
              I am a United States citizen or an individual admitted with
              permanent residence status (e.g. a “green card holder”). This
              contribution will not be reimbursed by another person or entity. I
              am at least eighteen years old and I am not a federal government
              contractor. By law, the maximum that an individual may contribute
              to the Team DeSantis 2024 in a calendar year is $11,600. The legal
              maximum amount couples may contribute is $23,200 from a joint
              account if each spouse so designates the contributions.
            </Typography>
          </Box>
        )}
        <Typography sx={{ mt: 6, mb: 6, fontSize: 18, textAlign: "center" }}>
          {step === 2 && "Optional information"}
          {step === 3 && "Consent"}
        </Typography>
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            // m: "auto",
            // width: "fit-content",
          }}
        >
          {step == 1 && (
            <Box sx={{ width: "100%" }}>
              <Typography>
                Campaign finance law requires us to collect your employment
                information.
              </Typography>
              <hr />
              <Grid container sx={{ width: "100%" }} spacing={2}>
                <Grid xs={12} sm={6} md={2.5} lg={2.5}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="Your First Name"
                    value={info.first_name}
                    onChange={(e) => updateInfo("first_name", e.target.value)}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={2.5} lg={2.5}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="Your Last Name"
                    value={info.last_name}
                    onChange={(e) => updateInfo("last_name", e.target.value)}
                  />
                </Grid>
                <Grid
                  xs={7}
                  sm={8}
                  md={4}
                  lg={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "3px",
                  }}
                >
                  <TextField
                    // sx={{ width: "368px" }}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                    placeholder="Enter Your Email"
                    value={info.email}
                    onChange={(e) => updateInfo("email", e.target.value)}
                  />
                </Grid>
                <Grid xs={5} sm={4} md={3} lg={3}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="Your Phone Number"
                    value={info.telephone}
                    onChange={(e) => updateInfo("telephone", e.target.value)}
                  />
                </Grid>
                <Grid xs={9}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="Enter Your Street Address"
                    value={info.address1}
                    onChange={(e) => updateInfo("address1", e.target.value)}
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="Apt #"
                    value={info.address2}
                    onChange={(e) => updateInfo("address2", e.target.value)}
                  />
                </Grid>
                <Grid xs={5}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="City"
                    value={info.city}
                    onChange={(e) => updateInfo("city", e.target.value)}
                  />
                </Grid>
                <Grid xs={4}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="State"
                    value={info.state}
                    onChange={(e) => updateInfo("state", e.target.value)}
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="Zip Code"
                    value={info.zipcode}
                    onChange={(e) => updateInfo("zipcode", e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ width: "100%" }} spacing={2}>
                <Grid xs={7}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="Your Employer"
                    value={info.employer}
                    onChange={(e) => updateInfo("employer", e.target.value)}
                  />
                </Grid>
                <Grid xs={5}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder="Your Occupation"
                    value={info.occupation}
                    onChange={(e) => updateInfo("occupation", e.target.value)}
                  />
                </Grid>
              </Grid>
              <hr />

              {false && amount > 11800 && (
                <Grid container sx={{ width: "100%" }} spacing={2}>
                  <Grid xs={7}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      placeholder="Spouse's Employer:"
                      value={info.spouse_employer}
                      onChange={(e) =>
                        updateInfo("spouse_employer", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid xs={5}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      placeholder="Spouse's Occupation"
                      value={info.spouse_occupation}
                      onChange={(e) =>
                        updateInfo("spouse_occupation", e.target.value)
                      }
                    />
                  </Grid>
                  <Box
                    sx={{
                      width: "100%",
                      paddingBottom: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Grid>
              )}
            </Box>
          )}
          {false && step == 2 && (
            <Box sx={{ width: "100%" }}>
              <Grid container sx={{ width: "100%" }} spacing={2}>
                <Grid xs={4}>
                  <Typography>Home phone</Typography>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder=""
                    value={info.home}
                    onChange={(e) => updateInfo("home", e.target.value)}
                  />
                </Grid>
                <Grid xs={4}>
                  <Typography>Office phone</Typography>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder=""
                    value={info.office}
                    onChange={(e) => updateInfo("office", e.target.value)}
                  />
                </Grid>
                <Grid xs={4}>
                  <Typography>Cell Phone</Typography>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    placeholder=""
                    value={info.cell}
                    onChange={(e) => updateInfo("cell", e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {false && step == 3 && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                Team DeSantis 2024 (“JFC”) is a joint fundraising committee
                composed of: Ron DeSantis for President and Great American
                Comeback (each individually, a “Committee” and collectively, the
                “Committees”).{" "}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                Contributions or gifts to JFC, or any participating Committee
                individually, are not deductible as charitable contributions for
                federal income tax purposes. All contributions permissible under
                The Federal Election Campaign Act (“FECA”), but which would
                cause a contributor to exceed any applicable Federal
                contribution limit to a specific Committee, shall be reallocated
                to the remaining Committees according to this allocation formula
                to the extent permitted by FECA. Any contribution that would
                otherwise cause a contributor to exceed any applicable Federal
                contribution limit or that is otherwise impermissible under FECA
                shall be refunded to the contributor. Any contributor may
                designate his or her contribution to a specific participating
                Committee, to the extent permissible by FECA.
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                Any contribution that would otherwise cause a contributor to
                exceed any applicable Federal contribution limit or that is
                otherwise impermissible under FECA shall be refunded to the
                contributor. Any contributor may designate his or her
                contribution to a specific participating Committee, to the
                extent permissible by FECA. Any contributor may make his or her
                contribution directly payable to any Committee participating in
                JFC.
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                Any such designated contribution that causes the contributor to
                exceed the contribution limit to the designated Committee shall
                not be reallocated by JFC absent the prior written permission of
                the contributor as required by law. Contributions from
                corporations, labor unions, foreign nationals (without “green
                cards”), and federal government contractors are prohibited.
              </Typography>
              <br />
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                The legal maximum in federal funds that an individual may
                contribute to the JFC is $11,600. The legal maximum in federal
                funds that a couple may contribute to the JFC is $23,200. A
                federal political action committee that is not a multi-candidate
                committee may contribute to the JFC a maximum of $11,600. The
                legal maximum in federal funds that a federal multi-candidate
                political action committee may contribute to the JFC is $15,000.
              </Typography>
              <br />
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                Contributions to JFC shall be allocated as follows:
              </Typography>
              <br />
              <ol>
                <li>
                  The first $3,300 from any single individual’s contribution
                  shall go to Ron DeSantis for President’s primary election
                  account. The next $3,300 shall go to Ron DeSantis for
                  President’s general election account. The next $5,000 will go
                  to Great American Comeback.
                </li>
                <li>
                  $6,600 shall go to Ron DeSantis for President’s general
                  election account. The next $10,000 will go to Great American
                  Comeback.
                </li>
                <li>
                  The first $3,300 from any non-multicandidate PAC contribution
                  shall go to Ron DeSantis for President’s primary election
                  account. The next $3,300 shall go to Ron DeSantis for
                  President’s general election account. The next $5,000 will go
                  to Great American Comeback.
                </li>
                <li>
                  The first $5,000 from any multicandidate PAC’s contribution
                  shall go to Ron DeSantis for President’s primary election
                  account. The next $5,000 shall go to Ron DeSantis for
                  President’s general election account. The next $5,000 will go
                  to Great American Comeback.{" "}
                </li>
              </ol>
              <br />
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                The contributor’s acknowledgement shall serve as designation of
                his or her contribution to the particular elections described
                above.
              </Typography>
              <Typography
                variant="h3"
                sx={{ textAlign: "center", paddingBottom: "12px" }}
              >
                I certify this contribution is drawn on my personal account,
                represents my personal funds, and is not drawn on an account
                maintained by a corporate entity
              </Typography>
            </Box>
          )}
        </Grid>
        {/* </Fade> */}
      </Grid>
      <Grid xs={12}>
        <Box
          sx={{
            display: "block",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            The contributor’s acknowledgement shall serve as designation of his
            or her contribution to the particular elections described below.
          </Typography>
          <Typography
            // variant="h3"
            sx={{ textAlign: "center", paddingBottom: "12px" }}
          >
            I certify this contribution is drawn on my personal account,
            represents my personal funds, and is not drawn on an account
            maintained by a corporate entity
          </Typography>
          {/* {step > 1 && (
            <Button sx={{ marginRight: "6px" }} onClick={back}>
              Back
            </Button>
          )} */}
          {/* {(step == 1 || step == 2) && (
            <Button
              sx={{ marginLeft: "6px" }}
              onClick={next}
              disabled={
                !info.first_name ||
                !info.last_name ||
                !info.address ||
                !info.zipcode ||
                !info.email ||
                !info.employer ||
                !info.occupation ||
                (amount > 11800 &&
                  (!info.spouse_employer || !info.spouse_occupation))
              }
            >
              Next
            </Button>
          )} */}
          {step === 1 && (
            <Button
              sx={{ marginLeft: "6px" }}
              fullWidth
              onClick={handleSubmit}
              disabled={
                !info.first_name ||
                !info.last_name ||
                !info.address1 ||
                !info.city ||
                !info.state ||
                !info.zipcode ||
                !info.telephone ||
                !info.email ||
                !info.employer ||
                !info.occupation ||
                (amount > 11800 &&
                  (!info.spouse_employer || !info.spouse_occupation))
              }
            >
              Agree & Submit
            </Button>
          )}
        </Box>
      </Grid>
      {/* <Disclaimer /> */}
    </Grid>
  );
}
