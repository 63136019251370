export const EIP712Domain = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" },
];

export const eip712Types = {
  Promote: [
    { name: "message", type: "string" },
    { name: "name", type: "string" },
    { name: "wallet", type: "address" },
    { name: "fee", type: "uint256" },
  ],
  // EIP712Domain,
  Transaction: [
    { name: "txType", type: "uint256" },
    { name: "from", type: "address" },
    { name: "to", type: "address" },
    { name: "gasLimit", type: "uint256" },
    { name: "gasPerPubdataByteLimit", type: "uint256" },
    { name: "maxFeePerGas", type: "uint256" },
    { name: "maxPriorityFeePerGas", type: "uint256" },
    { name: "paymaster", type: "address" },
    { name: "nonce", type: "uint256" },
    { name: "value", type: "uint256" },
    { name: "data", type: "string" },
    { name: "factoryDeps", type: "bytes32[]" },
    { name: "paymasterInput", type: "bytes" },
    // { name: 'type', type: 'uint256' },
    // { name: 'chainId', type: 'uint256' },
    // { name: 'customData', type: 'Eip712Meta' },
  ],
  PaymasterParams: [
    { name: "paymaster", type: "address" },
    { name: "paymasterInput", type: "string" },
  ],
  Eip712Meta: [
    { name: "gasPerPubdata", type: "string" },
    { name: "customSignature", type: "string" },
    { name: "paymasterParams", type: "PaymasterParams" },
    { name: "factoryDeps", type: "bytes" },
  ],
};

export const EIP712Promote = (name, wallet, fee) => {
  const message = `Sign to promote the campaign`;

  return {
    domain: {
      name: "npayme",
      version: "1",
      chainId: 1,
    },
    types: {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
      ],
      Promote: eip712Types.Promote,
    },
    primaryType: 'Promote',
    message: { message, name, wallet, fee },
  };
};
