import { Typography, Button, TextField, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "components/Slider";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const PreviewCampaign = (props) => {
  const {
    campaignName,
    imageUrl,
    description,
    message,
    dateLeft,
    raised,
    goal,
    totalRaisedFund,
    creator = { name: "", earn: 0 },
    affiliate = { earn: 0 },
    beneficiary = { name: "", percentage: 0 },
    contribute,
    settle,
    shareAndEarn,
    promote,
    view,
  } = props;
  // console.log(view, creator, affiliate, message);

  const smSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const target = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(goal);

  if (!creator.name || !affiliate.name) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="start"
      xs={12}
    >
      {!message ||
        (message !== description && (
          <Grid
            xs={12}
            sx={{
              // maxHeight: 103,
              marginBottom: "16px !important",
              padding: "12px 16px !important",
              backgroundColor: "#FFF1EB",
            }}
          >
            <span>
              <Typography
                variant="h3"
                component="span"
                sx={{ textTransform: "capitalize" }}
              >
                {affiliate.name}
              </Typography>
              <span
                style={{
                  width: 56,
                  height: 18,
                  backgroundColor: "#FBD158",
                  borderRadius: 4,
                  marginLeft: 6,
                  fontSize: 12,
                }}
              >
                Promoter
              </span>
            </span>
            {message && (
              <Grid>
                <ReactQuill theme="bubble" value={message} readOnly={true} />
                {/* <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {message}
                </Typography> */}
              </Grid>
            )}
          </Grid>
        ))}
      <Grid sx={{ marginBottom: "6px" }}>
        <Typography variant="h2" component="h2">
          {campaignName}
        </Typography>
      </Grid>
      <Grid container direction="row" xs={12}>
        <Grid xs={12} sm={12} md={6} lg={6}>
          <img src={imageUrl} style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6}>
          <ReactQuill theme="bubble" value={description} readOnly={true} />
        </Grid>
      </Grid>
      <Grid container direction="row" xs={12}>
        {!smSize && (
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              padding: "3px",
            }}
          >
            <Typography variant="h6" component="h6">
              {dateLeft.time}
            </Typography>
            <Typography variant="body1p" component="span">
              {dateLeft.unit} remaining
            </Typography>
            <Box
              sx={{
                marginTop: 6,
              }}
            >
              <Typography variant="h6" component="h6">
                {raised}%
              </Typography>
              <Typography variant="body1p" component="span">
                {`$${(totalRaisedFund / 1000000).toFixed(
                  2
                )} raised out of ${target} goal`}
              </Typography>
            </Box>

            <Box>
              <Button
                sx={{ marginTop: "10px", height: 28 }}
                variant="outlined"
                onClick={settle}
                disabled={beneficiary.amount == 0}
              >
                Settle
              </Button>
              <Typography
                sx={{ marginTop: "6px", fontSize: "13px", lineHeight: "14px" }}
              >
                Settling will immediately pay out all funds currently owed to
                the Beneficiary, Creator and all Affiliates.
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            padding: "3px",
          }}
        >
          <Slider
            size="small"
            label="Affiliates Earn"
            value={affiliate.earn / 100}
            readOnly
          />
          <Slider
            label={() => (
              <Typography variant="body1" component="span">
                Creator (<strong>{creator.name}</strong>) Earns:
              </Typography>
            )}
            value={creator.earn / 100}
            readOnly
          />
          <Slider
            size="small"
            label={() => (
              <Typography variant="body1" component="span">
                Beneficiary (<strong>{beneficiary.name}</strong>) Earns:
              </Typography>
            )}
            value={(10000 - affiliate.earn - creator.earn) / 100}
            // value={beneficiary.percentage}
            readOnly
          />
        </Grid>
      </Grid>
      {typeof contribute === "function" &&
        typeof shareAndEarn === "function" && (
          <Grid container xs={12} sx={{ marginTop: "12px" }}>
            <Grid
              xs={12}
              sm={6}
              md={6}
              lg={6}
              sx={{
                padding: "3px",
              }}
            >
              <Button fullWidth variant="outlined" onClick={contribute}>
                Donate Now
              </Button>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={6}
              lg={6}
              sx={{
                padding: "3px",
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={shareAndEarn}
                sx={{
                  "&:hover": {
                    backgroundColor: "#FFC700",
                    // backgroundColor: 'linear-gradient(to center, #b8860b, #d4af37)',
                  },
                }}
              >
                <span>Share & Earn</span>
              </Button>
            </Grid>
          </Grid>
        )}
      {typeof promote === "function" && (
        <Button variant="outlined" fullWidth onClick={promote}>
          Promote Campaign
        </Button>
      )}
      {smSize && (
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            padding: "3px",
          }}
        >
          <Box
            sx={{
              marginTop: 6,
            }}
          >
            <Typography variant="h6" component="h6">
              {raised}%
            </Typography>
            <Typography variant="body1p" component="span">
              {`$${(totalRaisedFund / 1000000).toFixed(
                2
              )} raised out of ${target} goal`}
            </Typography>
          </Box>
          <Typography
            sx={{ marginTop: "10px", fontSize: "13px", lineHeight: "14px" }}
          >
            <span onClick={settle}>Settling</span> will immediately pay out all
            funds currently owed to the Beneficiary, Creator and all Affiliates.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PreviewCampaign;
