import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const mySlider = (props) => {
  const { label, value = 0, onChange } = props;
  return (
    <Grid>
      <Box sx={{ width: "100%", display: "flex" }}>
        {typeof label === "string" ? (
          <Typography>{label}:</Typography>
        ) : typeof label === "function" ? (
          label()
        ) : null}
        <Typography sx={{ flex: 1, float: "right", textAlign: "right" }}>
          {value}%
        </Typography>
      </Box>
      <Slider
        defaultValue={50}
        valueLabelDisplay="auto"
        value={parseFloat(value)}
        onChange={onChange}
        sx={{
          color: "success.main",
          "& .MuiSlider-thumb": {
            borderRadius: "0px",
            width: 0,
          },
          "& .MuiSlider-rail": {
            height: 8,
            color: "#E3E6E8",
            borderRadius: 4,
          },
        }}
        // fullWidth
      />
    </Grid>
  );
};
export default mySlider;
