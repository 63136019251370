import { useState, useEffect, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { Button, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Grid from "@mui/material/Unstable_Grid2";
import { MoonPayProvider } from "@moonpay/moonpay-react";
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";

import Container from "../../components/Container";
import SelectWallet from "../../components/SelectWallet";
import PreviewCampaign from "features/campaign/Preview";
import Share from "../campaign/Share";
import Donate from "../campaign/Donate";
import ShareEarn from "../campaign/ShareEarn";
import Why from "features/campaign/Why";
import Thanks from "../campaign/Thanks";
import ScanConnect from "../../components/ScanConnect";
import Settlement from "../../components/Settlement";
import {
  pullCampaign,
  pullAffiliate,
  pullBeneficiary,
  getURLSignature,
  payWithCreditCard,
} from "../../api/api";
import { getRemainingDays } from "utils";
import store from "../../store/appStore";
import { NETWORK } from "utils/blockchain";

const processed_uuid = [];
const disabled_moonpay = false;
const Patron = () => {
  const { contract_address = "", affiliate_address = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [campaign, setCampaign] = useState({});
  const [twins, setTwins] = useState([]);
  const [affiliate, setAffiliate] = useState({});
  const [beneficiary, setBeneficiary] = useState({});
  const [totalRaisedFund, setTotalRaisedFund] = useState(0);
  const [receipt, setReceipt] = useState({});

  const [open, setSnackbar] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState("success");
  const [acceptUSDConEth, setAcceptUSDConEth] = useState("N");

  const browser = searchParams.get("browser");
  const deeplinkAction = searchParams.get("action");
  const deeplinkToken = searchParams.get("token");
  const deeplinkDonation = searchParams.get("amount");
  const deeplinkCompliance = searchParams.get("compliance");
  const deeplinkPromoter = searchParams.get("promoter");
  const deeplinkMessage = searchParams.get("message");
  const deeplinkProcessor = searchParams.get("processor");
  const deeplinkUUID = searchParams.get("uuid");
  const deeplinkTransactionID = searchParams.get("transactionId");
  const deeplinkTransactionStatus = searchParams.get("transactionStatus");
  const deeplinkErrMsg = searchParams.get("errMsg");
  const deeplinkUserMsg = searchParams.get("userMsg");

  const [token, setToken] = useState(
    deeplinkToken || (acceptUSDConEth === "Y" ? "USDC" : "USDC_PG")
  );
  const [donation, setDonation] = useState(
    deeplinkDonation ? deeplinkDonation : 0
  );
  const [donationUSD, setDonationUSD] = useState(
    deeplinkDonation ? deeplinkDonation : 0
  );

  const [patronAddress, setPatronAddress] = useState("");
  const [patronName, setPatronName] = useState(deeplinkPromoter || "");
  const [promoteMessage, setPromoteMessage] = useState(deeplinkMessage || "");
  const [visible, setVisible] = useState(false);
  const [baseCurrencyCode, setBaseCurrencyCode] = useState("usd");
  const [defaultCurrencyCode, setDefaultCurrencyCode] = useState("usdc");
  const [designatedWwalletAddress, setDesignatedWwalletAddress] = useState("");
  const [externalTransactionId, setExternalTransactionId] = useState("");
  const [redirectURL, setRedirectURL] = useState("");
  const currencyCode =
    token === "USDC"
      ? "usdc"
      : token === "USDC_PG"
      ? "usdc_polygon"
      : "usdc_zksync";

  const landingStep =
    deeplinkAction === "thanks"
      ? 20
      : deeplinkAction === "donate"
      ? deeplinkDonation > 0
        ? deeplinkCompliance
          ? 4
          : 3
        : 1
      : deeplinkAction === "promote" && patronName && promoteMessage
      ? 9
      : 1;

  const [step, setStep] = useState(landingStep);
  // console.log("window.location.host", window.location.host);

  const [container, setContainer] = useState({
    marginTop: 135,
  });

  const snap = useSnapshot(store);
  console.log("snap.pkMoonPay", snap.pkMoonPay);
  const goToStep = (step) => {
    window.scrollTo(0, 0);
    setStep(step);
  };

  const next = (jump) => {
    window.scrollTo(0, 0);
    if (!isNaN(jump)) {
      return setStep(jump);
    }

    setStep((prev) => (prev < 10 ? prev + 1 : prev));
  };
  const back = () => {
    window.scrollTo(0, 0);
    setStep((prev) => {
      if (prev === 6 || prev === 3 || prev === 1) {
        return 1;
      }
      return prev - 1;
    });
  };

  const notify = useCallback((msg, severity) => {
    setMessage(msg);
    setSeverity(severity);
    setSnackbar(true);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar(false);
  };

  useEffect(() => {
    if (
      contract_address.toLowerCase() ===
      "0x66197d8e98028b60e2682b1c079c79583f4b1047"
    ) {
      document.title = "DeSantis npayme";
    }

    if (deeplinkErrMsg) {
      notify(deeplinkErrMsg, "error");
    }

    if (deeplinkUserMsg) {
      notify(deeplinkUserMsg, "success");
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      if (contract_address) {
        try {
          let { success, campaign, twins, totalRaisedFund } =
            await pullCampaign(contract_address);
          const {
            name,
            description,
            creatorName,
            goal,
            expiration,
            political,
            creatorPercentage: creatorEarn,
            promoterPercentage: affiliateEarn,
            campaignUrl,
          } = campaign || {};

          if (success) {
            setCampaign({
              name,
              description,
              creatorName,
              goal,
              expiration: new Date(expiration),
              political,
              creatorEarn,
              affiliateEarn,
              campaignUrl,
            });

            setTwins(twins);

            const deployedOnEthereum = twins.reduce(
              (acc, v) => acc || v.network === NETWORK.ethereum,
              false
            );
            setAcceptUSDConEth(deployedOnEthereum ? "Y" : "N");
            setToken(
              deeplinkToken || (deployedOnEthereum ? "USDC" : "USDC_PG")
            );

            setTotalRaisedFund(totalRaisedFund);

            await pullBeneficiary(contract_address, 0).then((res) => {
              setBeneficiary({
                name: res.beneficiary.name,
                percentage: res.beneficiary.percentage,
                amount: res.beneficiary.amount,
              });

              const beneficiaryAddress = res.beneficiary.address;
              store.beneficiary = beneficiaryAddress;
              // const overrides = customizedTheme[beneficiaryAddress];
              // const overrides =
              //   customizedTheme["0x7121DD92Ab8acd3Ce5741523C447B18821e7bba8"];
              // console.log("..........overrides", overrides);
              // if (overrides) {
              //   store.theme = createCustomizedTheme(overrides);
              // }
            });
          }
        } catch (e) {
          console.log(e);
          notify(e.response?.data?.message || e.message, "error");
        }
      }
    };

    load();
  }, [contract_address]);

  // useEffect(() => {
  //   setVisible(true);
  // }, [designatedWwalletAddress]);

  useEffect(() => {
    if (contract_address && affiliate_address) {
      pullAffiliate(contract_address, affiliate_address).then((res) => {
        if (res.success) {
          setAffiliate({
            name: res.affiliate.name,
            promoteMessage: res.affiliate.promote_message,
            raisedFund: res.affiliate.raisedFund,
          });
        } else {
          setAffiliate({
            name: "",
            promoteMessage: "",
            raisedFund: 0,
          });

          return notify(res.message, "error");
        }
      });
    }
  }, [contract_address, affiliate_address]);

  const resetBeneficiaryAmount = () => {
    setBeneficiary({ ...beneficiary, amount: 0 });
  };

  const {
    name: campaignName,
    description,
    creatorName,
    goal = 1000,
    expiration,
    political,
    campaignUrl,
    creatorEarn,
    affiliateEarn,
  } = campaign;

  const dateLeft = getRemainingDays(expiration);

  const handleOverlay = (a, c, b) => {
    console.log("click", a, b, c);
    const overlay = document.getElementsByClassName(
      "moonpay-widget__overlay-container"
    );
    console.log("......overlay", overlay, visible);
    if (overlay.length === 0 && visible === true) {
      setVisible(false);
      document.removeEventListener("click", handleOverlay);
    }
  };
  if (visible) {
    console.log(
      "Add listener",
      document.getElementsByClassName("moonpay-widget__overlay-container")
    );
    document.addEventListener("click", handleOverlay);
  } else {
    const overlay = document.getElementsByClassName(
      "moonpay-widget__overlay-container"
    );
    console.log("1......overlay", overlay);
  }

  const handlePayWithCreditCard = async () => {
    const paymentRequest = await payWithCreditCard(
      contract_address,
      affiliate_address,
      token,
      donationUSD,
      currencyCode,
      baseCurrencyCode,
      browser,
      "MoonPay"
    );

    if (!paymentRequest || paymentRequest.success === false) {
      return notify("Failed to prepare a payment request", "error");
    }

    const { wallet_address, redirect_url, external_trans_id } = paymentRequest;
    console.log("..........................");
    console.log(processed_uuid);
    console.log(wallet_address);
    console.log(external_trans_id);
    console.log(redirect_url);

    if (!processed_uuid.includes(external_trans_id)) {
      processed_uuid.push(external_trans_id);
      setRedirectURL(redirect_url);
      setDesignatedWwalletAddress(wallet_address);
      setExternalTransactionId(external_trans_id);
      setTimeout(() => setVisible(true), 1000);
    } else {
      setVisible(false);
    }
  };

  const onTransactionCompleted = (a, b, c) => {
    console.log("debug...........1", a, b, c);
  };

  const onInitiateDeposit = (a, b, c) => {
    console.log("debug...........2", a, b, c);
  };

  return (
    <>
      {step === 1 && (
        <Container
          political={political}
          share={() => goToStep(2)}
          paper={{ width: 870, marginTop: 68 }}
          // primaryTitle="Hello"
          // secondaryTitle="Patron"
          subTitle="Welcome to the npayme campaign donation page."
        >
          <PreviewCampaign
            campaignName={campaignName}
            imageUrl={campaignUrl}
            description={description}
            message={affiliate.promoteMessage}
            dateLeft={dateLeft}
            raised={(Number(totalRaisedFund) / 10000 / goal).toFixed(2)}
            goal={goal}
            totalRaisedFund={totalRaisedFund}
            creator={{
              earn: creatorEarn,
              name: creatorName,
            }}
            affiliate={{
              earn: affiliateEarn,
              name: affiliate.name,
            }}
            beneficiary={beneficiary}
            contribute={() => goToStep(3)}
            settle={() => goToStep(15)}
            shareAndEarn={() => goToStep(6)}
          />
        </Container>
      )}
      {/*------- Share Flow----------*/}
      {step === 2 && (
        <Container
          political={political}
          back={back}
          paper={{ marginTop: 106 }}
          primaryTitle="Share"
          secondaryTitle="Campaign"
          subTitle="Spread the word and share on socials."
        >
          <Share
            affiliateAddress={affiliate_address}
            contractAddress={contract_address}
            notify={notify}
          />
        </Container>
      )}
      {/*------- Contribute Flow----------*/}
      {step === 3 && (
        <Container
          political={political}
          back={back}
          paper={{ marginTop: container.marginTop }}
          primaryTitle=""
          secondaryTitle="Make a Donation"
          subTitle="Choose payment method."
        >
          <Donate
            amount={donation}
            token={token}
            setToken={setToken}
            setAmount={setDonation}
            setAmountUSD={setDonationUSD}
            setContainer={setContainer}
            notify={notify}
            political={political}
            acceptUSDConEth={acceptUSDConEth}
          />
          <Button
            disabled={container.disablePay || donation <= 0}
            sx={{
              marginTop: 6,
              // backgroundColor: "#7129f5",
              height: 37.13,
              width: "50%",
            }}
            variant="outlined"
            onClick={() => {
              next();
            }}
            // fullWidth
          >
            Pay with Crypto Wallet
          </Button>
          <Button
            disabled={
              container.disablePay ||
              donation <= 0 ||
              token == "USDC_ZK" ||
              disabled_moonpay
            }
            sx={{
              marginTop: 6,
              // backgroundColor: "#7129f5",
              height: 37.13,
              width: "50%",
            }}
            variant="outlined"
            onClick={handlePayWithCreditCard}
            // fullWidth
          >
            Pay with Credit Card
          </Button>
        </Container>
      )}
      {step === 4 && (
        <Container
          political={political}
          back={back}
          paper={{ width: 574, marginTop: 77 }}
          // subTitle={
          //   <Typography sx={{ textAlign: "center" }}>
          //     Need a MetaMask wallet?{" "}
          //     <Link
          //       href="https://metamask.io/download/"
          //       target="_blank"
          //       rel="noreferrer"
          //     >
          //       Download it here.
          //     </Link>
          //   </Typography>
          // }
        >
          <SelectWallet
            contract_address={contract_address}
            affiliate_address={affiliate_address}
            twins={twins}
            to={contract_address}
            token={token}
            amount={donation}
            next={next}
            notify={notify}
            setReceipt={setReceipt}
            political={political}
            browser={browser}
            // deeplinkAction={deeplinkAction}
            // deeplinkToken={deeplinkToken}
            // deeplinkDonation={deeplinkDonation}
            deeplinkCompliance={deeplinkCompliance}
          />
        </Container>
      )}
      {step === 5 && (
        <Container
          political={political}
          back={back}
          paper={{ marginTop: 103 }}
          secondaryTitle="Scan & Donate"
          subTitle="Use the address below to make donation from your wallet."
        >
          <ScanConnect
            args={{
              action: "donate",
              token,
              amount: parseFloat(donation),
              amountUSD: donationUSD,
              contract_address,
              affiliate_address,
              beneficiary,
              donation,
              political,
            }}
            setStep={goToStep}
            notify={notify}
            setPatronAddress={setPatronAddress}
          />
        </Container>
      )}
      {/*------- Share & Earn Flow----------*/}
      {step === 6 && (
        <Container
          political={political}
          back={back}
          paper={{ width: 918, marginTop: 56 }}
          secondaryTitle="Share and Earn?"
          // subTitle="By becoming an Affiliate, you can Share a unique Affiliate link to your favorite npayme campaign with your followers on social media and automatically Earn whenever someone Donates to it"
        >
          <>
            <Grid xs={12}>
              <Typography variant="h6" component="h6" sx={{ color: "#2E3538" }}>
                {`Affiliate earns ${affiliateEarn / 100}%`}
              </Typography>
            </Grid>
            <ShareEarn
              campaignName={campaignName}
              imageUrl={campaignUrl}
              affiliateEarn={affiliateEarn / 100}
              political={political}
            />
            <Grid xs={12}>
              <Button
                // sx={{
                //   marginTop: 6,
                //   height: 37.13,
                // }}
                variant="outlined"
                onClick={next}
                fullWidth
              >
                Become an npayme Affiliate
              </Button>
            </Grid>
          </>
        </Container>
      )}
      {step === 7 && (
        <Container
          political={political}
          back={back}
          paper={{ marginTop: 180 }}
          primaryTitle="Hello"
          secondaryTitle="Affiliate"
          subTitle="Promote this npayme campaign and start earning money."
        >
          <Why
            name={patronName}
            message={promoteMessage}
            setName={setPatronName}
            setPromoteMessage={setPromoteMessage}
          />
          <Button
            sx={{
              marginTop: 6,
              height: 37.13,
            }}
            variant="outlined"
            onClick={next}
            fullWidth
          >
            Next
          </Button>
        </Container>
      )}
      {step === 8 && (
        <Container
          political={political}
          back={back}
          paper={{ width: 870, marginTop: 68 }}
          primaryTitle="Preview"
          secondaryTitle="Campaign"
        >
          <PreviewCampaign
            view="patron"
            campaignName={campaignName}
            imageUrl={campaignUrl}
            description={description}
            dateLeft={dateLeft}
            promote={next}
            raised={(Number(totalRaisedFund) / 10000 / goal).toFixed(2)}
            goal={goal}
            totalRaisedFund={totalRaisedFund}
            creator={{
              earn: creatorEarn,
              name: creatorName,
            }}
            affiliate={{
              earn: affiliateEarn,
              name: patronName,
            }}
            beneficiary={beneficiary}
            message={promoteMessage}
          />
        </Container>
      )}
      {step === 9 && (
        <Container
          political={political}
          back={back}
          paper={{ marginTop: 42 }}
          secondaryTitle="Scan & Connect"
          subTitle="Use your WalletConnect mobile app to scan the QR code below and authorize connection to this campaign."
        >
          <ScanConnect
            args={{
              action: "promote",
              token: "NPAY_ZK",
              amount: parseFloat(donation),
              contract_address,
              affiliate_address,
              donation,
              beneficiary,
              promoterName: patronName,
              promoteMessage,
              political,
            }}
            browser={browser}
            notify={notify}
            setStep={goToStep}
            setPatronAddress={setPatronAddress}
            instruction="The promotion of this campaign requires a signature from your wallet" // "The promotion of this campaign requires a setup fee of 1 NPAY token"
          />
        </Container>
      )}
      {step === 10 && (
        <Container
          political={political}
          paper={{ marginTop: 106 }}
          secondaryTitle="Share Campaign"
          subTitle="Spread the word and share on socials."
        >
          <Share
            affiliateAddress={patronAddress}
            contractAddress={contract_address}
            notify={notify}
          />
        </Container>
      )}
      {step === 15 && (
        <Container
          political={political}
          back={() => next(1)}
          paper={{ marginTop: 103 }}
          secondaryTitle="Settlement"
          subTitle="No censorship. As the campaign is ended, anyone can conclude it and send funds to the Beneficiary, Creator and Affiliates."
        >
          <Settlement
            args={{
              contract_address,
            }}
            setStep={goToStep}
            notify={notify}
            resetBeneficiaryAmount={resetBeneficiaryAmount}
            setPatronAddress={setPatronAddress}
          />
        </Container>
      )}

      {step === 20 && (
        <Container
          political={political}
          paper={{ marginTop: 40 }}
          secondaryTitle="Thank You!"
          subTitle={`For your donation to the ${campaignName} Campaign. Your support is greatly appreciated.`}
        >
          <Thanks
            contract_address={contract_address}
            receipt={receipt}
            beneficiary={beneficiary}
            token={token}
            share={() => goToStep(2)}
            shareAndEarn={() => goToStep(6)}
          />
        </Container>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          severity={severity}
          variant="filled"
          elevation={6}
          onClose={handleClose}
          sx={{ width: "100%", fontSize: 12 }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
      {visible && (
        <MoonPayProvider apiKey={snap.pkMoonPay} environment="sandbox" debug>
          <MoonPayBuyWidget
            variant="overlay"
            visible={visible}
            baseCurrencyCode={baseCurrencyCode}
            baseCurrencyAmount={donationUSD}
            defaultCurrencyCode={defaultCurrencyCode}
            currencyCode={currencyCode}
            walletAddress={designatedWwalletAddress}
            externalTransactionId={externalTransactionId}
            redirectURL={redirectURL}
            onLogin={(a) => console.log("Customer logged in!", a)}
            onUrlSignatureRequested={getURLSignature}
            onInitiateDeposit={onInitiateDeposit}
            onTransactionCompleted={onTransactionCompleted}
            lockAmount
          />
        </MoonPayProvider>
      )}
    </>
  );
};

export default Patron;

// [
//   {
//     "decimals": 6,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 8.8,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 20,
//     "maxSellAmount": 2000,
//     "id": "1440a41c-d03d-4047-a3b3-a6ddac63598e",
//     "createdAt": "2019-05-17T18:24:45.206Z",
//     "updatedAt": "2023-11-20T13:53:57.184Z",
//     "type": "crypto",
//     "name": "Cardano",
//     "code": "ada",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^(([0-9A-Za-z]{57,59})|([0-9A-Za-z]{100,104}))$",
//     "testnetAddressRegex": "^(([0-9A-Za-z]{57,59})|([0-9A-Za-z]{100,104}))$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "1815",
//       "chainId": null,
//       "networkCode": "cardano"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 11,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 20,
//     "maxSellAmount": 2000,
//     "id": "da2f536f-7991-4b96-ac21-602031044c14",
//     "createdAt": "2020-08-17T12:10:27.716Z",
//     "updatedAt": "2023-11-20T13:53:57.156Z",
//     "type": "crypto",
//     "name": "Algorand",
//     "code": "algo",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^[A-Z0-9]{58,58}",
//     "testnetAddressRegex": "^[A-Z0-9]{58,58}",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "algorand"
//     }
//   },
//   {
//     "decimals": 8,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.022,
//     "maxBuyAmount": 1000000,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 3.93071,
//     "maxSellAmount": null,
//     "id": "ae0b64b5-4f68-41aa-b102-d0860dfc0d35",
//     "createdAt": "2022-11-03T16:04:04.154Z",
//     "updatedAt": "2023-11-20T13:53:57.188Z",
//     "type": "crypto",
//     "name": "Aptos",
//     "code": "apt",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Za-z]{64}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Za-z]{64}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "aptos"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 16000,
//     "minAmount": 50,
//     "minBuyAmount": 50,
//     "maxBuyAmount": 16000,
//     "id": "beb381fb-16d0-4949-890c-d78a626a0a8a",
//     "createdAt": "2020-03-14T11:51:49.199Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Australian Dollar",
//     "code": "aud",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.11,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 2,
//     "minSellAmount": 1.9,
//     "maxSellAmount": 170,
//     "id": "38b1f272-af74-4f61-a425-62219aea25d9",
//     "createdAt": "2021-11-30T12:59:02.328Z",
//     "updatedAt": "2023-11-20T13:53:57.164Z",
//     "type": "crypto",
//     "name": "Avalanche (C-Chain)",
//     "code": "avax_cchain",
//     "precision": 2,
//     "isSellSupported": true,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000000000000000000000000000000000",
//       "coinType": null,
//       "chainId": "43114",
//       "networkCode": "avalanche_c_chain"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 2000,
//     "minAmount": 212,
//     "minBuyAmount": 92.4,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 212,
//     "maxSellAmount": 2000,
//     "id": "1faa158e-8433-4bae-bdb8-b5eb768f767d",
//     "createdAt": "2019-04-25T21:26:14.749Z",
//     "updatedAt": "2023-12-11T01:01:00.789Z",
//     "type": "crypto",
//     "name": "Basic Attention Token",
//     "code": "bat",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 8,
//     "maxAmount": 10,
//     "minAmount": 0.05,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 1,
//     "minSellAmount": 0.1,
//     "maxSellAmount": 100,
//     "id": "b01f8f58-f915-491b-aadb-7ba6b966c861",
//     "createdAt": "2018-09-28T10:47:49.801Z",
//     "updatedAt": "2023-11-20T13:53:57.176Z",
//     "type": "crypto",
//     "name": "Bitcoin Cash",
//     "code": "bch",
//     "precision": 3,
//     "isSellSupported": true,
//     "addressRegex": "^(bitcoincash:)?(q|p)[a-z0-9]{41}$",
//     "testnetAddressRegex": "^(bchtest:)?(q|p)[a-z0-9]{41}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "145",
//       "chainId": null,
//       "networkCode": "bitcoin_cash"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 20000,
//     "minAmount": 60,
//     "minBuyAmount": 60,
//     "maxBuyAmount": 20000,
//     "id": "4372afed-961e-4c1c-9a8c-e922b2cb0ca5",
//     "createdAt": "2020-07-22T11:01:33.475Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Bulgarian Lev",
//     "code": "bgn",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": null,
//     "maxAmount": 65000,
//     "minAmount": 150,
//     "minBuyAmount": 200,
//     "maxBuyAmount": 65000,
//     "id": "7d189686-5b3a-4bee-aef1-725e9826ce66",
//     "createdAt": "2020-10-21T10:59:01.854Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Brazilian Real",
//     "code": "brl",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 8,
//     "maxAmount": 0.2,
//     "minAmount": 0.001,
//     "minBuyAmount": 0.001,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 1,
//     "minSellAmount": 0.0015,
//     "maxSellAmount": 0.6,
//     "id": "a18a8d0b-502c-48b9-ab6b-e2638fba5862",
//     "createdAt": "2018-09-28T10:47:49.801Z",
//     "updatedAt": "2023-11-20T13:53:57.155Z",
//     "type": "crypto",
//     "name": "Bitcoin",
//     "code": "btc",
//     "precision": 5,
//     "isSellSupported": true,
//     "addressRegex": "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$",
//     "testnetAddressRegex": "^(tb1|[2mn])[a-zA-HJ-NP-Z0-9]{25,39}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "0",
//       "chainId": null,
//       "networkCode": "bitcoin"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 16000,
//     "minAmount": 50,
//     "minBuyAmount": 50,
//     "maxBuyAmount": 16000,
//     "id": "9efa0420-9f96-4b9c-a9ce-dee8e267ddcf",
//     "createdAt": "2020-03-14T11:51:49.199Z",
//     "updatedAt": "2023-09-25T13:26:56.639Z",
//     "type": "fiat",
//     "name": "Canadian Dollar",
//     "code": "cad",
//     "precision": 2,
//     "isSellSupported": false
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 20,
//     "maxSellAmount": 2000,
//     "id": "ef3f48ee-120e-4b74-b1a3-086cb4230184",
//     "createdAt": "2020-07-21T08:00:36.476Z",
//     "updatedAt": "2023-11-20T13:53:57.160Z",
//     "type": "crypto",
//     "name": "Celo",
//     "code": "celo",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000000000000000000000000000000000",
//       "coinType": null,
//       "chainId": "42220",
//       "networkCode": "celo"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 11000,
//     "minAmount": 30,
//     "minBuyAmount": 30,
//     "maxBuyAmount": 11000,
//     "id": "8508c57b-2720-4cb9-8d9f-2853ab52a091",
//     "createdAt": "2020-04-22T16:15:05.763Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Swiss Franc",
//     "code": "chf",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": null,
//     "maxAmount": 42500000,
//     "minAmount": 130000,
//     "minBuyAmount": 130000,
//     "maxBuyAmount": 42500000,
//     "id": "2b7d4064-75fa-43c0-8fac-de57ea2f6980",
//     "createdAt": "2020-07-22T11:12:31.219Z",
//     "updatedAt": "2023-04-21T15:36:42.244Z",
//     "type": "fiat",
//     "name": "Colombian Peso",
//     "code": "cop",
//     "precision": 0,
//     "isSellSupported": false
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "48e69e1a-2466-4218-b2cd-1e6164839595",
//     "createdAt": "2020-12-22T14:42:48.054Z",
//     "updatedAt": "2023-12-06T16:43:55.858Z",
//     "type": "crypto",
//     "name": "Celo Dollar",
//     "code": "cusd",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x765de816845861e75a25fca122bb6898b8b1282a",
//       "coinType": null,
//       "chainId": "42220",
//       "networkCode": "celo"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 260000,
//     "minAmount": 750,
//     "minBuyAmount": 750,
//     "maxBuyAmount": 260000,
//     "id": "a865a512-0f4a-4147-9de2-4ed87717156c",
//     "createdAt": "2020-07-22T11:12:41.131Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Czech Koruna",
//     "code": "czk",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 2000,
//     "minAmount": 212,
//     "minBuyAmount": 16.5,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": 12,
//     "minSellAmount": 212,
//     "maxSellAmount": 2000,
//     "id": "0c9480ef-2fab-4d31-9037-da4b29ecc16f",
//     "createdAt": "2019-07-01T11:54:35.746Z",
//     "updatedAt": "2023-12-06T13:36:12.678Z",
//     "type": "crypto",
//     "name": "Dai",
//     "code": "dai",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x6b175474e89094c44da98b954eedeac495271d0f",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "bc0dc8aa-715c-4cdc-a6dd-d0acc35640c5",
//     "createdAt": "2022-04-25T12:11:25.605Z",
//     "updatedAt": "2023-12-06T13:36:12.664Z",
//     "type": "crypto",
//     "name": "DAI (palm)",
//     "code": "dai_palm",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x4C1f6fCBd233241bF2f4D02811E3bF8429BC27B8",
//       "coinType": null,
//       "chainId": "11297108109",
//       "networkCode": "palm"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "5435c1df-450c-4e62-b18b-a5a1a2e1a6b7",
//     "createdAt": "2021-12-21T10:12:28.463Z",
//     "updatedAt": "2023-12-06T13:36:12.667Z",
//     "type": "crypto",
//     "name": "Dai (zkSync)",
//     "code": "dai_zksync",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "(zksync:)?(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "(zksync:)?(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "zksync"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 75000,
//     "minAmount": 220,
//     "minBuyAmount": 220,
//     "maxBuyAmount": 75000,
//     "id": "b3e4e8f1-42b3-405b-994a-2bd1bd22786b",
//     "createdAt": "2020-07-22T11:24:18.502Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Danish Krone",
//     "code": "dkk",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 8,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 88,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 20,
//     "maxSellAmount": 2000,
//     "id": "af950192-8e01-4a84-9807-27f16d88450e",
//     "createdAt": "2020-07-17T11:54:33.126Z",
//     "updatedAt": "2023-11-20T13:53:57.153Z",
//     "type": "crypto",
//     "name": "Dogecoin",
//     "code": "doge",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^(D|A|9)[a-km-zA-HJ-NP-Z1-9]{33,34}$",
//     "testnetAddressRegex": "^n{1}[a-km-zA-HJ-NP-Z1-9]{33,34}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "3",
//       "chainId": null,
//       "networkCode": "dogecoin"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 680000,
//     "minAmount": 2000,
//     "minBuyAmount": 2000,
//     "maxBuyAmount": 680000,
//     "id": "1c6c3ff1-a796-4724-ae4c-449d1ef1897d",
//     "createdAt": "2020-07-22T11:24:31.795Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Dominican Peso",
//     "code": "dop",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 10,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 1.65,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 5.54119,
//     "maxSellAmount": null,
//     "id": "83e44c86-d0d8-4896-a3f7-49dabe3fd635",
//     "createdAt": "2020-09-08T13:54:40.621Z",
//     "updatedAt": "2023-11-22T22:04:00.717Z",
//     "type": "crypto",
//     "name": "Polkadot",
//     "code": "dot",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(1)[0-9a-z-A-Z]{44,50}$",
//     "testnetAddressRegex": "^(1)[0-9a-z-A-Z]{44,50}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "polkadot"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.011,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 0.84986,
//     "maxSellAmount": null,
//     "id": "66f59d2c-abf0-4efa-946d-de10b8b6be14",
//     "createdAt": "2020-09-09T17:28:29.239Z",
//     "updatedAt": "2023-11-20T13:53:57.159Z",
//     "type": "crypto",
//     "name": "MultiversX (Elrond)",
//     "code": "egld",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(erd1)[0-9a-z]{58}$",
//     "testnetAddressRegex": "^(erd1)[0-9a-z]{58}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "multiversx"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 185000,
//     "minAmount": 550,
//     "minBuyAmount": 550,
//     "maxBuyAmount": 185000,
//     "id": "bdbcdf09-9896-49ca-bd5c-d1fb33f371bd",
//     "createdAt": "2020-07-22T11:24:37.545Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Egyptian Pound",
//     "code": "egp",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 4,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 0.22,
//     "maxBuyAmount": null,
//     "addressTagRegex": "^[0-9A-Za-z\\\\-_,]{1,120}$",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 20,
//     "maxSellAmount": 2000,
//     "id": "9fdb4e4e-feda-4a81-8b26-1401b6c2f4de",
//     "createdAt": "2019-02-23T22:03:45.797Z",
//     "updatedAt": "2023-12-11T09:30:01.310Z",
//     "type": "crypto",
//     "name": "EOS",
//     "code": "eos",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^[1-5a-z\\\\.]{1,12}$",
//     "testnetAddressRegex": "^[1-5a-z\\\\.]{1,12}$",
//     "supportsAddressTag": true,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "eosio"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 3,
//     "minAmount": 0.01,
//     "minBuyAmount": 0.01078,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 12,
//     "minSellAmount": 0.01,
//     "maxSellAmount": 8.5,
//     "id": "8d305f63-1fd7-4e01-a220-8445e591aec4",
//     "createdAt": "2018-09-28T10:47:49.801Z",
//     "updatedAt": "2023-11-20T13:53:57.177Z",
//     "type": "crypto",
//     "name": "Ethereum",
//     "code": "eth",
//     "precision": 4,
//     "isSellSupported": true,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000000000000000000000000000000000",
//       "coinType": "60",
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.00088,
//     "maxBuyAmount": 9999999,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 5,
//     "minSellAmount": 0.01,
//     "maxSellAmount": 1.6,
//     "id": "3fc99da4-d849-4641-a488-344d60c25a02",
//     "createdAt": "2022-09-21T11:00:49.055Z",
//     "updatedAt": "2023-11-20T13:53:57.182Z",
//     "type": "crypto",
//     "name": "Ethereum (Arbitrum)",
//     "code": "eth_arbitrum",
//     "precision": 4,
//     "isSellSupported": true,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000000000000000000000000000000000",
//       "coinType": null,
//       "chainId": "42161",
//       "networkCode": "arbitrum"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.0011,
//     "maxBuyAmount": 1000000000,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": null,
//     "maxSellAmount": null,
//     "id": "cdc83701-1799-4556-ae9a-da3266621101",
//     "createdAt": "2023-10-19T14:50:10.635Z",
//     "updatedAt": "2023-11-20T13:53:57.150Z",
//     "type": "crypto",
//     "name": "Ethereum (Base)",
//     "code": "eth_base",
//     "precision": 4,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000000000000000000000000000000000",
//       "coinType": "60",
//       "chainId": "8453",
//       "networkCode": "base"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.0011,
//     "maxBuyAmount": 1000000000,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 0.01,
//     "maxSellAmount": 1000000000,
//     "id": "621e5943-811c-4de0-b3b1-ef5340d9fcdf",
//     "createdAt": "2022-09-22T09:09:02.191Z",
//     "updatedAt": "2023-11-20T13:53:57.166Z",
//     "type": "crypto",
//     "name": "Ethereum (Optimism)",
//     "code": "eth_optimism",
//     "precision": 4,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000000000000000000000000000000000",
//       "coinType": null,
//       "chainId": "10",
//       "networkCode": "optimism"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.005,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 12,
//     "minSellAmount": 0.01,
//     "maxSellAmount": 8.5,
//     "id": "e149d940-85e2-4909-8d44-31a1c91b7a6f",
//     "createdAt": "2021-07-01T15:00:51.879Z",
//     "updatedAt": "2023-11-20T13:53:57.179Z",
//     "type": "crypto",
//     "name": "Ethereum (Polygon)",
//     "code": "eth_polygon",
//     "precision": 4,
//     "isSellSupported": true,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
//       "coinType": "966",
//       "chainId": "137",
//       "networkCode": "polygon"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 0.01562,
//     "maxSellAmount": null,
//     "id": "bd209358-ca9a-4977-afb9-8effc8c03b52",
//     "createdAt": "2021-12-21T10:14:43.483Z",
//     "updatedAt": "2023-11-24T09:19:48.853Z",
//     "type": "crypto",
//     "name": "Ethereum (zkSync)",
//     "code": "eth_zksync",
//     "precision": 4,
//     "isSellSupported": false,
//     "addressRegex": "(zksync:)?(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "(zksync:)?(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "zksync"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 10000,
//     "minAmount": 30,
//     "minBuyAmount": 30,
//     "maxBuyAmount": 10000,
//     "id": "71435a8d-211c-4664-a59e-2a5361a6c5a7",
//     "createdAt": "2019-04-22T15:12:07.861Z",
//     "updatedAt": "2023-11-03T15:05:44.162Z",
//     "type": "fiat",
//     "name": "Euro",
//     "code": "eur",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 8,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 46.08295,
//     "maxSellAmount": null,
//     "id": "f1f1ec81-0ab4-4415-a510-14ece659b089",
//     "createdAt": "2021-03-03T13:16:50.326Z",
//     "updatedAt": "2023-11-20T13:53:57.173Z",
//     "type": "crypto",
//     "name": "Flow",
//     "code": "flow",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{16}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{16}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "flow"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 9000,
//     "minAmount": 30,
//     "minBuyAmount": 30,
//     "maxBuyAmount": 9000,
//     "id": "6f424585-8936-4eb1-b01e-443fb306d1f5",
//     "createdAt": "2019-04-30T16:23:52.178Z",
//     "updatedAt": "2023-11-03T15:05:44.148Z",
//     "type": "fiat",
//     "name": "Pound Sterling",
//     "code": "gbp",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 680,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": 680,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 178.99761,
//     "maxSellAmount": null,
//     "id": "0f1c8fbd-c08b-4536-aa75-7f566a174374",
//     "createdAt": "2022-03-23T14:11:49.139Z",
//     "updatedAt": "2023-11-20T13:53:57.175Z",
//     "type": "crypto",
//     "name": "Gods Unchained (Immutable)",
//     "code": "gods_immutable",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "immutable"
//     }
//   },
//   {
//     "decimals": 8,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 2.2,
//     "maxBuyAmount": null,
//     "addressTagRegex": "^[a-zA-Z0-9\\\\s\\\\.]+$",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 20,
//     "maxSellAmount": 2000,
//     "id": "c58a008b-2f93-4faf-b6d3-83e004d23d4a",
//     "createdAt": "2020-03-04T16:29:59.149Z",
//     "updatedAt": "2023-11-20T13:53:57.163Z",
//     "type": "crypto",
//     "name": "Hedera Hashgraph",
//     "code": "hbar",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^0[.]0[.]\\d{1,7}$",
//     "testnetAddressRegex": "^0[.]0[.]\\d{1,7}$",
//     "supportsAddressTag": true,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "hedera"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 90000,
//     "minAmount": 280,
//     "minBuyAmount": 280,
//     "maxBuyAmount": 90000,
//     "id": "a080db62-29b4-44d2-ad40-7fe037f72e5e",
//     "createdAt": "2020-07-22T11:24:45.564Z",
//     "updatedAt": "2022-08-16T13:42:26.618Z",
//     "type": "fiat",
//     "name": "Hong Kong Dollar",
//     "code": "hkd",
//     "precision": 2,
//     "isSellSupported": false
//   },
//   {
//     "decimals": null,
//     "maxAmount": 170000000,
//     "minAmount": 520000,
//     "minBuyAmount": 520000,
//     "maxBuyAmount": 170000000,
//     "id": "a10ed157-1b7e-47ad-a3c9-fe6ae1ffff08",
//     "createdAt": "2020-07-22T11:25:00.354Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Indonesian Rupiah",
//     "code": "idr",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": null,
//     "maxAmount": 40000,
//     "minAmount": 120,
//     "minBuyAmount": 120,
//     "maxBuyAmount": 40000,
//     "id": "9a15ddd9-eed5-42b3-aae3-7589ee736985",
//     "createdAt": "2020-04-22T16:06:39.532Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Israeli New Shekel",
//     "code": "ils",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 12.1,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 39.98401,
//     "maxSellAmount": null,
//     "id": "35d2dea8-c349-4024-8e79-8faf77c2668a",
//     "createdAt": "2022-01-21T16:23:20.073Z",
//     "updatedAt": "2023-12-11T01:01:00.797Z",
//     "type": "crypto",
//     "name": "Immutable X (ERC-20)",
//     "code": "imx",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 400,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": 400,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 39.98401,
//     "maxSellAmount": null,
//     "id": "3fbed4b1-54b7-4742-be23-62be67a4a6da",
//     "createdAt": "2022-03-23T14:11:14.404Z",
//     "updatedAt": "2023-11-20T13:53:57.179Z",
//     "type": "crypto",
//     "name": "Immutable X (Immutable)",
//     "code": "imx_immutable",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "immutable"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 8000,
//     "minAmount": 25,
//     "minBuyAmount": 25,
//     "maxBuyAmount": 8000,
//     "id": "15173b02-e6d6-4776-86d7-05fb16c563e0",
//     "createdAt": "2020-08-13T17:07:26.436Z",
//     "updatedAt": "2023-03-01T14:38:43.122Z",
//     "type": "fiat",
//     "name": "Jordanian Dinar",
//     "code": "jod",
//     "precision": 3,
//     "isSellSupported": false
//   },
//   {
//     "decimals": null,
//     "maxAmount": 1250000,
//     "minAmount": 4000,
//     "minBuyAmount": 4000,
//     "maxBuyAmount": 1250000,
//     "id": "33c920d1-bdf0-42f6-aae1-397b92a459c5",
//     "createdAt": "2020-07-22T11:31:31.215Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Kenyan Shilling",
//     "code": "kes",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": null,
//     "maxAmount": 3500,
//     "minAmount": 10,
//     "minBuyAmount": 10,
//     "maxBuyAmount": 3500,
//     "id": "9e656712-f1b2-4ae6-bb94-724e0384b288",
//     "createdAt": "2020-08-13T17:11:29.045Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Kuwaiti Dinar",
//     "code": "kwd",
//     "precision": 3,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 2000,
//     "minAmount": 212,
//     "minBuyAmount": 1.43,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 212,
//     "maxSellAmount": 2000,
//     "id": "d5a202d2-fb36-49bb-93d5-2c2e5d6d000f",
//     "createdAt": "2020-08-17T12:14:45.856Z",
//     "updatedAt": "2023-12-11T01:01:00.801Z",
//     "type": "crypto",
//     "name": "Chainlink",
//     "code": "link",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x514910771af9ca656af840dff83e8264ecf986ca",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 2150000,
//     "minAmount": 7000,
//     "minBuyAmount": 7000,
//     "maxBuyAmount": 2150000,
//     "id": "83b57eca-5e25-4bd6-89bd-e4bada573bdb",
//     "createdAt": "2020-07-22T11:25:21.253Z",
//     "updatedAt": "2023-03-01T14:38:42.789Z",
//     "type": "fiat",
//     "name": "Sri Lankan Rupee",
//     "code": "lkr",
//     "precision": 2,
//     "isSellSupported": false
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.99,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 506.75676,
//     "maxSellAmount": null,
//     "id": "67b1c213-25d8-45fc-800f-75acb9e9379d",
//     "createdAt": "2023-01-18T16:06:22.295Z",
//     "updatedAt": "2023-11-20T13:53:57.175Z",
//     "type": "crypto",
//     "name": "LooksRare",
//     "code": "looks",
//     "precision": 4,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xf4d2888d29D722226FafA5d9B24F9164c092421E",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 8,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 0.0022,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 6,
//     "minSellAmount": 0.25,
//     "maxSellAmount": 20,
//     "id": "84bf7e0f-0a16-4486-8ba7-e80edcf5331e",
//     "createdAt": "2019-04-10T15:54:48.989Z",
//     "updatedAt": "2023-11-20T13:53:57.146Z",
//     "type": "crypto",
//     "name": "Litecoin",
//     "code": "ltc",
//     "precision": 3,
//     "isSellSupported": true,
//     "addressRegex": "^(L|M|3)[A-Za-z0-9]{33}$|^(ltc1)[0-9A-Za-z]{39}$",
//     "testnetAddressRegex": "^(L|M|3)[A-Za-z0-9]{33}$|^(tltc1)[0-9A-Za-z]{39}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "2",
//       "chainId": null,
//       "networkCode": "litecoin"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 1,
//     "maxBuyAmount": 5371386,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 37.07824,
//     "maxSellAmount": null,
//     "id": "4a60cd9a-ca4c-4701-8d0a-9b1e917cbffc",
//     "createdAt": "2022-09-19T08:58:21.161Z",
//     "updatedAt": "2023-11-20T13:53:57.180Z",
//     "type": "crypto",
//     "name": "Magic (Arbitrum)",
//     "code": "magic_arbitrum",
//     "precision": 3,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x539bdE0d7Dbd336b79148AA742883198BBF60342",
//       "coinType": null,
//       "chainId": "42161",
//       "networkCode": "arbitrum"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 12,
//     "minSellAmount": 38.00355,
//     "maxSellAmount": 18000,
//     "id": "34c3ed5f-0f60-497d-b31a-04065047aa2c",
//     "createdAt": "2021-07-01T15:26:23.848Z",
//     "updatedAt": "2023-12-01T08:50:00.755Z",
//     "type": "crypto",
//     "name": "Polygon (Polygon)",
//     "code": "matic_polygon",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000000000000000000000000000001010",
//       "coinType": "966",
//       "chainId": "137",
//       "networkCode": "polygon"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 260000,
//     "minAmount": 700,
//     "minBuyAmount": 700,
//     "maxBuyAmount": 260000,
//     "id": "6e703a57-0edb-49cc-84bc-c4d60a650b63",
//     "createdAt": "2020-07-22T11:25:43.990Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Mexican Peso",
//     "code": "mxn",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 24,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.22,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 19.06335,
//     "maxSellAmount": null,
//     "id": "7bbb6187-53ef-4a89-a1a2-e10ccc1d64ca",
//     "createdAt": "2020-11-19T11:11:15.221Z",
//     "updatedAt": "2023-12-01T12:20:01.657Z",
//     "type": "crypto",
//     "name": "NEAR Protocol",
//     "code": "near",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^[a-z0-9_-]{1}[a-z0-9_.-]{0,62}[a-z0-9_-]{1}$",
//     "testnetAddressRegex": "^[a-z0-9_-]{1}[a-z0-9_.-]{0,62}[a-z0-9_-]{1}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "near"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 4500000,
//     "minAmount": 15000,
//     "minBuyAmount": 15000,
//     "maxBuyAmount": 4500000,
//     "id": "239495d6-1cb3-4fd0-bbe2-d3f15bf5f2ad",
//     "createdAt": "2020-07-22T11:25:59.323Z",
//     "updatedAt": "2022-08-16T13:42:26.618Z",
//     "type": "fiat",
//     "name": "Nigerian Naira",
//     "code": "ngn",
//     "precision": 2,
//     "isSellSupported": false
//   },
//   {
//     "decimals": null,
//     "maxAmount": 110000,
//     "minAmount": 300,
//     "minBuyAmount": 300,
//     "maxBuyAmount": 110000,
//     "id": "82327c66-0f98-45fc-a212-b5bd709f9cc6",
//     "createdAt": "2020-04-22T16:12:52.173Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Norwegian Krone",
//     "code": "nok",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": null,
//     "maxAmount": 18000,
//     "minAmount": 50,
//     "minBuyAmount": 50,
//     "maxBuyAmount": 18000,
//     "id": "1c9beb30-cabb-40d5-aa28-f2ae703a42c0",
//     "createdAt": "2020-07-22T11:26:08.063Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "New Zealand Dollar",
//     "code": "nzd",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 2000,
//     "minAmount": 212,
//     "minBuyAmount": 30.8,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 212,
//     "maxSellAmount": 2000,
//     "id": "132caa7a-0c8f-4f16-b45d-df7b3d32f4f1",
//     "createdAt": "2020-08-17T12:16:25.431Z",
//     "updatedAt": "2023-12-09T01:01:00.769Z",
//     "type": "crypto",
//     "name": "OMG Network",
//     "code": "omg",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xd26114cd6ee289accf82350c8d8487fedb8a0c07",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 4300,
//     "minAmount": 15,
//     "minBuyAmount": 15,
//     "maxBuyAmount": 4300,
//     "id": "b8152935-cfeb-4e64-847e-2c29d888ebc9",
//     "createdAt": "2020-08-13T17:13:21.484Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Omani Rial",
//     "code": "omr",
//     "precision": 3,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 8,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 24.2,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 45.60657,
//     "maxSellAmount": null,
//     "id": "f5126e40-42ab-4de2-abd7-c35610860fe0",
//     "createdAt": "2021-12-20T13:01:58.170Z",
//     "updatedAt": "2023-12-11T01:01:00.805Z",
//     "type": "crypto",
//     "name": "Orion (ERC-20)",
//     "code": "orn",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 40000,
//     "minAmount": 130,
//     "minBuyAmount": 130,
//     "maxBuyAmount": 40000,
//     "id": "52d550cd-13eb-4a1c-b279-b68268c528a0",
//     "createdAt": "2020-07-22T11:26:12.738Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Peruvian Sol",
//     "code": "pen",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": null,
//     "maxAmount": 45000,
//     "minAmount": 140,
//     "minBuyAmount": 140,
//     "maxBuyAmount": 45000,
//     "id": "18dfd358-db55-43de-8660-826413bbc5ab",
//     "createdAt": "2020-04-22T16:13:11.842Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Polish Złoty",
//     "code": "pln",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": null,
//     "maxAmount": 48000,
//     "minAmount": 150,
//     "minBuyAmount": 150,
//     "maxBuyAmount": 48000,
//     "id": "1fecf3b1-7743-4b2e-89a7-7e32e6c64d13",
//     "createdAt": "2020-07-22T11:26:27.677Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Romanian Leu",
//     "code": "ron",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 42.43281,
//     "maxSellAmount": null,
//     "id": "7b9d3f1f-10ac-480c-b1eb-621438edbe58",
//     "createdAt": "2023-05-03T14:32:43.587Z",
//     "updatedAt": "2023-11-20T12:29:51.999Z",
//     "type": "crypto",
//     "name": "Ronin",
//     "code": "ronin",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^ronin:[a-fA-F0-9]{40}$|^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^ronin:[a-fA-F0-9]{40}$|^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000000000000000000000000000000000",
//       "coinType": null,
//       "chainId": "ronin",
//       "networkCode": "ronin"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 1.122,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 66.0066,
//     "maxSellAmount": null,
//     "id": "7b14e8de-b142-4fb1-ad4e-9a9609932efd",
//     "createdAt": "2022-04-27T09:53:01.421Z",
//     "updatedAt": "2023-12-11T01:01:00.812Z",
//     "type": "crypto",
//     "name": "The Sandbox (Polygon)",
//     "code": "sand_polygon",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
//       "coinType": null,
//       "chainId": "137",
//       "networkCode": "polygon"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 105000,
//     "minAmount": 300,
//     "minBuyAmount": 300,
//     "maxBuyAmount": 105000,
//     "id": "56ce4136-1519-469d-90e0-4c9cc9ddbe98",
//     "createdAt": "2020-04-22T16:13:47.028Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Swedish Krona",
//     "code": "sek",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 2262253.4,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 3821656.05096,
//     "maxSellAmount": 1300000000,
//     "id": "7f4d75df-0059-4950-ab31-fb2b83a4b23b",
//     "createdAt": "2021-10-27T20:08:12.206Z",
//     "updatedAt": "2023-12-11T01:01:00.814Z",
//     "type": "crypto",
//     "name": "Shiba Inu (ERC-20)",
//     "code": "shib",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 0,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 7020.2,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 17751.47929,
//     "maxSellAmount": null,
//     "id": "ed35ab11-5b56-40fc-9b7d-ef9b74565e1e",
//     "createdAt": "2023-05-03T14:53:53.408Z",
//     "updatedAt": "2023-12-11T01:01:00.815Z",
//     "type": "crypto",
//     "name": "Smooth Love Potion (ERC-20)",
//     "code": "slp",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25",
//       "coinType": "60",
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 169.4,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 17751.47929,
//     "maxSellAmount": null,
//     "id": "423aed48-cccf-4303-b646-e30784e0f834",
//     "createdAt": "2023-05-03T16:38:40.125Z",
//     "updatedAt": "2023-11-20T12:29:51.993Z",
//     "type": "crypto",
//     "name": "Smooth Love Potion (Ronin)",
//     "code": "slp_ronin",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^ronin:[a-fA-F0-9]{40}$|^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^ronin:[a-fA-F0-9]{40}$|^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "ronin:a8754b9fa15fc18bb59458815510e40a12cd2014",
//       "coinType": "",
//       "chainId": "ronin",
//       "networkCode": "ronin"
//     }
//   },
//   {
//     "decimals": 9,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 0.33,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 1,
//     "minSellAmount": 1.5,
//     "maxSellAmount": 100,
//     "id": "df3cc2d2-6ea5-4b61-9309-5923c0767a54",
//     "createdAt": "2020-07-17T11:55:16.350Z",
//     "updatedAt": "2023-11-20T13:53:57.175Z",
//     "type": "crypto",
//     "name": "Solana",
//     "code": "sol",
//     "precision": 2,
//     "isSellSupported": true,
//     "addressRegex": "^[1-9A-HJ-NP-Za-km-z]{32,44}$",
//     "testnetAddressRegex": "^[1-9A-HJ-NP-Za-km-z]{32,44}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "501",
//       "chainId": null,
//       "networkCode": "solana"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 5.5,
//     "maxBuyAmount": null,
//     "addressTagRegex": "^[0-9a-zA-Z]{0,20}$",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 47.19207,
//     "maxSellAmount": null,
//     "id": "5d2b8469-54f4-43bd-ba08-2a0f06b17c67",
//     "createdAt": "2022-03-17T13:46:04.923Z",
//     "updatedAt": "2023-11-20T13:53:57.167Z",
//     "type": "crypto",
//     "name": "Stacks",
//     "code": "stx",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^S[PM][0-9A-Z]{38,39}$",
//     "testnetAddressRegex": "^S[PM][0-9A-Z]{38,39}$",
//     "supportsAddressTag": true,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "stacks"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 370000,
//     "minAmount": 1200,
//     "minBuyAmount": 1200,
//     "maxBuyAmount": 370000,
//     "id": "b0eaa8d9-b197-4be1-8703-c0d42919bf57",
//     "createdAt": "2020-07-22T11:27:28.972Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Thai Baht",
//     "code": "thb",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": null,
//     "maxAmount": 80000,
//     "minAmount": 400,
//     "minBuyAmount": 400,
//     "maxBuyAmount": 80000,
//     "id": "d3c98192-a872-4958-bc91-7a8434acc1d1",
//     "createdAt": "2020-07-22T11:27:34.419Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "Turkish Lira",
//     "code": "try",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 2000,
//     "minAmount": 212,
//     "minBuyAmount": 33,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 212,
//     "maxSellAmount": 2000,
//     "id": "6e1400ae-8aa0-42be-9b4a-fb661aaf649c",
//     "createdAt": "2019-05-31T09:53:17.499Z",
//     "updatedAt": "2023-12-06T13:36:12.702Z",
//     "type": "crypto",
//     "name": "TrueUSD",
//     "code": "tusd",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x0000000000085d4780B73119b644AE5ecd22b376",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 340000,
//     "minAmount": 1000,
//     "minBuyAmount": 1000,
//     "maxBuyAmount": 340000,
//     "id": "15618cb8-74ff-4af5-88cf-13c88271bbf3",
//     "createdAt": "2020-07-22T11:27:41.600Z",
//     "updatedAt": "2022-08-16T13:42:26.618Z",
//     "type": "fiat",
//     "name": "Taiwan Dollar",
//     "code": "twd",
//     "precision": 2,
//     "isSellSupported": false
//   },
//   {
//     "decimals": null,
//     "maxAmount": 12000,
//     "minAmount": 30,
//     "minBuyAmount": 30,
//     "maxBuyAmount": 12000,
//     "id": "edd81f1f-f735-4692-b410-6def107f17d2",
//     "createdAt": "2019-04-29T16:55:28.647Z",
//     "updatedAt": "2023-10-24T11:03:37.104Z",
//     "type": "fiat",
//     "name": "US Dollar",
//     "code": "usd",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 6,
//     "maxAmount": 10000,
//     "minAmount": 30,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": 11,
//     "minSellAmount": 15,
//     "maxSellAmount": 10000,
//     "id": "aaefa32f-161b-42c8-8115-debcbf3d6a2d",
//     "createdAt": "2019-05-17T18:24:45.206Z",
//     "updatedAt": "2023-12-06T13:36:12.719Z",
//     "type": "crypto",
//     "name": "USD Coin (ERC-20)",
//     "code": "usdc",
//     "precision": 2,
//     "isSellSupported": true,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
//       "coinType": "60",
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 30,
//     "maxBuyAmount": 10000,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": 10000,
//     "id": "0bf8d975-267f-4ccf-b00b-6b7b16842009",
//     "createdAt": "2022-11-04T14:28:09.267Z",
//     "updatedAt": "2023-12-06T13:36:12.704Z",
//     "type": "crypto",
//     "name": "USD Coin (Aptos)",
//     "code": "usdc_aptos",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Za-z]{64}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Za-z]{64}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": true,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "aptos"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 30,
//     "maxBuyAmount": 10000,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": 9995,
//     "id": "f21fd44e-e881-4eb3-9b3a-24ef5af03193",
//     "createdAt": "2022-11-10T17:07:52.679Z",
//     "updatedAt": "2023-12-06T13:36:12.700Z",
//     "type": "crypto",
//     "name": "USD Coin (Arbitrum)",
//     "code": "usdc_arbitrum",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
//       "coinType": null,
//       "chainId": "42161",
//       "networkCode": "arbitrum"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": null,
//     "maxSellAmount": null,
//     "id": "6bfe9894-3fd6-409c-ab70-a70506207601",
//     "createdAt": "2023-10-19T14:47:43.967Z",
//     "updatedAt": "2023-12-06T13:36:12.656Z",
//     "type": "crypto",
//     "name": "USD Coin (Base)",
//     "code": "usdc_base",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
//       "coinType": null,
//       "chainId": "8453",
//       "networkCode": "base"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 55,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "de47a930-f76e-4284-b9d6-c5a6f2a1fc3f",
//     "createdAt": "2023-05-15T10:49:59.996Z",
//     "updatedAt": "2023-12-06T13:36:12.709Z",
//     "type": "crypto",
//     "name": "USD Coin (Avalanche C-Chain)",
//     "code": "usdc_cchain",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
//       "coinType": null,
//       "chainId": "43114",
//       "networkCode": "avalanche_c_chain"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "eba454fb-24fe-4adf-8a6c-a3e0c66f576b",
//     "createdAt": "2022-03-16T09:49:47.011Z",
//     "updatedAt": "2023-12-06T13:36:12.727Z",
//     "type": "crypto",
//     "name": "USD Coin (Flow)",
//     "code": "usdc_flow",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{16}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{16}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "flow"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 30,
//     "maxBuyAmount": 10000,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 15,
//     "maxSellAmount": 10000,
//     "id": "ed457774-d742-46cb-80c5-f2ae19e1da58",
//     "createdAt": "2022-11-11T10:58:35.514Z",
//     "updatedAt": "2023-12-06T13:36:12.694Z",
//     "type": "crypto",
//     "name": "USD Coin (Optimism)",
//     "code": "usdc_optimism",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
//       "coinType": null,
//       "chainId": "10",
//       "networkCode": "optimism"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": 12,
//     "minSellAmount": 15,
//     "maxSellAmount": 10000,
//     "id": "1501ea35-9c89-4c1e-ae57-4a19d5a635db",
//     "createdAt": "2021-07-01T15:27:08.689Z",
//     "updatedAt": "2023-12-06T13:36:12.675Z",
//     "type": "crypto",
//     "name": "USD Coin (Polygon)",
//     "code": "usdc_polygon",
//     "precision": 2,
//     "isSellSupported": true,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
//       "coinType": "966",
//       "chainId": "137",
//       "networkCode": "polygon"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 55,
//     "maxBuyAmount": null,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "510a16cf-2a33-4bf8-949e-f02648198db5",
//     "createdAt": "2023-05-03T14:28:30.500Z",
//     "updatedAt": "2023-12-06T13:36:12.725Z",
//     "type": "crypto",
//     "name": "USD Coin (Ronin)",
//     "code": "usdc_ronin",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^ronin:[a-fA-F0-9]{40}$|^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^ronin:[a-fA-F0-9]{40}$|^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "ronin:0b7007c13325c48911f73a2dad5fa5dcbf808adc",
//       "coinType": null,
//       "chainId": "ronin",
//       "networkCode": "ronin"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 11,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "acb9589d-c7cf-41a8-b220-875f2867bd46",
//     "createdAt": "2022-01-04T17:08:50.653Z",
//     "updatedAt": "2023-12-10T17:40:01.699Z",
//     "type": "crypto",
//     "name": "USD Coin (Solana)",
//     "code": "usdc_sol",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^[0-9a-zA-Z]{32,44}$",
//     "testnetAddressRegex": "^[0-9a-zA-Z]{32,44}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "501",
//       "chainId": null,
//       "networkCode": "solana"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": 10000,
//     "minAmount": 30,
//     "minBuyAmount": 2.2,
//     "maxBuyAmount": null,
//     "addressTagRegex": "^[0-9A-Za-z-]{1,28}$",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": 10000,
//     "id": "31db99fa-f9ed-48a0-9f81-9b5a6fa82992",
//     "createdAt": "2022-05-24T14:10:14.009Z",
//     "updatedAt": "2023-12-06T13:36:12.696Z",
//     "type": "crypto",
//     "name": "USD Coin (Stellar)",
//     "code": "usdc_xlm",
//     "precision": 2,
//     "isSellSupported": true,
//     "addressRegex": "^G[A-D]{1}[A-Z2-7]{54}$",
//     "testnetAddressRegex": "^G[A-D]{1}[A-Z2-7]{54}$",
//     "supportsAddressTag": true,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN",
//       "coinType": "148",
//       "chainId": null,
//       "networkCode": "stellar"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "1101ea35-9c89-4c1e-ae57-4a19d5a635ef",
//     "createdAt": "2021-12-21T10:15:56.365Z",
//     "updatedAt": "2023-12-06T13:36:12.672Z",
//     "type": "crypto",
//     "name": "USD Coin (zkSync)",
//     "code": "usdc_zksync",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "(zksync:)?(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "(zksync:)?(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "zksync"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": 10000,
//     "minAmount": 30,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": 12,
//     "minSellAmount": 15,
//     "maxSellAmount": 10000,
//     "id": "3693a9c0-7b24-4e48-a8a7-75021ad6e26a",
//     "createdAt": "2019-07-04T08:37:10.847Z",
//     "updatedAt": "2023-12-06T13:36:12.721Z",
//     "type": "crypto",
//     "name": "Tether (ERC-20)",
//     "code": "usdt",
//     "precision": 2,
//     "isSellSupported": true,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xdac17f958d2ee523a2206206994597c13d831ec7",
//       "coinType": "60",
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 0.319,
//     "maxBuyAmount": 15000,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 5.5,
//     "maxSellAmount": 15000,
//     "id": "bb34e21c-8545-4b01-ad08-63cc6eab03b8",
//     "createdAt": "2023-10-18T10:46:38.246Z",
//     "updatedAt": "2023-12-07T10:40:02.183Z",
//     "type": "crypto",
//     "name": "Tether (BEP-20)",
//     "code": "usdt_bsc",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0x55d398326f99059ff775485246999027b3197955",
//       "coinType": "9006",
//       "chainId": "56",
//       "networkCode": "binance_smart_chain"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 5.5,
//     "maxBuyAmount": 15000,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": 15000,
//     "id": "41b793aa-1252-44a7-b79b-6789628fb864",
//     "createdAt": "2023-03-21T22:05:40.988Z",
//     "updatedAt": "2023-12-06T13:36:12.714Z",
//     "type": "crypto",
//     "name": "Tether (Polygon)",
//     "code": "usdt_polygon",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
//       "coinType": "966",
//       "chainId": "137",
//       "networkCode": "polygon"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 11,
//     "maxBuyAmount": 0,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": 1,
//     "minSellAmount": 15,
//     "maxSellAmount": 10000,
//     "id": "da65fff3-02e3-4f89-9df4-648097ed9573",
//     "createdAt": "2022-02-15T16:28:34.608Z",
//     "updatedAt": "2023-12-06T13:36:12.716Z",
//     "type": "crypto",
//     "name": "Tether (TRC-20)",
//     "code": "usdt_trx",
//     "precision": 2,
//     "isSellSupported": true,
//     "addressRegex": "^T[1-9A-HJ-NP-Za-km-z]{33}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
//       "coinType": "195",
//       "chainId": null,
//       "networkCode": "tron"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": null,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [
//       "CA"
//     ],
//     "confirmationsRequired": null,
//     "minSellAmount": 30,
//     "maxSellAmount": null,
//     "id": "3693a9c0-7b24-4e48-a8a7-75021ad6e26c",
//     "createdAt": "2021-12-21T10:17:02.375Z",
//     "updatedAt": "2023-12-06T13:36:12.711Z",
//     "type": "crypto",
//     "name": "Tether (zkSync)",
//     "code": "usdt_zksync",
//     "precision": 2,
//     "isSellSupported": false,
//     "addressRegex": "(zksync:)?(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "(zksync:)?(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "zksync"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 250000000,
//     "minAmount": 850000,
//     "minBuyAmount": 850000,
//     "maxBuyAmount": 250000000,
//     "id": "0c6284be-f5e0-4f27-9aae-ea6aa6bb42e2",
//     "createdAt": "2020-08-13T17:15:47.092Z",
//     "updatedAt": "2022-08-16T13:42:26.618Z",
//     "type": "fiat",
//     "name": "Vietnamese Dong",
//     "code": "vnd",
//     "precision": 0,
//     "isSellSupported": false
//   },
//   {
//     "decimals": 18,
//     "maxAmount": null,
//     "minAmount": null,
//     "minBuyAmount": 2.794,
//     "maxBuyAmount": 9999999,
//     "addressTagRegex": "",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 211.26761,
//     "maxSellAmount": null,
//     "id": "271589a1-6472-4614-a863-af56298b3d0c",
//     "createdAt": "2022-05-12T12:25:39.128Z",
//     "updatedAt": "2023-12-11T01:01:00.822Z",
//     "type": "crypto",
//     "name": "Voxies (Polygon)",
//     "code": "voxel_polygon",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
//       "coinType": null,
//       "chainId": "137",
//       "networkCode": "polygon"
//     }
//   },
//   {
//     "decimals": 8,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 7.7,
//     "maxBuyAmount": null,
//     "addressTagRegex": "^[0-9A-Za-z-_,.]{1,120}$",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 20,
//     "maxSellAmount": 2000,
//     "id": "3d6b67b6-744b-464e-b300-d447b010e456",
//     "createdAt": "2020-07-21T08:01:34.417Z",
//     "updatedAt": "2023-12-06T10:10:01.282Z",
//     "type": "crypto",
//     "name": "WAX",
//     "code": "waxp",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^[1-5a-z\\.]{1,12}$",
//     "testnetAddressRegex": "^[1-5a-z\\.]{1,12}$",
//     "supportsAddressTag": true,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "wax"
//     }
//   },
//   {
//     "decimals": 7,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 44,
//     "maxBuyAmount": null,
//     "addressTagRegex": "^[0-9A-Za-z]{1,28}$",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 1,
//     "minSellAmount": 200,
//     "maxSellAmount": 100000,
//     "id": "c8b1ef20-3703-4a66-9ea1-c13ce0d893bf",
//     "createdAt": "2019-04-10T15:58:46.394Z",
//     "updatedAt": "2023-11-20T13:53:57.174Z",
//     "type": "crypto",
//     "name": "Stellar",
//     "code": "xlm",
//     "precision": 0,
//     "isSellSupported": true,
//     "addressRegex": "^G[A-D]{1}[A-Z2-7]{54}$",
//     "testnetAddressRegex": "^G[A-D]{1}[A-Z2-7]{54}$",
//     "supportsAddressTag": true,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "148",
//       "chainId": null,
//       "networkCode": "stellar"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 16.5,
//     "maxBuyAmount": null,
//     "addressTagRegex": "^((?!0)[0-9]{1,19})$",
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": 1,
//     "minSellAmount": 70,
//     "maxSellAmount": 6600,
//     "id": "d64b5e09-3d1b-452d-ab28-478c4190de7d",
//     "createdAt": "2019-04-10T15:55:51.649Z",
//     "updatedAt": "2023-12-04T20:09:00.628Z",
//     "type": "crypto",
//     "name": "XRP",
//     "code": "xrp",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^r[1-9A-HJ-NP-Za-km-z]{25,34}$",
//     "testnetAddressRegex": "^r[1-9A-HJ-NP-Za-km-z]{25,34}$",
//     "supportsAddressTag": true,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": "144",
//       "chainId": null,
//       "networkCode": "ripple"
//     }
//   },
//   {
//     "decimals": 6,
//     "maxAmount": 2000,
//     "minAmount": 20,
//     "minBuyAmount": 1.1,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "TX",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 20,
//     "maxSellAmount": 2000,
//     "id": "dab98182-bcdf-4f6d-be4d-62d57c000089",
//     "createdAt": "2019-12-11T13:42:51.949Z",
//     "updatedAt": "2023-11-20T13:53:57.173Z",
//     "type": "crypto",
//     "name": "Tezos",
//     "code": "xtz",
//     "precision": 1,
//     "isSellSupported": false,
//     "addressRegex": "^(tz[1,2,3]|KT1)[a-zA-Z0-9]{33}$",
//     "testnetAddressRegex": "^(tz[1,2,3]|KT1)[a-zA-Z0-9]{33}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": false,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": null,
//       "coinType": null,
//       "chainId": null,
//       "networkCode": "tezos"
//     }
//   },
//   {
//     "decimals": null,
//     "maxAmount": 195000,
//     "minAmount": 550,
//     "minBuyAmount": 550,
//     "maxBuyAmount": 195000,
//     "id": "863cd8f3-e5d6-4ae8-b520-9cbb0e7713ad",
//     "createdAt": "2020-03-14T11:51:49.199Z",
//     "updatedAt": "2023-11-01T10:37:13.232Z",
//     "type": "fiat",
//     "name": "South African Rand",
//     "code": "zar",
//     "precision": 2,
//     "isSellSupported": true
//   },
//   {
//     "decimals": 18,
//     "maxAmount": 2000,
//     "minAmount": 212,
//     "minBuyAmount": 55,
//     "maxBuyAmount": null,
//     "addressTagRegex": null,
//     "notAllowedUSStates": [
//       "LA",
//       "NY",
//       "VI"
//     ],
//     "notAllowedCountries": [],
//     "confirmationsRequired": null,
//     "minSellAmount": 212,
//     "maxSellAmount": 2000,
//     "id": "9c244f90-6c08-4a77-ab27-8e1cfff2388e",
//     "createdAt": "2020-02-04T09:26:53.239Z",
//     "updatedAt": "2023-12-11T01:01:00.833Z",
//     "type": "crypto",
//     "name": "0x",
//     "code": "zrx",
//     "precision": 0,
//     "isSellSupported": false,
//     "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "testnetAddressRegex": "^(0x)[0-9A-Fa-f]{40}$",
//     "supportsAddressTag": false,
//     "supportsTestMode": true,
//     "supportsLiveMode": true,
//     "isSuspended": false,
//     "isSupportedInUS": true,
//     "metadata": {
//       "contractAddress": "0xe41d2489571d322189246dafa5ebde1f4699f498",
//       "coinType": null,
//       "chainId": "1",
//       "networkCode": "ethereum"
//     }
//   }
// ]
