import { useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Button,
  LinearProgress,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Radio, { RadioProps } from "@mui/material/Radio";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";

import {
  request as walletConnectPay,
  onWalletConnect,
  useWalletConnect,
} from "../features/WalletConnect/hook/useWalletConnect";
import {
  request as metamaskPay,
  onMetaMaskConnect,
} from "../features/MetaMask/hook/useMetaMask";
import { getDidAddress } from "../features/WalletConnect/utils/address";
import store from "../features/WalletConnect/store";
import { getRaisedFunds } from "../api/api";

import {
  NETWORK,
  METHOD,
  TOKEN,
  commitPayment,
  sleep,
} from "../utils/blockchain";
import {
  NONE,
  STEP_SELECT_NETWORK,
  STEP_PULLING_SETTLEMENT,
  STEP_PREPARE_SETTLEMENT,
  getWaitingMessage,
} from "utils";

const NetworkSelection = (props) => {
  const { funds, onChange } = props;

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props: RadioProps) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        border: "2px dashed #7129F5",
        padding: "6px",
        marginTop: "6px",
        marginBottom: "6px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontWeight: 600, margin: "auto" }}>
        Please select a network to settle
      </Typography>
      <RadioGroup
        row
        defaultValue="0"
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        sx={{ display: "flex", justifyContent: "center" }}
        onChange={onChange}
      >
        {funds.map((fund) => {
          console.log(fund);
          return (
            <FormControlLabel
              value={fund.network}
              control={<BpRadio />}
              label={`${fund.network} ( $${new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 9,
              }).format(fund.tokenCount / 1000000)} )`}
            />
          );
        })}
      </RadioGroup>
    </Box>
  );
};

const Settlement = (props) => {
  const state = useSnapshot(store);
  const connectUrl = state.uri;

  const { args, setStep, notify, resetBeneficiaryAmount, next, instruction } =
    props;

  const [funds, setFunds] = useState<
    [{ network: string; address: string; tokenCount: number }] | []
  >([]);
  const [selectedNetwork, setSelectedNetwork] = useState<string>();

  const waitingMessage = getWaitingMessage(0, "NA");
  const [waitingStep, setWaitingStep] = useState(STEP_PULLING_SETTLEMENT);

  const connect = async (session, args) => {
    if (!selectedNetwork) {
      return setWaitingStep(STEP_SELECT_NETWORK);
    }

    const { contract_address } = args || {};

    const { s: signature, p: payload, topic } = session;
    const from = getDidAddress(payload.iss);
    if (from) {
      let result;
      const selected = funds.find((x) => x && x.network === selectedNetwork);
      if (selected) {
        setWaitingStep(STEP_PREPARE_SETTLEMENT);
        let token: string;
        switch (selected.network) {
          case NETWORK.ethereum:
            token = TOKEN.USDC;
            break;
          case NETWORK.polygon:
            token = TOKEN.USDC_PG;
            break;
          default:
            token = TOKEN.USDC_ZK;
        }
        result = await commitPayment(
          topic === null ? metamaskPay : walletConnectPay,
          from,
          selected.address,
          selected.tokenCount,
          token,
          METHOD.settle
        );
        try {
          if (result && result.success === false) {
            return notify(result.message, "error");
          }

          console.log(`${from} is settling the campaign...`);

          if (!result || result.success === false) {
            setWaitingStep(NONE);
            return notify(result.message, "error");
          }

          if (result === true || result.success === true) {
            setStep(1);
            resetBeneficiaryAmount();
            return notify("Success", "success");
          }
        } catch (e: any) {
          return notify(e.response?.data?.message || e.message, "error");
        } finally {
          setWaitingStep(NONE);
        }
      }
    }
  };

  useWalletConnect((session, args) => {
    connect(session, args);
  });

  useEffect(() => {
    // const wallet = async () => {
    //   if (args.action === "donate") {
    //     setWaitingStep("STEP_CONNECT_WALLET");
    //   }
    //   const res = await onWalletConnect(args);
    //   if (res.success === false) {
    //     notify(res.message, "error");
    //   }
    //   console.log("wallet connect succesfully: ", res);
    // };
    // wallet();
    getRaisedFunds(args.contract_address).then((res) => {
      console.log(res);
      if (res.success === true) {
        setFunds(res.funds);
        setWaitingStep(NONE);
      }
    });
  }, [args]);

  const metamask = async () => {
    const from = await onMetaMaskConnect();
    if (from) {
      console.log("Metamask wallet: .", from);
      const iss = `${store.l2ChainId}:did:pkh:${from}`;

      connect(
        {
          p: {
            iss,
          },
          s: {},
          topic: null,
        },
        args
      );
    }
  };

  const onClick = async () => {
    notify("Copied to clipboard");
  };

  console.log("rereder............");

  return (
    <Grid container alignContent="center" alignItems="center">
      {/* <Grid
        sx={{
          width: 190,
          height: 190,
          padding: "12px",
          border: "1px solid",
          borderRadius: 3,
        }}
      >
        {connectUrl && (
          <CopyToClipboard text={connectUrl}>
            <QRCode size={166} value={connectUrl} onClick={onClick} />
          </CopyToClipboard>
        )}
      </Grid>
      <Grid sx={{ display: "flex" }}>
        <Typography style={{ color: "#1A1A1A" }}>
          Click the QR Code to copy
        </Typography>
        <span
          style={{
            backgroundColor: "#EFE7FE",
            borderRadius: 50,
            width: 146,
            height: 30,
            textAlign: "center",
            marginLeft: 4,
            marginRight: 4,
          }}
        >
          <img
            style={{ width: 21, height: 12 }}
            src="/assets/wallet_connect.svg"
            alt="MetaMask"
          />
          Wallet Connect
        </span>
        <Typography sx={{ color: "#1A1A1A" }}>URL</Typography>
      </Grid>
      <Typography>
        Alternatively, use Metamask Wallet. Click
        <span onClick={metamask}>
          <img
            src="/assets/metamask.svg"
            alt="metamask"
            width="18px"
            height="13px"
            display="inline-block"
          ></img>
        </span>
      </Typography> */}
      <Grid container xs={12}>
        <Grid xs={4}>
          <img
            src="/assets/metamask.svg"
            alt="metamask"
            width="144px"
            height="68px"
            // display="inline-block"
          />
        </Grid>
        <Grid
          container
          xs={8}
          sx={{ alignContent: "center", alignItems: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Button sx={{ fontSize: 12, display: "flex" }} onClick={metamask}>
              Sign In with Metamask
            </Button>
            <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
              Need MetaMask?&nbsp;
              <a href="https://metamask.io/download/" target="_blank">
                Download it here
              </a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {waitingMessage[waitingStep] && (
        <Grid
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 3,
            border: "1px solid #7129F5",
          }}
        >
          <Typography>{waitingMessage[waitingStep]}</Typography>
          <LinearProgress sx={{ width: "100%", backgroundColor: "#7129F5" }} />
        </Grid>
      )}
      <Grid
        xs={12}
        //fullWidth
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: " #F9F5FF",
          borderRadius: "12px",
          paddingBottom: "26px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "justified",
            marginBottom: 6,
          }}
        >
          <Typography sx={{ paddingTop: "31px" }}>
            You are about to pay a small gas fee to settle this campaign.
          </Typography>
          <NetworkSelection
            funds={funds}
            onChange={(e) => setSelectedNetwork(e.target.value)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Settlement;
