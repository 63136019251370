import { proxy } from "valtio";
import theme from "../theme";

const state = proxy({
  FACILITATOR_ADDRESS: "",
  baseUrl: "",
  socialUrl: "https://1095-143-244-44-167.eu.ngrok.io",
  approved: false,
  contract_address: "",
  creator_address: "",
  campaign: "", // Should be read directly from smart contract
  beneficiary: "",
  theme,
  pkMoonPay: "",
});

export default state;
