import { useState } from "react";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Why = (props) => {
  const { name, message, setPromoteMessage, setName } = props;

  const handleName = (event) => {
    setName(event.target.value);
  };

  return (
    <Grid container xs={12} rowSpacing={3} sx={{ marginTop: 3 }}>
      <Grid xs={12}>
        <Typography variant="body1" component="span" align="center">
          Your Name
        </Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          margin="dense"
          size="small"
          required
          fullWidth
          type="text"
          value={name}
          placeholder="John Smith"
          onChange={handleName}
        />
      </Grid>
      <Grid xs={12}>
        <Typography>
          Explain to your followers why you are doing this:
        </Typography>
        {/*<TextField
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          value={message}
          placeholder="Enter your own description."
          onChange={handleMessage}
          multiline
          rows={8}
        />*/}
        <ReactQuill
          modules={{
            toolbar: [
              [/*{ header: "1" }, { header: "2" }, */ { font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", /*"strike", */ "blockquote"],
              [
                // { color: ["red", "green", "blue", "orange", "violet"] },
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image" /*, "video"*/],
              // ["clean"],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          theme="snow"
          value={message}
          onChange={setPromoteMessage}
        />
      </Grid>
    </Grid>
  );
};

export default Why;
