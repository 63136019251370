import { useState, useEffect } from "react";
import { Typography, MenuItem, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const Cash = (props) => {
  const countries = [
    {
      value: "USA",
      label: "USA",
    },
    {
      value: "JAPAN",
      label: "JAPAN",
    },
    {
      value: "CANADA",
      label: "CANADA",
    },
    {
      value: "VIETNAM",
      label: "VIETNAM",
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        justsifyContent: "center",
      }}
    >
      <Grid container sx={{ paddingTop: "15px" }}>
        <Button
          sx={{
            width: "506px",
            height: "56px",
            backgroundColor: "#333333",
            border: "1px solid",
            borderColor: "#333333",
            borderRadius: "12px",
          }}
          disabled
        >
          <img src="/assets/gpay.svg"></img>
        </Button>
      </Grid>
      <Grid container>
        <img src="/assets/paywcard.svg"></img>
      </Grid>
      <Typography sx={{ color: "#8F9CA3" }}> Email</Typography>
      <Grid container>
        <TextField
          disabled
          variant="outlined"
          size="small"
          sx={{
            width: "506px",
            height: "40px",
          }}
        ></TextField>
      </Grid>
      <Typography sx={{ color: "#8F9CA3" }}>Card Information</Typography>
      <Grid
        container
        xs={12}
        sx={{
          width: "506px",
          height: "100px",
          border: "1px solid",
          borderColor: "#8F9CA3",
          borderRadius: "12px",
        }}
      >
        <Grid xs={12}>
          <TextField
            disabled
            variant="standard"
            label="1234 1234 1234 1234"
            sx={{
              width: "506px",
              height: "50px",
            }}
          ></TextField>
        </Grid>
        <Grid container xs={12}>
          <Grid
            xs={6}
            sx={{
              borderRight: "1px solid",
              borderColor: "#8F9CA3",
              width: "253px",
              height: "50px",
            }}
          >
            <TextField
              disabled
              variant="standard"
              label="MM / YY"
              sx={{
                paddingTop: "6px",
                paddingLeft: "10px",
                width: "240px",
                height: "50px",
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              disabled
              variant="standard"
              label="CVC"
              sx={{
                paddingTop: "6px",
                paddingLeft: "10px",
                width: "240px",
                height: "50px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography sx={{ color: "#8F9CA3" }}>Name on Card</Typography>
      <Grid container xs={12}>
        {
          <TextField
            disabled
            variant="outlined"
            size="small"
            sx={{ width: "506px" }}
          />
        }
      </Grid>
      <Typography sx={{ color: "#8F9CA3" }}>Country or Region</Typography>
      <Box
        sx={{
          border: "1px solid",
          borderRadius: "12px",
          borderColor: "#8F9CA3",
          width: "100%",
          height: "100px",
        }}
      >
        <TextField select variant="standard" label="Country" fullWidth disabled>
          {countries.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          disabled
          variant="standard"
          label="ZIP"
          fullWidth
          sx={{ paddingTop: "9px", paddingLeft: "10px", width: "95%" }}
        />
      </Box>
    </Box>
  );
};

export default Cash;
