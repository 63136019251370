import { useState, useEffect } from "react";
import { isMobile, isIOS } from "react-device-detect";
import { Box, Button, Typography, Link, LinearProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Compliance from "../features/campaign/Compliance";
import {
  ensurChainId,
  onMetaMaskConnect,
  request as metamaskPay,
} from "../features/MetaMask/hook/useMetaMask";
import {
  web3,
  ensurChainId as cbEnsureChainId,
  onCoinbaseConnect,
  request as coinbasePay,
} from "../features/CoinbaseWallet/hook/useCoinbaseConnect";

import {
  NETWORK,
  WALLET,
  commitPayment,
  sendDonation,
  getL1ChainId,
  getL2ChainId,
  getPGChainId,
  sleep,
  mobile,
} from "../utils/blockchain";
import abi from "../abis/erc20.abi.json";

import {
  NONE,
  STEP_PREPARE_DEPOSIT,
  STEP_PREPARE_PAYMENT,
  STEP_SWITCH_TO_L1,
  STEP_SWITCH_TO_L2,
  STEP_SWITCH_TO_PG,
  STEP_PREPARE_DONATION,
  STEP_APPROVE_DONATION,
  STEP_DEPOSIT_TOKEN,
  STEP_SEND_DONATION,
  STEP_APPROVE_L1_ALLOWANCE,
  getWaitingMessage,
} from "../utils";

const SelectWallet = (props) => {
  const {
    affiliate_address,
    twins,
    to,
    token,
    amount,
    next,
    notify,
    setReceipt,
    political = false,
    browser,
    deeplinkCompliance,
  } = props;

  const [wallet, setWallet] = useState(WALLET.metamask);
  const [open, setOpen] = useState(!!deeplinkCompliance);
  const [waitingStep, setWaitingStep] = useState(NONE);

  const symbol = token.endsWith("_ZK")
    ? token.substring(0, token.length - 3)
    : token;

  const WAITING_MSG = getWaitingMessage(amount, symbol, token, wallet);

  const donateERC20Token = async (from, to, amount, token, info) => {
    let result;
    const method = "approve";
    let chainId, prompt;
    switch (token) {
      case "NPAY":
      case "USDC":
        chainId = getL1ChainId();
        prompt = STEP_SWITCH_TO_L1;
        break;
      case "NPAY_ZK":
      case "USDC_ZK":
        chainId = getL2ChainId();
        prompt = STEP_SWITCH_TO_L2;
        break;
      case "NPAY_PG":
      case "USDC_PG":
        chainId = getPGChainId();
        prompt = STEP_SWITCH_TO_PG;
        break;
    }

    let Pay;
    let message;
    switch (wallet) {
      case WALLET.coinbase:
        Pay = coinbasePay;
        message = await cbEnsureChainId(chainId, () => setWaitingStep(prompt));
        break;
      default:
        Pay = metamaskPay;
        message = await ensurChainId(chainId, () => setWaitingStep(prompt));
    }
    if (message !== true) {
      return {
        success: false,
        message,
      };
    } else {
      console.log(
        `donate ${token} on chain ID ${chainId} - approve allowance.......`
      );
      setWaitingStep(STEP_PREPARE_DONATION);

      result = await commitPayment(
        Pay,
        from,
        to,
        amount,
        token,
        method,
        () => setWaitingStep(STEP_APPROVE_DONATION),
        browser
      );

      if (result && result.success === true) {
        console.log(`donate ${token} on zkSync - send donation.......`);
        const { success, ...rest } = result;

        setWaitingStep(STEP_SEND_DONATION);

        // Wait for 10 seconds to give the allowance time to make it into the mempool/blockchain
        await sleep(15000);

        result = await sendDonation(
          from,
          to,
          affiliate_address,
          token,
          amount,
          {
            political,
            info,
            method,
            ...rest,
          }
        );

        setReceipt(result.receipt);
      }
    }

    console.log("donate ERC20 token....... ", result);
    return result;
  };

  const donateL2Token = async (from, to, amount, token, info) => {
    let result;
    const method = "approve"; // "eip712/approve"; // "eip712/permit"; //

    let Pay;
    let message;
    switch (wallet) {
      case WALLET.coinbase:
        Pay = coinbasePay;
        message = await cbEnsureChainId(getL2ChainId(), () =>
          setWaitingStep(STEP_SWITCH_TO_L2)
        );
        break;
      default:
        Pay = metamaskPay;
        message = await ensurChainId(getL2ChainId(), () =>
          setWaitingStep(STEP_SWITCH_TO_L2)
        );
    }
    if (message !== true) {
      return {
        success: false,
        message,
      };
    } else {
      console.log(`donate ${token} on zkSync - approve allowance.......`);
      setWaitingStep(STEP_PREPARE_DONATION);

      result = await commitPayment(
        Pay,
        from,
        to,
        amount,
        token,
        method,
        () => setWaitingStep(STEP_APPROVE_DONATION),
        browser
      );

      if (result && result.success === true) {
        console.log(`donate ${token} on zkSync - send donation.......`);
        const { success, ...rest } = result;

        setWaitingStep(STEP_SEND_DONATION);

        // Wait for 10 seconds to give the allowance time to make it into the mempool/blockchain
        await sleep(20000);

        result = await sendDonation(
          from,
          to,
          affiliate_address,
          token,
          amount,
          {
            political,
            info,
            method,
            ...rest,
          }
        );

        setReceipt(result.receipt);
      }
    }

    console.log("donate L2 token....... ", result);
    return result;
  };

  const walletConnect = async () => {
    next();
  };

  const facilitateMobileBrowser = (wallet, info = {}) => {
    let uri;
    let encodedURI;
    let redirectedUrl;
    switch (wallet) {
      case WALLET.coinbase:
        uri = political
          ? `https://${window.location.host}${
              window.location.pathname
            }?browser=mobile&action=donate&token=${token}&amount=${amount}&compliance=${btoa(
              unescape(encodeURIComponent(JSON.stringify(info)))
            )}`
          : `https://${window.location.host}${window.location.pathname}?browser=mobile&action=donate&token=${token}&amount=${amount}`;

        encodedURI = encodeURIComponent(uri);
        redirectedUrl = isIOS
          ? `cbwallet://dapp?url=${encodedURI}`
          : `https://go.cb-w.com/dapp?cb_url=${encodedURI}`;

        break;
      default:
        redirectedUrl = political
          ? `https://metamask.app.link/dapp/${window.location.host}${
              window.location.pathname
            }?browser=mobile&action=donate&token=${token}&amount=${amount}&compliance=${btoa(
              unescape(encodeURIComponent(JSON.stringify(info)))
            )}`
          : `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}?browser=mobile&action=donate&token=${token}&amount=${amount}`;
    }
    // alert(redirectedUrl);
    function openWalletBrowser(url) {
      const a = document.createElement("a");
      a.href = url;
      a.target = "_self";
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    openWalletBrowser(redirectedUrl); // window.location.replace(redirectedUrl);
  };

  const comply = (wallet) => {
    setWallet(wallet);

    if (political) {
      setOpen(true);
    } else {
      if (isMobile && browser !== mobile) {
        return facilitateMobileBrowser(wallet);
      }
      donate(wallet, {});
    }
  };

  const handleCompliance = (wallet, info) => {
    if (!isMobile || browser === mobile) {
      setOpen(false);
      donate(wallet, info);
    } else {
      facilitateMobileBrowser(wallet, info);
    }
  };

  const donate = async (wallet, info) => {
    let from;
    switch (wallet) {
      case WALLET.coinbase:
        from = await onCoinbaseConnect();
        break;
      default:
        from = await onMetaMaskConnect();
    }
    if (from) {
      console.log("wallet.......", from, token);
      let result;
      if (token === "USDC_ZK") {
        result = await donateL2Token(from, to, amount, token, info);
      } else if (token === "USDC") {
        const twin = twins.find((t) => t.network === NETWORK.ethereum);
        if (!twin) {
          return notify(
            "This campaign does not accept USDC on ethereum. Thank you",
            "error"
          );
        }
        const contract = new web3.eth.Contract(abi, twin.address);
        console.log("start web3...............", contract);
        const myEvent = contract.once(
          "Approval" /*, { from }*/,
          (error, result) => {
            console.log("on watch...............", error);
            console.log(result);
            console.log(result.args);
          }
        );

        result = await donateERC20Token(
          from,
          twin.address,
          amount,
          token,
          info
        );
      } else if (token === "USDC_PG") {
        const twin = twins.find((t) => t.network === NETWORK.polygon);
        if (!twin) {
          return notify(
            "This campaign does not accept USDC on Polygon (MATIC). Thank you",
            "error"
          );
        }

        result = await donateERC20Token(
          from,
          twin.address,
          amount,
          token,
          info
        );
      }

      console.log("result.......", result);
      if (result === true || result?.success === true) {
        next(20);
      } else {
        setWaitingStep(NONE);
        notify(result?.message || result, "error");
      }
    }
  };

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        gap: "20px",
        // height: political ? "168px" : "480px",
        flex: "none",
        order: "1",
        alignSelf: "stretch",
      }}
    >
      {open && (
        <Compliance
          amount={amount}
          open={open}
          setOpen={setOpen}
          submit={handleCompliance}
          wallet={wallet}
          compliance={deeplinkCompliance}
        />
      )}
      {!open && (
        <>
          <Typography
            sx={{
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Need a MetaMask wallet?{" "}
            <Link
              href="https://metamask.io/download/"
              target="_blank"
              rel="noreferrer"
            >
              Download it here.
            </Link>
          </Typography>
          <Button
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              height: 80,
              borderRadius: "12px",
              backgroundColor: "#F9F5FF",
              alignItems: "center",
              padding: "4px 34px",
              color: "#4108AB",
              columnGap: "10px",
            }}
            onClick={() => comply(WALLET.metamask)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                width: "208px",
                height: "45px",
                gap: "8px",
                order: 0,
              }}
            >
              <img
                src="/assets/metamask.svg"
                alt="metamask"
                width={60}
                height={46}
              />
              {/*<img
            src="/assets/wallet_connect.svg"
            width={"37.28px"}
            height={"37.28px"}
            style={{
              display: "flex",
              order: 1,
            }}
          />*/}

              <Typography
                variant="body2"
                component="span"
                sx={{ display: "flex", order: 2 }}
              >
                MetaMask*{/*Wallet Connect*/}
              </Typography>
            </Box>
            <Typography
              variant="h4"
              component="span"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "2px 8px",
                gap: "4px",
                width: "105px",
                height: "24px",
                borderRadius: "50px",
                backgroundColor: "#EFE7FE",
                order: 1,
              }}
            >
              Recommended
            </Typography>
          </Button>
          <Button
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              height: 80,
              borderRadius: "12px",
              backgroundColor: "#F9F5FF",
              alignItems: "center",
              padding: "4px 34px",
              color: "#4108AB",
              gap: "4px",
            }}
            onClick={() => comply(WALLET.coinbase)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "17px",
                width: "161px",
                height: "46.88px",
              }}
            >
              <img
                src="/assets/coinbase.svg"
                alt="coinbase"
                width={60}
                height={45}
              />
              <span
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  color: "#000000",
                  fontSize: "18px",
                }}
              >
                CoinBase
              </span>
            </Box>
            <Typography
              variant="h4"
              component="span"
              sx={{
                width: "162px",
                height: "24px",
                backgroundColor: "#EFE7FE",
                borderRadius: "50px",
                order: 1,
                color: "#4108AB",
              }}
            >
              Popular
            </Typography>
          </Button>
        </>
      )}
      {!political && (
        <Button
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            height: 80,
            borderRadius: "12px",
            backgroundColor: "#F9F5FF",
            alignItems: "center",
            padding: "4px 34px",
            color: "#4108AB",
            gap: "4px,",
          }}
          onClick={walletConnect}
          disabled
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0px",
              gap: "17px",
              width: "161px",
              height: "46.88px",
            }}
          >
            <img src="/assets/trust.svg" alt="trust" width={60} height={40} />
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: 500,
                color: "#000000",
                fontSize: "18px",
              }}
            >
              Trust
            </span>
          </Box>
          <Typography
            variant="h4"
            component="span"
            sx={{
              width: "162px",
              height: "24px",
              backgroundColor: "#EFE7FE",
              borderRadius: "50px",
              order: 1,
              color: "#4108AB",
            }}
          >
            Coming soon
          </Typography>
        </Button>
      )}
      {WAITING_MSG[waitingStep] && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 3,
            border: "1px solid #7129F5",
          }}
        >
          {typeof WAITING_MSG[waitingStep] === "string" ? (
            <Typography>{WAITING_MSG[waitingStep]}</Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ textAlign: "center" }}>
                {WAITING_MSG[waitingStep][0]}
              </Typography>
              <Typography
                sx={{ fontWeight: 700, color: "red", textAlign: "center" }}
              >
                {WAITING_MSG[waitingStep][1]}
              </Typography>
            </Box>
          )}
          <LinearProgress sx={{ width: "100%", backgroundColor: "#7129F5" }} />
        </Box>
      )}
      {!political && (
        <>
          <Button
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              height: 80,
              borderRadius: "12px",
              backgroundColor: "#F9F5FF",
              alignItems: "center",
              padding: "4px 34px",
              color: "#4108AB",
              gap: "4px,",
            }}
            onClick={walletConnect}
            disabled
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "17px",
                width: "238px",
                height: "46.88px",
                order: 0,
              }}
            >
              <img
                src="/assets/wallet_connect.svg"
                width={"58px"}
                height={"58px"}
                style={{
                  display: "flex",
                  // order: 1,
                }}
              />
              {/*<img
            src="/assets/metamask.svg"
            alt="metamask"
            width={60}
            height={46}
          />*/}
              <span
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  color: "#000000",
                  fontSize: "18px",
                }}
              >
                Wallet Connect{/*MetaMask*/}
              </span>
            </Box>
            <Typography
              variant="h4"
              component="span"
              sx={{
                width: "162px",
                height: "24px",
                backgroundColor: "#EFE7FE",
                borderRadius: "50px",
                order: 1,
                color: "#4108AB",
              }}
            >
              Coming soon
            </Typography>
          </Button>
          <Button
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              height: 80,
              borderRadius: "12px",
              backgroundColor: "#F9F5FF",
              alignItems: "center",
              padding: "4px 34px",
              color: "#4108AB",
              gap: "4px,",
            }}
            disabled
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "17px",
                width: "161px",
                height: "46.88px",
              }}
            >
              <img
                src="/assets/exodus.svg"
                alt="exodus"
                width={60}
                height={40}
              />
              <span
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  color: "#000000",
                  fontSize: "18px",
                }}
              >
                Exodus
              </span>
            </Box>
            <Typography
              variant="h4"
              component="span"
              sx={{
                width: "162px",
                height: "24px",
                backgroundColor: "#EFE7FE",
                borderRadius: "50px",
                order: 1,
                color: "#4108AB",
              }}
            >
              Coming soon
            </Typography>
          </Button>
          <Button
            disabled
            sx={{
              width: "100%",
              height: 56,
              backgroundColor: "#FFFFFF",
              color: "#7129F5",
              "&:hover": {
                color: "#FFFFFF",
              },
              m: 2,
              border: "1px solid #7129F5",
              borderRadius: "12px",
            }}
          >
            Show More
          </Button>
        </>
      )}
    </Grid>
  );
};

export default SelectWallet;
