import Web3 from "web3";
import { ethers } from "ethers";
import { utils, Contract, Web3Provider, Provider, Wallet } from "zksync-web3";
import { getError } from "../../../utils";
import { isMetaMaskInstalled } from "../store/";

export const PresetNetwork = {
  137: {
    chainId: ethers.utils.hexValue(137),
    rpcUrls: ["https://polygon-mainnet.infura.io"],
    chainName: "Polygon Mainnet",
    nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
    blockExplorerUrls: ["https://polygonscan.com/"],
    // iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"],
  },
  80001: {
    chainId: ethers.utils.hexValue(80001),
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    chainName: "Matic Mumbai Testnet",
    nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    // iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"],
  },
  324: {
    chainId: ethers.utils.hexValue(324),
    rpcUrls: ["https://mainnet.era.zksync.io"],
    chainName: "zkSync Era Mainnet",
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://explorer.zksync.io/"],
    // iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"],
  },
  280: {
    chainId: ethers.utils.hexValue(280),
    rpcUrls: ["https://testnet.era.zksync.dev"],
    chainName: "zkSync Era Testnet",
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://goerli.explorer.zksync.io/"],
    // iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"],
  },
};

export const hasMetaMask = isMetaMaskInstalled();

export const zkSyncEraChains = [280, 324, "280", "324", "0x118", "0x144"];
export const checkNetwork = async (chainId: number) => {
  if (hasMetaMask) {
    const currentChainId = await window.ethereum.request({
      method: "eth_chainId",
    });

    const targetChainID = ethers.utils.hexValue(
      typeof chainId === "string" ? parseInt(chainId) : chainId
    );
    return targetChainID === currentChainId;
  }
};

export const switchNetwork = async (chainId: number) => {
  const targetChainID = chainId;
  try {
    const err = await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.utils.hexValue(targetChainID) }],
    });

    if (err === null) {
      return true;
    }

    return err.message;
  } catch (e: any) {
    // alert(`Debug 4 - targetChainID: ${targetChainID}`);
    console.log("............................", e);
    console.log("targetChainID:", targetChainID, PresetNetwork[targetChainID]);
    console.log(".hex....:", ethers.utils.isHexString(targetChainID));
    console.log(".de....:", parseInt(`${targetChainID}`, 16));
    const chain = ethers.utils.isHexString(targetChainID)
      ? parseInt(`${targetChainID}`, 16)
      : targetChainID;
    switch (e.code) {
      case 4902:
      case -32603:
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [PresetNetwork[chain]],
          });
          return true;
        } catch (e: any) {
          console.log(e);
          return e.message;
        }
      case -32601:
        return "Please switch blockchain network manually";
      default:
        return e.message;
    }
  }
};

export const ensurChainId = async (chainId: number, setProgress?: Function) => {
  const validBlockchain = await checkNetwork(chainId);
  if (!validBlockchain) {
    if (typeof setProgress === "function") {
      setProgress();
    }

    return await switchNetwork(chainId);
  }

  return true;
};

export const onMetaMaskConnect = async (args: any = undefined) => {
  try {
    if (hasMetaMask) {
      const eth = window.ethereum;
      // Will open the MetaMask UI
      // You should disable this button while the request is pending!
      const accounts = await eth.request({
        method: "eth_requestAccounts",
      });
      if (!accounts) {
        return console.error("Error connect metamask");
      }

      eth.on("chainChanged", (chainId) => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        console.log("Changed to new network. Chain id =", chainId);
        // window.location.reload();
      });

      return accounts[0];
    }
  } catch (error) {
    console.error(error);
  }
};

export async function request(
  {
    from,
    to,
    data,
    amount,
    gasPrice,
    estimatedGas,
    EIP712Msg,
    chainId,
    type,
    nonce,
    customData,
    gas,
    gasLimit,
    maxFeePerGas,
    maxPriorityFeePerGas,
    setProgress,
    uuid,
  }: {
    from: string;
    to: string;
    data: string;
    amount: string;
    gasPrice: string;
    estimatedGas: string;
    EIP712Msg: any;
    chainId: number;
    type: number;
    nonce: number;
    customData: any;
    gas: string | number;
    gasLimit: string | number;
    maxFeePerGas: string | number;
    maxPriorityFeePerGas: string | number;
    setProgress?: Function;
    uuid?: string;
  },
  browser?: string
) {
  // alert(`Debug 2 - hasMetaMask: ${hasMetaMask}`);
  // alert(`Debug 3 - browser: ${browser}`);
  if (browser === "mobile" || hasMetaMask) {
    const eth = window.ethereum;
    const message = await ensurChainId(
      chainId || EIP712Msg?.domain?.chainId,
      () => (typeof setProgress === "function" ? setProgress() : undefined)
    );
    if (message !== true) {
      return {
        success: false,
        message,
      };
    } else {
      try {
        if (!EIP712Msg) {
          // alert(`Debug 4a - chainId: ${chainId}`);
          let tx;
          // if (!tx.gas) {
          //console.log("store.l1Rpc", store.l1Rpc);
          // const web3 = new Web3(store.l1Rpc);

          //   tx.gas = web3.utils.toHex(web3.utils.toWei(String(gas), 'wei'));
          // }
          if (!customData) {
            console.log("legacy transaction");
            // (`Debug 5 - legacy: ${type}`);

            // web3.eth.estimateGas({
            //   to: toAddress,
            //   data: "0xc6888fa10000000000000000000000000000000000000000000000000000000000000003"
            // }).then((estimatedGas) => {
            //   web3.eth.getMaxPriorityFeePerGas().then((price) => {
            //     sendTx(web3, {
            //       gas: estimatedGas,
            //       maxPriorityFeePerGas: price,
            //       to: toAddress,
            //       value: 100,
            //     });
            //   });
            // });

            tx =
              type == 2
                ? {
                    from,
                    to,
                    // gas: estimatedGas || gasLimit, // When deposit usdc on L1 Metamask throw error: invalid key
                    gasLimit: estimatedGas || gasLimit,
                    maxFeePerGas,
                    maxPriorityFeePerGas,
                    value: amount,
                    data,
                    type: "2",
                    // chainId,
                  }
                : {
                    from,
                    to,
                    // gas: estimatedGas || gasLimit, // When deposit usdc on L1 Metamask throw error: invalid key
                    gasLimit: gasLimit || estimatedGas,
                    gasPrice: gasPrice || gas || maxFeePerGas,
                    value: amount,
                    data,
                    // chainId,
                  };

            // const gas1 = await web3.eth.estimateGas(tx);
            // console.log("gas1...", gas1);
            // if (!tx.gasLimit) {
            //   tx.gasLimit = web3.utils.toHex(
            //     web3.utils.toWei(String(gas1), "wei")
            //   );
            // }

            // if (type) {
            //   tx.type = type.toString();
            // }
          } else {
            console.log("paymaster transaction");
            // alert(`Debug 6 - paymaster: ${chainId}`);

            tx = {
              from,
              to,
              gas: estimatedGas || gasLimit,
              // gasPrice: gasPrice || gas, // When donate usdc on L2 Metamask throw error: both gasPrice and (maxFeePerGas or maxPriorityFeePerGas) specified
              // gasLimit,
              value: amount,
              data,
              // nonce: ethers.utils.hexValue(nonce), // MetaMask - RPC Error: Invalid transaction params: nonce is not a string
              customData,
              maxFeePerGas,
              maxPriorityFeePerGas,
            };
          }
          console.log("tx to send to metamask........", tx);
          // alert(`Debug 7 - chainId: ${chainId}`);

          let transactionHash;
          if (!zkSyncEraChains.includes(chainId)) {
            transactionHash = await eth.request({
              method: "eth_sendTransaction",
              params: [tx],
            });
            console.log("zk transactionHash........", transactionHash);
          } else {
            // const zkSyncProvider = new Provider("https://mainnet.era.zksync.io");
            // const signer = new Web3Provider(zkSyncProvider).getSigner();
            const signer = new Web3Provider(eth).getSigner();

            const txHash = await signer.sendTransaction(tx);
            console.log("txHash........", txHash);

            // const receipt = await txHash.wait();
            // console.log("receipt........", receipt);
            transactionHash = txHash.hash;
          }

          console.log("transactionHash........", transactionHash);

          return {
            success: true,
            uuid,
            transaction_hash: transactionHash,
          };
        } else {
          console.log("EIP712Msg", EIP712Msg);

          // alert(`Debug 4b - chainId: ${chainId}`);
          const signature = await eth.request({
            method: "eth_signTypedData_v4",
            params: [from, JSON.stringify(EIP712Msg)],
          });

          // alert(`Debug 5 - EIP712Msg signature: ${signature}`);
          console.log("signature:", signature, from);
          return { from, EIP712Msg, signature };
          const zkSyncProvider = new Provider("https://testnet.era.zksync.dev");
          // const zkSyncProvider = new Provider("https://mainnet.era.zksync.io");

          const tx = {
            //txType: 113,
            from,
            to,
            gasLimit: estimatedGas || gasLimit,
            maxFeePerGas,
            maxPriorityFeePerGas,
            nonce,
            value: ethers.BigNumber.from(0),
            data,
            type: 113,
            chainId: parseInt(chainId || EIP712Msg?.domain?.chainId),
            customData: {
              ...customData,
              customSignature: signature,
            },
          };

          console.log("tx...........", tx);

          const serializedTx = utils.serialize({ ...tx });
          console.log("serializedTx...........", serializedTx);

          const sentTx = await zkSyncProvider.sendTransaction(serializedTx);
          console.log("......sentTx", sentTx);
          await sentTx.wait();

          // return {
          //   success: true,
          //   EIP712Msg,
          //   signature,
          // };
        }
      } catch (e: any) {
        console.log("............", e, e.code);
        if (e && e.code) {
          switch (e.code) {
            case 4001:
            case "ACTION_REJECTED": // MetaMask Typed Message Signature: User denied message signature.
              return {
                success: false,
                message: "User rejected",
              };
            // case -32000:
            //   return {
            //     success: false,
            //     message: e.message,
            //   };
            default:
          }
        }
        return {
          success: false,
          message: getError(e) || "Something wrong",
        };
      }
    }
  }
}
