import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
// import AuthClient, { generateNonce } from "@walletconnect/auth-client";
import SignClient from "@walletconnect/sign-client";
import { UniversalProvider } from "@walletconnect/universal-provider";

import store from "../store";
import { getDidAddress, getNamespacedDidChainId } from "../utils/address";
import { getError } from "../../../utils";

let assureRunOnlyOnce = false;
let count = 0;

export let authClient: any;
export let signClient: any;
export let provider: any;

let using = "signClient"; // "provider";

export const metadata = {
  name: "oneiro",
  description: "Oneiro Widget",
  url: window.location.host,
  icons: ["https://widget.npayme.io/assets/npay.svg"],
};

const methods = [
  "eth_sendTransaction",
  "eth_signTransaction",
  "eth_sign",
  "personal_sign",
  "eth_signTypedData",
];

const events = ["connect", "disconnect"]; //  "session_event", "session_update";

export const checkNetwork = async (chainId: number) => {
  const currentChainId = await window.ethereum.request({
    method: "eth_chainId",
  });

  const targetChainID = ethers.utils.hexValue(
    typeof chainId === "string" ? parseInt(chainId) : chainId
  );
  return targetChainID === currentChainId;
};

export const switchNetwork = async (chainId: number) => {
  try {
    const targetChainID = chainId;
    const err = await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.utils.hexValue(targetChainID) }],
    });

    if (err === null) {
      return true;
    }

    return err.message;
  } catch (e: any) {
    console.log("e..........", e);
    switch (e.code) {
      case -32601:
        return "Switch network id not supported";
      default:
        return e.message;
    }
  }
};

export const ensurChainId = async (chainId: number, setProgress?: Function) => {
  const validBlockchain = await checkNetwork(chainId);
  if (!validBlockchain) {
    if (typeof setProgress === "function") {
      setProgress();
    }

    return await switchNetwork(chainId);
  }

  return true;
};

const getSession = (session) => {
  const { expiry, namespaces, topic } = session;
  const walletAddress = namespaces.eip155.accounts[0];
  const iss = `did:pkh:${walletAddress}`;
  console.log("..........expiry", expiry);
  // Compose authenticate params
  const domain = window.location.hostname.split(".").slice(-2).join(".");
  return {
    p: {
      domain,
      version: "fallback",
      nonce: "na",
      aud: window.location.href,
      type: "eip4361",
      statement: "Sign in with wallet",
      iat: new Date(expiry * 1000).toISOString(),
      iss,
    },
    s: {},
    topic,
    expiry,
  };
};

export async function createAuthClient() {
  if (using === "authClient") {
    console.log("stop support");
    // authClient = await AuthClient.init({
    //   projectId: "1854555a752db30bba2d5b217e3bbd5d",
    //   metadata,
    // });
  } else if (using === "signClient") {
    signClient = await SignClient.init({
      relayUrl: "wss://relay.walletconnect.com",
      projectId: "1854555a752db30bba2d5b217e3bbd5d",
      metadata,
    });
  } else if (using === "provider") {
    // provider = await EthereumProvider.init({
    //   projectId: "1854555a752db30bba2d5b217e3bbd5d",
    //   chains: [1],
    //   // optionalChains, // OPTIONAL chains
    //   showQrModal: false, // REQUIRED set to "true" to use @walletconnect/modal
    //   methods, // REQUIRED ethereum methods
    //   // optionalMethods, // OPTIONAL ethereum methods
    //   events, // REQUIRED ethereum events
    //   // optionalEvents, // OPTIONAL ethereum events
    //   // rpcMap, // OPTIONAL rpc urls for each chain
    //   // metadata, // OPTIONAL metadata of your app
    //   // qrModalOptions, // OPTIONAL - `undefined` by default, see https://docs.walletconnect.com/2.0/web3modal/options
    // });

    provider = await UniversalProvider.init({
      projectId: "1854555a752db30bba2d5b217e3bbd5d",
      metadata,
    });
  }
}

export async function onWalletConnect(args: any = undefined) {
  if (!authClient && !signClient && !provider) {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(onWalletConnect(args)), 1000);
    });
  }

  const open = (uri: string) => {
    store.uri = uri;
    store.open = true;
    store.args = args;
  };

  const reset = () => {
    // store.uri = "";
    store.open = false;
    store.args = null;
  };

  // const domain = window.location.hostname.split('.').slice(-2).join('.');
  // const { uri } = await authClient.request({
  //   chains: [store.chainId],
  //   domain,
  //   nonce: generateNonce(),
  //   aud: window.location.href,
  //   // type: 'eip4361',
  //   statement: 'Sign in with wallet',
  //   // methods: ['wc_authRequest'], ???
  // });
  const proposalNamespace = {
    eip155: {
      methods,
      chains: [store.l2ChainId, store.pgChainId], // "eip155:1"
      events,
    },
  };

  if (provider) {
    provider.on("display_uri", (uri: string, approval) => {
      console.log("...............uri", uri, approval);
      // if (uri) {
      open(uri);
      // }
    });

    // provider.connect();
    const { uri, approval } = await provider.connect({
      namespaces: proposalNamespace,
      // {
      //   eip155: {
      //     methods,
      //     chains: [store.chainId],
      //     events,
      //     // rpcMap: {
      //     //   80001:
      //     //     "https://rpc.walletconnect.com?chainId=eip155:80001&projectId=<your walletconnect project id>",
      //     // },
      //   },
      //   // pairingTopic: "<123...topic>", // optional topic to connect to
      //   // skipPairing: false, // optional to skip pairing ( later it can be resumed by invoking .pair())
      // },
    });
    console.log("1...............uri", uri, approval);
    return {};
  }

  if (signClient) {
    const { uri, approval } = await signClient
      .connect({
        // Optionally: pass a known prior pairing (e.g. from `signClient.core.pairing.getPairings()`) to skip the `uri` step.
        // pairingTopic: pairing?.topic,
        // Provide the namespaces and chains (e.g. `eip155` for EVM-based chains) we want to use in this session.
        requiredNamespaces: proposalNamespace,
        optionalNamespaces: {
          eip155: {
            methods,
            chains: [store.l1ChainId],
            events,
          },
        },
      })
      .catch((e) => {
        console.log(e);
      });

    if (uri) {
      // wc:872194600639647a37a4f52df204f3321397a700b18d5048b164a27b8676e240@2?relay-protocol=irn&symKey=19de024358b85ddc6ace3afac710652dd5d26080ac009cae47467626bb220dc0
      open(uri);
    }

    // setTimeout(async () => {
    try {
      const session = await approval();
      console.log("session", session);
      store.session = getSession(session);

      return { uri, approval };
    } catch (e: any) {
      console.log("should I reset.......?", e);
      // reset();
      // return {
      //   success: false,
      //   message: getError(e) || "Something went wrong",
      // };
    }
    // }, 1000);

    return { uri, approval };
  }
}

export const subscribeToEvents = async (connect: any) => {
  if (provider) {
    provider.on("session_event", (r, a) => {
      console.log("on session_event........", r, a);
    });

    provider.on("accountsChanged", async (accounts, a) => {
      const account = accounts[0];
      if (store.selected != account) {
        store.selected = account;
        console.log("do something...........");
        // const walletAddress = account;
        // const iss = `${store.chainId}:did:pkh:${walletAddress}`;
        // const domain = window.location.hostname.split(".").slice(-2).join(".");

        // store.session = {
        //   p: {
        //     domain,
        //     version: "1",
        //     nonce: "na",
        //     aud: window.location.href,
        //     type: "eip4361",
        //     statement:
        //       "Welcome to KNOWINS. Signing is the only way we can truly know that you are the owner of the wallet you are connecting. Signing is a safe, gas-less transaction that does not in any way give permission to perform any transactions with your wallet.",
        //     iat: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
        //     iss,
        //   },
        //   s: {},
        //   topic: "walletconnect",
        // };

        // const result = await connect(store.session, store.args);
      }
    });

    provider.on("connect", (payload, a) => {
      console.log("on connect........", payload);
      store.session = getSession(payload.session);
    });

    provider.on("disconnect", (r, a) => {
      console.log("on disconnect........", r, a);
    });
  } else if (signClient) {
    signClient.on("session_update", ({ topic, params }) => {
      const { namespaces } = params;
      const _session = signClient.session.get(topic);
      // Overwrite the `namespaces` of the existing session with the incoming one.
      const updatedSession = { ..._session, namespaces };
      // Integrate the updated session state into your dapp state.
      console.log(".......................updatedSession", updatedSession);
    });

    signClient.on("session_event", ({ event }) => {
      // Handle session events, such as "chainChanged", "accountsChanged", etc.
      console.log("session_event.......", event);
    });

    signClient.on("connect", (payload, a) => {
      console.log("on connect........", payload);
    });

    signClient.on("disconnect", (r, a) => {
      console.log("on disconnect........", r, a);
    });
  } else if (authClient) {
    try {
      authClient.on("auth_response", async (res: any) => {
        const { params } = res;
        console.log(res);
        if ("code" in params || "error" in params) {
          return alert("something_wrong");
        }

        const { s: signature, p: payload } = params.result;
        const walletAddress = getDidAddress(payload.iss);
        const chainId = getNamespacedDidChainId(payload.iss);

        if (!walletAddress) {
          console.log("Could not derive address from `payload.iss`");
          return alert("wc_payload_address_error");
        }
        if (!chainId) {
          console.log("Could not derive chainId from `payload.iss`");
          return alert("status.wc_payload_chain_error");
        }

        store.session = params.result;
      });
    } catch (e) {
      console.log(e);
    }
  }
};

export function useWalletConnect(connect: any, hold = false) {
  const state = useSnapshot(store);
  const { session, args } = state;

  const onInitialize = useCallback(async () => {
    try {
      if (!assureRunOnlyOnce) {
        assureRunOnlyOnce = true;
        if (!signClient) {
          await createAuthClient();
        }

        console.log("initialize auth client:", ++count);
        // console.log('authClient', authClient);

        subscribeToEvents(connect);
      }
    } catch (err: unknown) {
      console.log(err);
    }
  }, []);

  const onSession = useCallback(
    async (session: any) => {
      try {
        if (session) {
          console.log("session changed", session);

          const result = await connect(session, args);
          console.log("Calling connect result...........", result);

          if (!hold) {
            store.open = false;
          }
        }
      } catch (err: unknown) {
        console.log(err);
      }
    },
    [store.args]
  );

  useEffect(() => {
    onInitialize();
  }, []);

  useEffect(() => {
    onSession(session);
  }, [session]);
}

export async function request({
  from,
  to,
  data,
  amount,
  gasPrice,
  estimatedGas,
  EIP712Msg,
  chainId,
  type,
  nonce,
  customData,
  gas,
  gasLimit,
  maxFeePerGas,
  maxPriorityFeePerGas,
  setProgress,
}: {
  from: string;
  to: string;
  data: string;
  amount: string;
  gasPrice: string;
  estimatedGas: string;
  EIP712Msg: any;
  chainId: number;
  type: number;
  nonce: number;
  customData: any;
  gas: string | number;
  gasLimit: string | number;
  maxFeePerGas: string | number;
  maxPriorityFeePerGas: string | number;
  setProgress?: Function;
}) {
  if (!signClient && !provider) {
    throw Error("Unable to send payment request");
  }

  try {
    const topic = store.session.topic;
    console.log("topic........", topic);
    if (!EIP712Msg) {
      let tx;
      if (!customData) {
        console.log("legacy transaction");
        tx = {
          from,
          to,
          data,
          // nonce,
          gasLimit: estimatedGas || gasLimit || maxFeePerGas,
          gasPrice: gasPrice || gas,
          value: amount,
          chainId,
        };

        if (type) {
          tx.type = type.toString();
        }
      } else {
        console.log("paymaster transaction");
        tx = {
          from,
          to,
          // gasPrice: gasPrice || gas,
          // gas: estimatedGas || gasLimit, // When promote, Wallet Connect throw invalid key
          gasLimit: estimatedGas || gasLimit,
          value: amount,
          data,
          nonce: ethers.utils.hexValue(nonce),
          customData,
          maxFeePerGas,
          maxPriorityFeePerGas,
          // chainId,
        };
      }
      console.log("tx........", tx);

      if (provider) {
        const res = await provider.request(
          {
            method: "eth_sendTransaction",
            params: [tx],
          },
          `eip155:${chainId}`
        );
        console.log(res);
        return {
          success: true,
          transaction_hash: res,
        };
      } else if (signClient) {
        // const txHash = await signClient.request({
        //   topic,
        //   chainId: store.chainId,
        //   request: {
        //     method: 'eth_sendTransaction',
        //     params: [tx],
        //   },
        // });
        console.log("topic........", topic);
        console.log("tx........", tx);

        if (chainId == 1 || chainId == 5) {
          console.log("ensure chain id");
          // await signClient.request({
          //   topic,
          //   chainId: `eip155:${chainId}`, // "eip155:324", // store.chainId,
          //   request: {
          //     method: "wallet_switchEthereumChain",
          //     // params: [{ chainId: ethers.utils.hexValue(chainId) }],
          //     params: [{ chainId: `0x${chainId}` }],
          //   },
          // });

          await signClient
            .connect({
              requiredNamespaces: {
                eip155: {
                  methods,
                  chains: [`eip155:${chainId}`],
                  events,
                },
              },
            })
            .catch((e) => {
              console.log(e);
            });
        }

        if (typeof setProgress === "function") {
          setProgress();
        }

        const req = {
          topic,
          chainId: `eip155:${chainId}`, // "eip155:324", // store.chainId,
          request: {
            // method: "eth_signTransaction",
            // id: 1,
            // jsonrpc: "2.0",
            method: "eth_sendTransaction",
            params: [tx],
          },
        };
        console.log("tx req................", req);
        const txHash = await signClient.request(req);

        console.log("txHash........", txHash);
        return {
          success: true,
          transaction_hash: txHash,
        };
      }
    } else {
      console.log("EIP712Msg", EIP712Msg);

      const signature = await signClient.request({
        method: "eth_signTypedData_v4",
        params: [from, JSON.stringify(EIP712Msg)],
      });

      console.log("signature:", signature, from);

      return {
        success: true,
        signature,
      };
    }
  } catch (e: any) {
    if (typeof setProgress === "function") {
      setProgress("NONE");
    }

    return {
      success: false,
      message: getError(e) || "Something went wrong",
    };
  } finally {
    store.open = false;
  }
}
