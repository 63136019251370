import { Grid, Box, Typography, Link } from "@mui/material";

const Disclaimer = (props) => {
  return (
    <Grid xs={12}>
      <Grid
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Team DeSantis 2024 (“JFC”) is a joint fundraising committee composed
          of: Ron DeSantis for President and Great American Comeback (each
          individually, a “Committee” and collectively, the “Committees”).{" "}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Contributions or gifts to JFC, or any participating Committee
          individually, are not deductible as charitable contributions for
          federal income tax purposes. All contributions permissible under The
          Federal Election Campaign Act (“FECA”), but which would cause a
          contributor to exceed any applicable Federal contribution limit to a
          specific Committee, shall be reallocated to the remaining Committees
          according to this allocation formula to the extent permitted by FECA.
          Any contribution that would otherwise cause a contributor to exceed
          any applicable Federal contribution limit or that is otherwise
          impermissible under FECA shall be refunded to the contributor. Any
          contributor may designate his or her contribution to a specific
          participating Committee, to the extent permissible by FECA.
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Any contribution that would otherwise cause a contributor to exceed
          any applicable Federal contribution limit or that is otherwise
          impermissible under FECA shall be refunded to the contributor. Any
          contributor may designate his or her contribution to a specific
          participating Committee, to the extent permissible by FECA. Any
          contributor may make his or her contribution directly payable to any
          Committee participating in JFC.
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Any such designated contribution that causes the contributor to exceed
          the contribution limit to the designated Committee shall not be
          reallocated by JFC absent the prior written permission of the
          contributor as required by law. Contributions from corporations, labor
          unions, foreign nationals (without “green cards”), and federal
          government contractors are prohibited.
        </Typography>
        <br />
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          The legal maximum in federal funds that an individual may contribute
          to the JFC is $11,600. The legal maximum in federal funds that a
          couple may contribute to the JFC is $23,200. A federal political
          action committee that is not a multi-candidate committee may
          contribute to the JFC a maximum of $11,600. The legal maximum in
          federal funds that a federal multi-candidate political action
          committee may contribute to the JFC is $15,000.
        </Typography>
        <br />
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Contributions to JFC shall be allocated as follows:
        </Typography>
        <br />
        <ol>
          <li>
            The first $3,300 from any single individual’s contribution shall go
            to Ron DeSantis for President’s primary election account. The next
            $3,300 shall go to Ron DeSantis for President’s general election
            account. The next $5,000 will go to Great American Comeback.
          </li>
          <li>
            $6,600 shall go to Ron DeSantis for President’s general election
            account. The next $10,000 will go to Great American Comeback.
          </li>
          <li>
            The first $3,300 from any non-multicandidate PAC contribution shall
            go to Ron DeSantis for President’s primary election account. The
            next $3,300 shall go to Ron DeSantis for President’s general
            election account. The next $5,000 will go to Great American
            Comeback.
          </li>
          <li>
            The first $5,000 from any multicandidate PAC’s contribution shall go
            to Ron DeSantis for President’s primary election account. The next
            $5,000 shall go to Ron DeSantis for President’s general election
            account. The next $5,000 will go to Great American Comeback.{" "}
          </li>
        </ol>
        <br />
      </Grid>
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{
          // position: "absolute",
          width: "100%",
          left: 0,
          bottom: 34,
        }}
      >
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", display: "flex" }}
        >
          <Typography
            variant="h3"
            component="h3"
            sx={{ display: "inline-block" }}
          >
            Powered by
          </Typography>
          <Link
            href="https://npayme.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/Logo.svg"
              height="26"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: 8,
              }}
            />
          </Link>
        </Box>
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{ display: "flex" }}
        >
          <Link
            href="https://rondesantis.com/terms-of-use/"
            target="_blank"
            rel="noreferrer"
          >
            Terms of use
          </Link>
          &nbsp; &nbsp;
          <Link
            href="https://rondesantis.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </Link>
        </Box>
      </Box>
    </Grid>
  );
};

export default Disclaimer;
