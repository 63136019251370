import { createTheme } from "@mui/material/styles";

const spacing = (factor: number) => `${0.25 * factor}rem`;
let theme = createTheme({
  spacing,
  palette: {
    primary: {
      main: "#2E3538",
    },
    secondary: {
      main: "#7129F5",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#7129F5",
    },
  },
});

theme = createTheme(theme, {
  body1: {
    // fontSize: spacing(5),
    fontSize: "1rem",
    padding: `${spacing(3)}, 0, ${spacing(3)}, 0`,
  },
  body2: {
    fontSize: "0.875rem",
  },

  typography: {
    fontFamily: `'Inter', sans-serif`,
    fontSize: 13,
    htmlFontSize: 14,

    h1: {
      fontFamily: `'Titillium Web', san-serif`,
      fontWeight: 400,
      fontSize: "40px",
      lineHeight: "60px",
    },
    h2: {
      fontFamily: `'Titillium Web', san-serif`,
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
      color: "#1A1A1A",
    },
    h3: {
      fontFamily: `Poppins, 'Titillium Web', san-serif`,
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "27px",
      color: "#1A1A1A",
    },
    h4: {
      fontFamily: `'Titillium Web', san-serif`,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
    },
    h5: {
      fontFamily: `'Titillium Web', san-serif`,
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px ",
    },
    h6: {
      fontFamiy: `'Titillium Web', san-serif`,
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "28px",
    },
    button: {
      color: "black",
      fontSize: "0.875rem",
    },
    caption: {},
    overline: {},
    body1: {
      fontFamily: `'Titillium Web', san-serif`,
      color: "black",
      fontSize: "16px",
      lineHeight: "23px",
      paddingBottom: "6px",
    },

    body2: {
      fontFamily: `'Poppins', san-serif`,
      fontStyle: "normal",
      color: "#000000",
      fontWeight: 500,
      fontSize: "18px",
    },
    subtitle1: {
      fontFamily: `'Poppins', san-serif`,
      fontStyle: "normal",
      color: theme.palette.secondary.main, // "#7d40edd4",
      fontWeight: 500,
      fontSize: "18px",
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: theme.spacing(64),
          drawerPaper: {
            width: theme.spacing(64),
          },
        },
      },
    },
    // MuiAppBar: {
    //   styleOverrides: {
    //     root: {
    //       // height: 26,
    //       // paddingTop: 3,
    //       // paddingBottom: 2,
    //       background: '#f7f7f7',
    //       borderTop: 'solid 1px rgba(0,0,0,.05)',
    //     },
    //   },
    // },
    // MuiToolbar: {
    //   styleOverrides: {
    //     root: {
    //       maxHeight: 28,
    //     },
    //   },
    // },
    // MuiButtonBase: {
    //   defaultProps: {
    //     // The default props to change
    //     disableRipple: true, // No more ripple, on the whole application 💣!
    //   },
    // },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontFamily: `'Poppins', san-serif`,
          backgroundColor: "#7129F5",
          color: "#FFFFFF",
          fontSize: "0.875rem",
          borderColor: "black",
          textTransform: "none",
          padding: "6px 18px",
          fontWeight: 500,
          "&:hover": {
            backgroundColor: "#7129F5",
            transform: "scale(1.02) perspective(0px)",
            boxShadow: "0 10px 10px black",
          },
          "&:disabled": {
            color: "#ecd7d7c9",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: theme.palette.background.paper,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: {
          color: "rgb(249, 157, 28)",
          fontWeight: "bold",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: "rgb(249, 157, 28)",
          fontWeight: "bold",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
        notchedOutline: {
          borderColor: "black",
        },
      },
    },
    MuiCssBaseline: {
      // styleOverrides: {
      //   html: {
      //     fontSize: '15px',
      //   },
      //   '.MuiDataGrid-root, .MuiDataGrid-cell, .MuiDataGrid-columnsContainer': {
      //     borderColor: 'rgba(0, 0, 0, .2)!important',
      //   },
      //   '.MuiDataGrid-iconSeparator': {
      //     color: 'rgba(0,0,0,.2)!important',
      //   },
      // },
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          color: "#7129F5",
        },
      },
    },
  },
});

export const createCustomizedTheme = (overrides) => {
  theme = createTheme(theme, overrides);
  return theme;
};

export const beneficiaryThemes = {
  "0xf9ba1de3e12ad9026fe251e2692dd0706b107d11": "DeSantis",
  "0x78a02bb94b324bd2d671ba32f644530b55a0271e": "DeSantis",
  "0x026597812ae18170f8a0287caf473795e2e1591a": "DeSantis",
};
export const customizedTheme = {
  DeSantis: {
    palette: {
      primary: {
        main: "#db2a37",
      },
      secondary: {
        main: "#09264a",
        contrastText: "#FFFFFF",
      },
    },
    typography: {
      subtitle1: {
        fontFamily: `'Poppins', san-serif`,
        fontStyle: "normal",
        color: "#db2a37",
        fontWeight: 500,
        fontSize: "18px",
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            fontFamily: `'Poppins', san-serif`,
            backgroundColor: "#db2a37",
            color: "#FFFFFF",
            fontSize: "0.875rem",
            borderColor: "#09264a",
            textTransform: "none",
            padding: "6px 18px",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#db2a37",
              transform: "scale(1.02) perspective(0px)",
              boxShadow: "0 10px 10px black",
            },
            "&:disabled": {
              color: "#ecd7d7c9",
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          track: {
            color: "#db2a37",
          },
        },
      },
    },
  },
};

export default theme;
